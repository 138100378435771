export function formatWer(wer) {
  return `${Math.round(wer * 100)}%`;
}

export function formatDdm(ddm) {
  return `${Math.round(ddm * 100)}%`;
}

export function formatRace(race) {
  return `${formatRaceSimple(race)} speakers`;
}

export function formatRaceSimple(race, nontitle) {
  switch (race) {
    case "b":
    case "black":
      return "Black";
    case "w":
    case "white":
      return nontitle ? "white" : "White";
    default:
      return nontitle ? "unknown" : "Unknown";
  }
}

export function formatNumberWithArticle(n) {
  switch (n) {
    case 11:
    case 18:
      return `An ${n}`;
    default:
      if (`${n}`[0] === "8") {
        return `An ${n}`;
      } else {
        return `A ${n}`;
      }
  }
}

export function formatSource(source) {
  switch (source) {
    case "PRV":
      return "Princeville";
    case "DCB":
      return "Washington, D.C.";
    case "ROC":
      return "Rochester";
    default:
      return "Unknown";
  }
}

export function formatProduct(product) {
  switch (product) {
    case "amazon":
      return "Amazon";
    case "apple":
      return "Apple";
    case "google":
      return "Google";
    case "ibm":
      return "IBM";
    case "msft":
      return "Microsoft";
    default:
      return "Unknown";
  }
}

export function formatSex(sex) {
  switch (sex) {
    case "male":
      return "Men";
    case "female":
      return "Women";
    default:
      return "Unknown";
  }
}

export function formatRaceSex(d) {
  const race = formatRaceSimple(d.race);
  const sex = formatSex(d.sex);
  return `${race}\n${sex}`;
}

/**
 * Get the number of indents in a line.
 *
 * Assumes 4 spaces per indent.
 */
function getIndentLevel(text) {
    const indentMatch = text.match(/^(\s+).*/)
    if (!indentMatch) {
      return 0;
    }
    return indentMatch[1].length / 4;
}

/**
 * Parse a TextGrid file to a javascript object.
 *
 * NOTE(jnu): this is a quick parser that isn't intended to implement the whole
 * file format, just enough to be useful.
 */
export function parse(text) {
  const json = {};
  const lists = {};
  const stack = [json];

  let lastIndent = 0;
  text.split("\n").forEach(line => {
    line = line.replace(/\s+$/, "");
    if (!line) {
      return;
    }

    // If this line is unindented, pop the previous structure(s)
    let newIndent = getIndentLevel(text);
    while (newIndent > lastIndent++) {
      stack.shift();
    }

    const [key, value] = line.replace(/^\s+/, "").split(" = ");
    
    // Boolean key
    if (/^\w+\?/.test(key)) {
      const boolKey = line.split("? ")[0];
      stack[0][boolKey] = key.endsWith("<exists>");
    }
    // List key
    else if (key.endsWith(" []:")) {
      const listKey = key.replace(/ \[\]:$/, "");
      const newList = [];
      lists[listKey] = newList;
      stack[0][listKey] = newList;
    }
    // Pre-allocated list key
    else if (key.endsWith(": size")) {
      const listKey = key.replace(": size", "");
      const newList = [];
      lists[listKey] = newList;
      stack[0][listKey] = newList;
    }
    // List map entry
    else if (/ \[\d+\]:$/.test(key)) {
      const parts = key.match(/^(\w+) \[(\d+)\]:/);
      const listKey = parts[1];
      const index = +parts[2] - 1;
      const newMap = {};
      stack.unshift(newMap);
      lists[listKey][index] = newMap;
    }
    // Normal key
    else {
      stack[0][key] = JSON.parse(value);
    }
  });

  return json;
}

// Extract a simplified array of timecodes from a parsed TextGrid file.
export function extractSimpleTimecodes(tg) {
  const timecodes = [];

  tg.item.forEach(item => {
    const firstTier = !timecodes.length;
    const name = item.name;
    // Expected tier names: "truth" and "asr"
    const contentKey = name === "truth" ? "input" : "output";

    item.intervals.forEach((iv, i) => {
      if (!firstTier) {
        timecodes[i][contentKey] = iv.text.trim();
        return;
      }

      timecodes.push({
        [contentKey]: iv.text,
        time: iv.xmin,
        duration: iv.xmax - iv.xmin,
      });
    });
  });

  return timecodes.filter(tc => tc.input !== "" || tc.output !== "");
}

import React from 'react';
import {Page, PageBody, PageText, PageTitle} from '../components/Page';
import styled from '@emotion/styled'
import {Flex, Box} from '@rebass/grid/emotion'
import * as theme from '../components/theme';

const people = [
  {
    lastName: "Jurafsky",
    firstName: "Dan",
    title: "Professor",
    department: "Linguistics & Computer Science",
    institution: "Stanford University",
    website: "https://web.stanford.edu/~jurafsky/",
  },
  {
    lastName: "Goel",
    firstName: "Sharad",
    title: "Assistant Professor",
    department: "Management Science & Engineering",
    institution: "Stanford University",
    website: "https://5harad.com/",
  },
  {
    lastName: "Rickford",
    firstName: "John R.",
    title: "Professor Emeritus",
    department: "Department of Linguistics",
    institution: "Stanford University",
    website: "https://profiles.stanford.edu/john-rickford",
  },
  {
    lastName: "Koenecke",
    firstName: "Allison",
    title: "Ph.D. Candidate",
    department: "Computational & Math Engineering",
    institution: "Stanford University",
    website: "http://stanford.edu/~koenecke/",
  },
  {
    lastName: "Lake",
    firstName: "Emily",
    title: "Ph.D. Candidate",
    department: "Department of Linguistics",
    institution: "Stanford University",
    website: "https://emilyrlake.com/",
  },
  {
    lastName: "Mengesha",
    firstName: "Zion",
    title: "Ph.D. Candidate",
    department: "Department of Linguistics",
    institution: "Stanford University",
    website: "https://ccsre.stanford.edu/people/zion-ariana-mengesha",
  },
  {
    lastName: "Quartey",
    firstName: "Minnie",
    title: "Ph.D. Candidate",
    department: "Department of Linguistics",
    institution: "Georgetown University",
  },
  {
    lastName: "Toups",
    firstName: "Connor",
    title: "Undergraduate Student",
    department: "Symbolic Systems",
    institution: "Stanford University",
  },
  {
    lastName: "Omarali",
    firstName: "Ayesha",
    title: "Undergraduate Student",
    department: "Computer Science",
    institution: "University of California, Berkeley",
  },
  {
    lastName: "Nam",
    firstName: "Andrew",
    title: "Ph.D. Candidate",
    department: "Department of Psychology",
    institution: "Stanford University",
    website: "http://web.stanford.edu/~ajhnam/",
  },
  {
    lastName: "Nudell",
    firstName: "Joe",
    title: "Staff Researcher",
    department: "Computational Policy Lab",
    institution: "Stanford University",
  },
].sort((a, b) => a.lastName < b.lastName ? -1 : 1);

export default function Outro(props) {
  return (
    <Page {...props}>
      <PageTitle>People</PageTitle>
      <PageText>We are an interdisciplinary team of linguists, social scientists, computer scientists, and statisticians.</PageText>
      <PageBody>
        <Flex flexWrap="wrap" alignItems="start">
          {people.map(p => (
            <PersonBox key={p.lastName} width={[1/2, 1/3, 1/4]}>
              <HeadshotWrapper>
                {p.website ?
                  <a href={p.website} target="_blank" rel="noopener noreferrer">
                    <Headshot src={`${process.env.PUBLIC_URL}/people/${p.lastName.toLowerCase()}.png`} />
                  </a> :
                    <Headshot src={`${process.env.PUBLIC_URL}/people/${p.lastName.toLowerCase()}.png`} />
                }
              </HeadshotWrapper>
              <NameText>
                {p.website ?
                  <a href={p.website} target="_blank" rel="noopener noreferrer">
                    <span>{p.firstName} </span>
                    <span>{p.lastName}</span>
                  </a> :
                  <>
                    <span>{p.firstName} </span>
                    <span>{p.lastName}</span>
                  </>}
              </NameText>
              <RoleText>{p.title}</RoleText>
              {p.department && <InstText>{p.department}</InstText>}
              <InstText>{p.institution}</InstText>
            </PersonBox>))}
        </Flex>
      </PageBody>
    </Page>
  );
}

const PersonBox = styled(Box)`
  padding: 1rem;
`;

const NameText = styled.div`
  font-size: 1rem;
  a {
    text-decoration: none;
  }
  a:hover {
    color: #555;
  }
`;

const RoleText = styled.div`
  font-size: 0.8rem;
  color: ${theme.gray};
  font-weight: bold;
`;

const InstText = styled.div`
  font-size: 0.8rem;
  color: ${theme.gray};
`;

const Headshot = styled.img`
  max-width: 120px;
  max-height: 120px;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const HeadshotWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 130px; 
  max-height: 130px;
`;

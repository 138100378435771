import React from 'react'
import * as diff from 'diff'
import * as theme from './theme'
import '../tooltip.css'

/**
 * Wrap an element with a tooltip behavior.
 */
function wrapWithTooltip(chunk, content, hover, click) {
  return (
    <span
      key={chunk.key}
      onMouseEnter={hover && (() => hover(content))}
      onMouseLeave={hover && (() => hover(null))}
      onClick={click && (() => click(content))}>
      {chunk}
    </span>
  );
}

/**
 * Split a string into tokens based on spaces and punctuation.
 *
 * Punctuation and spaces will be treated as individual tokens. All other
 * characters (alphanumeric, mostly) will be treated as tokens in contiguous
 * blocks.
 *
 * NOTE(jnu): avoid doing this with RegEx, since zero-length assertions are
 * not supported everywhere.
 */
function tokenize(s) {
  const parts = [""];
  let prevWasBoundary = false;
  for (let i = 0; i < s.length; i++) {
    const c = s[i];
    if (/[\.,\s\!\?\:]/.test(c)) {
      parts.push(c);
      prevWasBoundary = true;
    } else if (prevWasBoundary) {
      parts.push(c);
      prevWasBoundary = false;
    } else {
      parts[parts.length - 1] += c;
      prevWasBoundary = false;
    }
  }
  return parts;
}

export function Diff({
  a,
  b,
  focused,
  annotations,
  onHoverAnnotation,
  onClickAnnotation,
}) {
  annotations = annotations ? annotations.slice() : [];
  const chunks = [];
  const dTemp = a && b ? diff.diffWords(a, b) : []
  const d = [];

  // Expand unchanged chunks to get down to one chunk per word. Increase
  // the granularity like this so we can mark features more easily.
  for (let i = 0; i < dTemp.length; i++) {
    const part = dTemp[i];
    if (!part.removed && part.count > 1) {
      // Split at space boundaries
      const subParts = tokenize(part.value);
      for (let j = 0; j < subParts.length; j++) {
        d.push({count: 1, value: subParts[j], added: part.added, removed: part.removed});
      }
      continue;
    }
    d.push(part);
  }

  const n = d.length;

  let charPtr = 0;
  for (let i = 0; i < n; i++) {
    const part = d[i];
    const next = d[i + 1] || {};
    const currentAnnotation = annotations[0];
    const needsAnnotation = !!currentAnnotation && charPtr >= currentAnnotation.start && (!part.removed || next.added);
    const isFocused = needsAnnotation && !!focused && currentAnnotation.start === focused.start && currentAnnotation.end === focused.end;

    // Layout for a substitution
    if (part.removed && next.added) {
      const chunk = (
        <div key={i} style={{
          display: 'inline-block',
          position: "relative",
          whiteSpace: "nowrap",
          boxSizing: 'border-box',
          cursor: needsAnnotation && onClickAnnotation ? "pointer" : undefined,
        }}>
          <span style={{
            position: 'relative',
            color: 'transparent',
            pointerEvents: 'none',
            whiteSpace: "nowrap",
          }}>
            {/* Lay out the longer segment transparently to make sure the box
                gets the appropriate width. */}
            {part.value.length > next.value.length ? part.value : next.value}
          </span>
          <span style={{
            position: 'absolute',
            color: theme.red,
            textDecoration: 'line-through',
            left: 0,
            top: 0,
          }}>
            {part.value}
          </span>
          <span style={{
            position: 'absolute',
            color: theme.red,
            top: "-0.25rem",
            left: 0,
            lineHeight: 1,
            backgroundColor: needsAnnotation ?
              isFocused ? theme.highlightAccent : theme.highlight : undefined,
          }}>
            {next.value}
          </span>
        </div>
      );

      chunks.push(needsAnnotation ?
                  wrapWithTooltip(chunk, currentAnnotation, onHoverAnnotation, onClickAnnotation) :
                  chunk);

      i += 1;

      // Pop off the current annotation if it ends at this word.
      charPtr += next.value.length;
      if (needsAnnotation && charPtr >= currentAnnotation.end) {
        annotations.shift();
      }
      continue;
    }

    const chunk = (
      <span
        key={i}
        style={{
          position: 'relative',
          boxSizing: 'border-box',
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "transparent",
          color: part.added || part.removed ? theme.red : theme.gray,
          textDecoration: part.removed ? 'line-through' : 'none',
          backgroundColor: needsAnnotation && !/\s+/.test(part.value) ?
            isFocused ? theme.highlightAccent : theme.highlight : undefined,
          cursor: needsAnnotation && onClickAnnotation ? "pointer" : undefined,
        }}>
        {part.value}
      </span>
    );

    chunks.push(needsAnnotation ?
                wrapWithTooltip(chunk, currentAnnotation, onHoverAnnotation, onClickAnnotation) :
                chunk);

    charPtr += !part.removed ? part.value.length : 0;
    if (needsAnnotation && charPtr >= currentAnnotation.end) {
      annotations.shift();
    }
  }

  return <div>{chunks}</div>
}

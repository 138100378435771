import React from 'react';
import { wer } from '../data/wer.js';
import { Page, PageBody, PageText, PageTitle } from '../components/Page';
import styled from '@emotion/styled'
import { Box } from '@rebass/grid/emotion';
import * as theme from '../components/theme';
import {formatWer, formatRace} from '../format';

export default function AccuracyByRace(props) {
  return (
    <Page {...props}>
      <PageTitle>
Error rates for Black speakers nearly double those for white speakers
      </PageTitle>

      <PageBody>

        <Box width={[ 1, 1/2 ]}>
          <PageText>
    Automated speech recognition technology is everywhere, from our pockets to our homes, from the <a href="https://www.nytimes.com/2019/10/02/technology/automatic-speech-transcription-ai.html" target="_blank" rel="noopener noreferrer">doctor’s office</a> to the <a href="https://www.themarshallproject.org/2019/02/14/in-court-where-are-siri-and-alexa" rel="noopener noreferrer" target="_blank">courtroom</a>. It powers life-changing tools that help people with physical impairments interact with their digital devices. But not all of us can take advantage of this powerful new technology.
          </PageText>
          <PageText>
    In Spring 2019, we ran thousands of audio snippets of white and Black speakers through leading speech-to-text services by Amazon, Apple, Google, IBM, and Microsoft. We found that all five services showed significant racial disparities.
          </PageText>
        </Box>

        <Box width={[ 1, 1/2 ]} style={{padding: "0 2rem"}}>
          <StatTitle>
            Average error rates
          </StatTitle>
          <StatContainer>
            {wer.byRace.map(d => (
              <BigStat
                key={d.race}
                label={formatRace(d.race)}
                value={formatWer(d.wer)}
                />
            ))}
          </StatContainer>
          <StatSubtitle>
           For every hundred words, the systems made 19 errors for white speakers compared to 35 errors for Black speakers — nearly twice as many.
          </StatSubtitle>
        </Box>

      </PageBody>
    </Page>
  );
}

function BigStat({value, label}) {
  return (
    <div>
      <BigStatValue>{value}</BigStatValue>
      <BigStatLabel>{label}</BigStatLabel>
    </div>
  );
}

const StatContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: center;
`;

const BigStatValue = styled.div`
  color: ${theme.red};
  font-size: 6rem;
  font-weight: bold;
  font-family: ${theme.dataFont};
`;

const BigStatLabel = styled.div`
  font-size: 1.5rem;
  font-family: ${theme.dataFont};
  color: ${theme.slate};
`;

const StatTitle = styled.h2`
  font-size: 1.4rem;
  font-family: ${theme.dataFont};
  color: ${theme.slate};
  padding: 0;
  padding-top: 0.75rem;
  margin: 0.25rem 0;
`;

const StatSubtitle = styled.div`
  font-size: 1rem;
  font-family: ${theme.dataFont};
  color: ${theme.slate};
  font-style: italic;
  margin: 2rem 0;
`;

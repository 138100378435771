const assign = Object.assign;

// Colors
const lightBlue = "#37C1FF";
const gold = "rgb(242, 189, 0)";
const colors = [
  lightBlue,
  gold,
];

const grey = "#A4A4A4";
const lightGrey = "#ECECEC";
const darkerGrey = "#999999";
const grey900 = "#212121";

// Typography
const mono = "'Roboto Mono', courier, monospace";

const letterSpacing = "normal";
const fontSize = 12;

// Layout
const padding = 8;
const baseProps = {
  width: 350,
  height: 350,
  padding: 50
};

// * Labels
const baseLabelStyles = {
  fontFamily: mono,
  fontSize,
  letterSpacing,
  padding,
  fill: darkerGrey,
  stroke: "transparent",
  strokeWidth: 0
};

const centeredLabelStyles = assign({ textAnchor: "middle" }, baseLabelStyles);

// Strokes
const strokeDasharray = "10, 5";
const strokeLinecap = "round";
const strokeLinejoin = "round";

// Put it all together...
export const theme = {
  area: assign(
    {
      style: {
        data: {
          fill: grey900
        },
        labels: centeredLabelStyles
      }
    },
    baseProps
  ),
  axis: assign(
    {
      style: {
        axis: {
          fill: "transparent",
          stroke: "transparent",
          strokeWidth: 0,
          strokeLinecap,
          strokeLinejoin
        },
        axisLabel: assign({}, centeredLabelStyles, {
          padding: 30,
          stroke: "transparent"
        }),
        grid: {
          fill: "none",
          stroke: lightGrey,
          strokeDasharray,
          strokeLinecap,
          strokeLinejoin,
          pointerEvents: "painted"
        },
        ticks: {
          fill: "transparent",
          size: 0,
          stroke: "transparent",
          strokeWidth: 0,
          strokeLinecap,
          strokeLinejoin
        },
        tickLabels: assign({}, baseLabelStyles, {
          fill: grey,
          fontSize: 12
        })
      }
    },
    baseProps
  ),
  bar: assign(
    {
      colorScale: colors,
      style: {
        data: {
          fill: darkerGrey,
          padding,
          strokeWidth: 0
        },
        labels: baseLabelStyles
      }
    },
    baseProps
  ),
  boxplot: assign(
    {
      style: {
        max: {
          padding,
          stroke: darkerGrey,
          strokeWidth: 1
        },
        maxLabels: baseLabelStyles,
        median: {
          padding,
          stroke: darkerGrey,
          strokeWidth: 1
        },
        medianLabels: baseLabelStyles,
        min: {
          padding,
          stroke: darkerGrey,
          strokeWidth: 1
        },
        minLabels: baseLabelStyles,
        q1: {
          padding,
          fill: darkerGrey
        },
        q1Labels: baseLabelStyles,
        q3: {
          padding,
          fill: darkerGrey
        },
        q3Labels: baseLabelStyles
      },
      boxWidth: 20
    },
    baseProps
  ),
  candlestick: assign(
    {
      style: {
        data: {
          stroke: darkerGrey
        },
        labels: centeredLabelStyles
      },
      candleColors: {
        positive: "#ffffff",
        negative: darkerGrey
      }
    },
    baseProps
  ),
  chart: assign({}, baseProps, {
    padding: {
      top: 40,
      left: 50,
      right: 30,
      bottom: 50,
    },
  }),
  errorbar: assign(
    {
      borderWidth: 8,
      style: {
        data: {
          fill: "transparent",
          opacity: 1,
          stroke: darkerGrey,
          strokeWidth: 2
        },
        labels: centeredLabelStyles
      }
    },
    baseProps
  ),
  group: assign(
    {
      colorScale: colors
    },
    baseProps
  ),
  legend: {
    colorScale: colors,
    gutter: 10,
    orientation: "vertical",
    titleOrientation: "top",
    style: {
      data: {
        type: "circle"
      },
      labels: baseLabelStyles,
      title: assign({}, baseLabelStyles, { padding: 5 })
    }
  },
  line: assign(
    {
      style: {
        data: {
          fill: "transparent",
          opacity: 1,
          stroke: darkerGrey,
          strokeWidth: 2
        },
        labels: centeredLabelStyles
      }
    },
    baseProps
  ),
  pie: assign(
    {
      colorScale: colors,
      style: {
        data: {
          padding,
          stroke: lightGrey,
          strokeWidth: 1
        },
        labels: assign({}, baseLabelStyles, { padding: 20 })
      }
    },
    baseProps
  ),
  scatter: assign(
    {
      style: {
        data: {
          fill: darkerGrey,
          opacity: 1,
          stroke: "transparent",
          strokeWidth: 0
        },
        labels: centeredLabelStyles
      }
    },
    baseProps
  ),
  stack: assign(
    {
      colorScale: colors
    },
    baseProps
  ),
  tooltip: {
    style: assign({}, centeredLabelStyles, {
      padding: 5,
      pointerEvents: "none"
    }),
    flyoutStyle: {
      stroke: grey900,
      strokeWidth: 1,
      fill: "#f0f0f0",
      pointerEvents: "none"
    },
    cornerRadius: 5,
    pointerLength: 10
  },
  voronoi: assign(
    {
      style: {
        data: {
          fill: "transparent",
          stroke: "transparent",
          strokeWidth: 0
        },
        labels: assign({}, centeredLabelStyles, {
          padding: 5,
          pointerEvents: "none"
        }),
        flyout: {
          stroke: grey900,
          strokeWidth: 1,
          fill: "#f0f0f0",
          pointerEvents: "none"
        }
      }
    },
    baseProps
  )
};

import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled'
import * as d3scale from 'd3-scale';
import * as d3shape from 'd3-shape';
import {FaPause, FaPlay} from 'react-icons/fa';
import * as theme from './theme';
import {Flex, Box} from '@rebass/grid/emotion'
import {IconButton} from './IconButton';
import {mp3data} from './silence';

function getPosFromClick(e) {
  const x = e.clientX;
  const box = e.currentTarget.getBoundingClientRect();
  return (x - box.x) / box.width;
}

/**
 * Component to load and play given audio snippet.
 */
export function AudioPlayer({
  clip,
  samples,
  attribution,
  annotations,
  onReset,
  onClick,
  onFocusAnnotation,
  onHoverAnnotation,
  snippet,
  focused,
}) {
  annotations = annotations || [];
  samples = samples || 300;
  const [paused, setPaused] = useState(true);
  const [tick, setTick] = useState(0);
  const [loadingCurve, setLoadingCurve] = useState("");
  const [pct, setPct] = useState(0);
  const [frame, requestNewFrame] = useState(0);
  const audioSource = useAudioContext(clip, 0)[1];

  // Pause any playing audioSource when a new clip is loaded.
  useEffect(() => {
    if (!audioSource) {
      return;
    }

    audioSource.pause();
  }, [clip, audioSource]);

  // Play the requested section of audio
  useEffect(() => {
    if (!audioSource) {
      return;
    }

    const {start, end} = snippet;

    if (start !== undefined && start !== null) {
      audioSource.setOffset(start);
    }

    audioSource.setEnd(end || null);

    if (snippet.forcePlay && audioSource.isPaused()) {
      audioSource.play();
      audioSource.unmute();
    }
  }, [audioSource, snippet]);

  // Initialize an audioSource when a new one is loaded.
  useEffect(() => {
    if (!audioSource) {
      return;
    }

    audioSource.onstatechange = () => {
      setPaused(audioSource.isPaused());
      if (audioSource.isEnded()) {
        audioSource.reset();
      }
    };

    audioSource.onreset = onReset;

    setPct(0);
  }, [audioSource, onReset]);

  // Ensure that audioSource `paused` state is in sync with component state.
  const doPause = toggle => {
    if (!audioSource) {
      return;
    }

    if (!toggle) {
      if (onFocusAnnotation) {
        onFocusAnnotation(null);
      }
      audioSource.play();
      audioSource.unmute();
    } else {
      audioSource.pause();
    }
  };

  // Render updates to progress ticker.
  useEffect(() => {
    if (!audioSource) {
      return;
    }

    const req = requestAnimationFrame(() => {
      const max = audioSource.buffer.duration;
      const t = audioSource.currentPlaybackTime();
      setPct(t / max);
      // Ensure re-render as long as the audio is playing.
      if (!paused) {
        requestNewFrame(frame + 1);
      }
    });

    return () => cancelAnimationFrame(req);
  });

  // Width and height here are just used for SVG viewbox. The resuling
  // visualization is responsive.
  const width = 1000;
  const height = 100;

  const loading = !audioSource;

  const audioViz = useVisualizeAudioWave({
    source: audioSource,
    width,
    height,
    samples,
  });

  useEffect(() => {
    if (!loading) {
      return;
    }

    const z = 200;
    const x = d3scale.scaleLinear()
      .domain([0, z])
      .range([0, width]);

    const y = d3scale.scaleLinear()
      .domain([-1, 1])
      .range([height - 4, 4]);

    const line = d3shape.line()
      .curve(d3shape.curveBasis)
      .x(d => x(d[0]))
      .y(d => y(d[1]));

    requestAnimationFrame(dt => {
      const a = new Array(z * 2);
      const b = Math.round(dt / 20) % z;
      for (let i = 0; i < z; i++) {
        const j = z + (z - 1 - i);
        if ((b + i) % 20 === 0) {
          a[i] = [i, 0.5];
          a[j] = [i, -0.5];
        } else {
          a[i] = [i, 0];
          a[j] = [i, 0];
        }
      }

      setLoadingCurve(line(a));
      setTick(tick + 1);
    });
  }, [tick, loadingCurve, loading]);

  const timeWidth = audioSource ? audioSource.buffer.duration : 1;
    
  return (
    <>
    <Flex alignContent="space-evenly" alignItems="center">
      <Box flexBasis="4rem">
        <IconButton onClick={() => doPause(!paused)} disabled={loading}>
          {paused ?
            <FaPlay color={theme.slate} size="1.5em" /> :
            <FaPause color={theme.slate} size="1.5em" />}
        </IconButton>
      </Box>
      <Box flexBasis="100%" style={{height: "auto"}}>
        <svg
          viewBox={`0 0 ${width} ${height}`}
          onClick={onClick && (e => onClick(getPosFromClick(e) * audioSource.buffer.duration))}
          style={{
            width: "100%",
            height: "auto",
            //borderTop: `1px solid ${theme.gray}`,
            //borderBottom: `1px solid ${theme.gray}`,
            cursor: onClick ? "crosshair" : null,
          }}>
          <g>
            <path
              d={loading ? loadingCurve : audioViz}
              fill={theme.darkGray}
              stroke={theme.slate}
              strokeWidth={2}
              />
          </g>
          <g>
          {annotations.map((atn, i) => {
            const x = width * atn.timeStart / timeWidth;
            const rectWidth = width * (atn.timeEnd - atn.timeStart) / timeWidth;
            const isFocused = !!focused && focused.timeEnd === atn.timeEnd && focused.timeStart === atn.timeStart;
            return (
              <rect
                key={i}
                x={x || 0}
                y={0}
                width={rectWidth || 0}
                height={height || 0}
                fill={isFocused ? theme.highlight : "transparent"}
                onMouseEnter={onHoverAnnotation && (() => onHoverAnnotation({...atn}))}
                onMouseLeave={onHoverAnnotation && (() => onHoverAnnotation(null))}
                onClick={onFocusAnnotation && (e => {
                  onFocusAnnotation({...atn});
                  e.stopPropagation();
                  e.preventDefault();
                  return false;
                })}
                />
            );
          })}
          </g>
          <g style={{
            pointerEvents: "none",
            display: loading ? "none" : null,
          }}>
            <line
              y1={0}
              y2={height}
              x1={pct * width}
              x2={pct * width}
              stroke={theme.red}
              strokeWidth={4}
              />
          </g>
        </svg>
      </Box>
    </Flex>
    <Attribution>{attribution}</Attribution>
    </>
  );
}

const Attribution = styled.div`
  padding: 0.2rem 0;
  font-size: 0.75rem;
  color: ${theme.gray};
  width: 100%;
  text-align: right;
  a {
    color: ${theme.gray};
  }
`;

/**
 * Create an SVG path string based on the wave form in the given source audio
 * buffer.
 */
function useVisualizeAudioWave({source, width, height, samples}) {
  const [path, setPath] = useState("");

  useEffect(() => {
    if (!source) {
      return;
    }

    const raw = [];
    for (let j = 0; j < source.buffer.numberOfChannels; j++) {
      raw.push(source.buffer.getChannelData(j));
    }

    const blockSize = Math.floor(source.buffer.length / samples);
    // Array for storing up and down waveforms
    const downsampled = new Array(2 * samples);

    let min = Infinity;
    let max = -Infinity;

    // Downsample by computing average value for each block.
    for (let i = 0, block = 0, sumUp = 0, sumDown = 0, curBlockSize = 0; i < source.buffer.length;) {
      let cellSum = 0;
      for (let j = 0; j < raw.length; j++) {
        cellSum += raw[j][i];
      }

      const cellAvg = cellSum / raw.length / blockSize;
      if (cellAvg > 0) {
        sumUp += cellAvg;
      } else {
        sumDown += cellAvg;
      }
      curBlockSize += 1;
      i += 1;

      const last = i === source.buffer.length;

      if (curBlockSize === blockSize || last) {
        downsampled[block] = [block, sumUp];
        // Insert negatives in reverse order, after the positives samples.
        // The generated SVG curve will go left-to-right for generating the
        // top curve, then right-to-left for the bottom curve to end up back
        // at the origin.
        downsampled[samples + (samples - 1 - block)] = [block, sumDown];
        curBlockSize = 0;
        block += 1;
        if (sumUp > max) {
          max = sumUp;
        }
        if (sumDown < min) {
          min = sumDown;
        }
        sumUp = 0;
        sumDown = 0;
      }

      if (last) {
        break;
      }
    }

    const x = d3scale.scaleLinear()
      .domain([0, samples])
      .range([0, width]);
    const y = d3scale.scaleLinear()
      .domain([min, max])
      .range([height - 4, 4]);

    const line = d3shape.line()
      .curve(d3shape.curveCardinalClosed.tension(0.5))
      .x(d => x(d[0]))
      .y(d => y(d[1]));

    const p = line(downsampled);
    setPath(p);
  }, [source, width, height, samples]);

  return path;
}

/**
 * Pad the given buffer with the number of seconds of silence, before and
 * after the buffer plays.
 */
function addSilence(ctx, buf, silence) {
  // Append silence before and after actual audio
  const silentBufLength = silence * buf.sampleRate;
  const silentBuf = ctx.createBuffer(buf.numberOfChannels, silentBufLength, buf.sampleRate);
  const fullBuf = ctx.createBuffer(buf.numberOfChannels, 2 * silentBufLength + buf.length, buf.sampleRate);

  for (let i = 0; i < buf.numberOfChannels; i++) {
    const chan = fullBuf.getChannelData(i);
    chan.set(silentBuf.getChannelData(i), 0);
    chan.set(buf.getChannelData(i), silentBuf.length);
    chan.set(silentBuf.getChannelData(i), silentBuf.length + buf.length);
  }

  return fullBuf;
}

/**
 * Logic for loading an audio clip into an audio context and returning it.
 *
 * The `silence` parameter adds the specified number of silence before and
 * after the clip starts.
 */
export function useAudioContext(clip, silence) {
  const [source, setSource] = useState(null);
  const [analyzer, setAnalyzer] = useState(null);
  silence = silence || 0;

  useEffect(() => {
    setSource(null);
    setAnalyzer(null);
    if (!clip) {
      return;
    }
    const ctx = GlobalAudioContext.get();
    const analyze = ctx.createAnalyser();
    analyze.fftSize = 2**14;
    fetch(`${process.env.PUBLIC_URL}/audio/${clip}`)
      .then(resp => resp.arrayBuffer())
      .then(buf => {
        ctx.decodeAudioData(buf, decoded => {
          // NOTE(jnu): can't use promise version for Safari support
          const fullBuf = silence ? addSilence(ctx, decoded, silence) : decoded;
          const s = new TimedAudioBufferSource(GlobalAudioContext, fullBuf, analyze);
          setSource(s);
          setAnalyzer(analyze);
        });
      });
  }, [clip, silence]);

  return [analyzer, source];
}

/**
 * Get the most precise 'now' available.
 */
function now() {
  if (window.performance && window.performance.now) {
    return window.performance.now();
  }
  return Date.now();
}

/**
 * A singleton manager for the global AudioContext.
 *
 * This simplifies managing snippets and ensures only one source is ever
 * loaded at a time (to prevent a cacophony).
 */
const GlobalAudioContext = {

  _ctx: null,

  _gain: null,

  _currentSource: null,

  _muted: false,

  _createdTime: null,

  _initTime: null,

  /**
   * Check if the context has successfully started, or if it has been blocked
   * by the browser's anti-nuisance policies.
   */
  isInited: function() {
    return !!this._initTime;
  },

  /**
   * Get the underlying audio context.
   */
  get: function() {
    if (!this._ctx) {
      this._createdTime = now();
      const ctx = new (window.AudioContext || window.webkitAudioContext)();

      // XXX(jnu): hack to make sure AudioContext can start playing audio on
      // every device.
      let gestureHandled = false;
      const handleFirstGesture = () => {
        if (gestureHandled) {
          return;
        }

        // Optimistically try to start
        ctx.resume()
          .then(() => {
            // Cleanup
            window.removeEventListener("mousedown", handleFirstGesture);
            window.removeEventListener("touchstart", handleFirstGesture);
            window.removeEventListener("touchend", handleFirstGesture);
            gestureHandled = true;
          });

        // HACK(jnu): play a few milliseconds of silence through the HTML5
        // audio API. Somehow this lets the AudioContext play even when the
        // mobile device is on silent mode.
        const a = document.createElement("audio");
        a.autoplay = true;
        a.muted = false;
        const src = document.createElement("source");
        src.type = "audio/mp3";
        src.src = mp3data;
        a.appendChild(src)

        // XXX(jnu): Play an empty sound through the AudioContext too to warm
        // it up, though this is an old hack and doesn't seem to work anymore.
        const emptyBuf = ctx.createBuffer(1, 1, 22050);
        const emptySrc = ctx.createBufferSource();
        emptySrc.buffer = emptyBuf;
        emptySrc.connect(ctx.destination);
        playSourceNow(emptySrc);
      };
      window.addEventListener("mousedown", handleFirstGesture, true);
      window.addEventListener("touchstart", handleFirstGesture, true);
      window.addEventListener("touchend", handleFirstGesture, true);


      // Try to initialize and mark the time when that finally succeeds.
      ctx.resume()
        .then(() => {
          this._initTime = now();

          if (this._currentSource) {
            this._currentSource.resumeAt(this.currentTime());
          }
        });

      const gain = ctx.createGain();
      gain.connect(ctx.destination);

      ctx.onstatechange = () => {
        if (!this._currentSource) {
          return;
        }

        const src = this._currentSource;

        switch (ctx.state) {
          case "suspended":
            src.pause();
            break;
          case "closed":
            src.pause();
            break;
          case "running":
            src.play();
            break;
          default:
            break;
        }
      };

      this._ctx = ctx;
      this._gain = gain;
    }

    return this._ctx;
  },

  /**
   * Stop and clear any existing audio source.
   */
  clear: function() {
    this.get();

    if (this._currentSource) {
      const src = this._currentSource;
      this._currentSource = null;
      // XXX(jnu): this is circular (safely so), but should clean up.
      src.pause();
    }

  },

  /**
   * Test if context has no loaded source.
   */
  isCleared: function() {
    return !this._currentSource;
  },

  /**
   * Queue up given audio source in the context.
   *
   * Any existing source will be stopped and removed.
   */
  loadSource: function(source) {
    // Ensure that the context is initialized
    this.get();

    if (this._currentSource) {
      if (this._currentSource !== source) {
        this._currentSource.pause();
      } else {
        // If the source is unchanged, exit early.
        return;
      }
    }

    this._currentSource = source;

    source.node().connect(this._gain);
  },

  /**
   * Mute the audio context.
   */
  mute: function() {
    if (this._muted) {
      return;
    }
    this._muted = true;
    return this._gain.gain.setValueAtTime(0, this._ctx.currentTime);
  },

  /**
   * Unmute the audio context.
   */
  unmute: function() {
    if (!this._muted) {
      return;
    }
    this._muted = false;
    return this._gain.gain.setValueAtTime(1, this._ctx.currentTime);
  },

  isMuted: function() {
    return this._muted;
  },

  /**
   * Get the current playback time. Accounts for time before the context
   * could be initialized due to lack of permissions.
   */
  currentTime: function() {
    if (!this.isInited()) {
      return (now() - this._createdTime) / 1000;
    }

    return (this._initTime - this._createdTime) / 1000 + this._ctx.currentTime;
  },

}

/**
 * Start a source immediately with the given offset with legacy support.
 */
function playSourceNow(s, offset) {
  if (s.start) {
    s.start(0, offset);
  } else if (s.play) {
    s.play(0, offset);
  } else if (s.noteOn) {
    s.noteOn(0, offset);
  } else {
    console.error("Not sure what API to use to play source");
  }
}

/**
 * Stop a playing source node immediately with legacy support.
 */
function stopSourceNow(s) {
  if (s.stop) {
    this._source.stop(0);
  } else if (s.noteOff) {
    s.noteOff(0);
  } else {
    console.error("Not sure what API to use to stop source");
  }
}

/**
 * Wrapper for an audio source buffer node.
 *
 * Provides convenience methods to check playback time and state.
 */
class TimedAudioBufferSource {

  constructor(ctx, buffer, analyze) {
    this.buffer = buffer;
    this._context = ctx;
    this._analyze = analyze;
    this._offset = 0;
    this._playTime = 0;
    this._source = null;
    this._end = null;
    this.onstatechange = null;
    this.onreset = null;
  }

  _notifyStateChange() {
    if (this.onstatechange) {
      this.onstatechange();
    }
  }

  mute() {
    this._context.get().resume();
    return this._context.mute();
  }

  unmute() {
    this._context.get().resume();
    return this._context.unmute();
  }

  isMuted() {
    return this._context.isMuted();
  }

  /**
   * Get the underlying source audio node.
   */
  node() {
    return this._source;
  }

  /**
   * Set the snippet to end at the given time in seconds.
   *
   * Pass null or 0 to unset.
   */
  setEnd(pos) {
    this._end = pos;
  }

  /**
   * Set the playback cursor to the given time in seconds.
   */
  setOffset(pos) {
    if (!this.isPaused()) {
      this.pause();
      this._offset = pos;
      this.play();
    } else {
      this._offset = pos;
    }
  }

  /**
   * Play / resume audio at given playback position.
   */
  play() {
    if (this._source) {
      return;
    }

    this._context.clear();
    const s = this._context.get().createBufferSource();
    s.buffer = this.buffer;
    if (this._analyze) {
      s.connect(this._analyze);
    }

    this._source = s;
    this._context.loadSource(this);

    // Mark the time that the audio starts playing in the context's timeline.
    this._playTime = this._context.currentTime();

    if (this._context.isInited()) {
      playSourceNow(s, this._offset);
    } else {
      console.warn("Avoiding audio play because context is not ready");
    }

    this._context.get().resume();
    // If the audio context was not ready, the audio will not be started. But
    // the timer is abstracted over the actual audio, so any animations
    // pegged to it can start immediately.
    this._notifyStateChange();
  }

  resumeAt(t) {
    let delta = t - this._playTime;
    if (delta >= this.buffer.duration) {
      delta = 0;
    }
    playSourceNow(this._source, delta);
  }

  /**
   * Test if audio has played to the end.
   */
  isEnded() {
    return this.currentPlaybackTime() >= this.buffer.duration;
  }

  /**
   * Reset playback to beginning paused state.
   */
  reset() {
    this._offset = 0;
    this._end = null;
    this._source = null;
    if (this.onreset) {
      this.onreset();
    }
  }

  /**
   * Check the current source playback time.
   */
  currentPlaybackTime() {
    if (!this._source) {
      return this._offset;
    }

    const t1 = this._context.currentTime();
    const t0 = this._playTime;
    const offset = this._offset;
    const t = Math.min(offset + (t1 - t0), this.buffer.duration);

    // Ensure the end is caught
    // XXX(jnu): workaround in case end is reached before AudioContext is
    // actually allowed to start. The `onended` event is also not always
    // reliable.
    if (t >= this.buffer.duration || (this._end && t >= this._end)) {
      this.pause();
      // Unset the end property so the play button can be clicked again.
      this._end = null;
    }

    return t;
  }

  /**
   * Check if playback is paused.
   */
  isPaused() {
    return !this._source;
  }

  /**
   * Pause (stop) playback.
   *
   * Playback will be resumed at the current time.
   */
  pause() {
    // XXX(jnu): try to run AudioContext optimistically if it hasn't been
    // allowed to start yet, even though the audio will be stopped here.
    this._context.get().resume();

    // If the audio is already in a paused state ,return early.
    if (!this._source) {
      return;
    }

    // Drop the entire source node
    this._source.disconnect();
    try {
      stopSourceNow(this._source);
    } catch {
      // If the source could never be started, this will just break.
    }
    this._source = null;
    this._context.clear();

    this._offset += this._context.currentTime() - this._playTime;
    this._notifyStateChange();
  }

}

import React, { useEffect, useState, useRef } from 'react'
import { Element, Link, scroller } from 'react-scroll'
import styled from '@emotion/styled'
import './App.css'
import { Navbar } from './components/Navbar'
import { AppContainer } from './components/AppContainer.js'
import { Footer } from './components/Footer'
import { SpeakerAnimation } from './components/SpeakerAnimation'
import * as timecoded from './data/timecoded'
import * as theme from './components/theme'
import { vhProp } from './style'

import Intro from './pages/Intro.js'
import AccuracyByRace from './pages/AccuracyByRace.js'
import AccuracyBySex from './pages/AccuracyBySex.js'
import AccuracyByProduct from './pages/AccuracyByProduct.js'
import DDM from './pages/DDM.js'
import Video from './pages/Video.js'
import Outro from './pages/Outro.js'

const pages = [
  AccuracyByRace,
  AccuracyBySex,
  AccuracyByProduct,
  DDM,
  Video,
  Outro,
]

function getScreenSize() {
  return {
    w: window.innerWidth,
    h: window.innerHeight,
  }
}

function setCssHeight(x) {
  document.documentElement.style.setProperty('--vh', `${x * 0.01}px`)
}

// Set height when page loads
setCssHeight(getScreenSize().h)

let listeningForScroll = false

export default function App() {
  const firstPageId = 'intro'
  const lastPageId = 'footer'
  const pageIds = pages.map((_, i) => `page${i}`)
  const { w, h } = getScreenSize()
  const [width, setWidth] = useState(w)
  const [height, setHeight] = useState(h)
  const [page, setPage] = useState(null)
  const [onLastPage, setOnLastPage] = useState(false)
  const onContentPage = page !== firstPageId && !onLastPage
  const introText = useRef(null)

  // Resize the visualization when the window is resized
  useEffect(() => {
    // Resize handler
    let resizeTick = false

    const resize = () => {
      let { w, h } = getScreenSize()

      if (!resizeTick) {
        requestAnimationFrame(() => {
          setWidth(w)
          setHeight(h)
          setCssHeight(h)
          resizeTick = false
        })
      }

      resizeTick = true
    }

    window.addEventListener('resize', resize)

    // Cleanup
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  useEffect(() => {
    if (listeningForScroll || !page || page === firstPageId) {
      return
    }

    // Scroll handler
    // XXX(jnu): this is a messy hack for react-scroll to define the
    // scroll to the last page, which never actually gets fully scrolled
    // into view.
    let scrollTick = false

    const scroll = () => {
      if (!scrollTick) {
        requestAnimationFrame(() => {
          const footer = document.getElementById('footer')
          const nav = document.getElementById('nav')
          if (!footer || !nav) {
            scrollTick = false
            return
          }

          const footerTop = footer.getBoundingClientRect().top
          const navBottom = nav.getBoundingClientRect().bottom

          if (footerTop < navBottom) {
            setOnLastPage(true)
          } else {
            setOnLastPage(false)
          }
          scrollTick = false
        })
      }
      scrollTick = true
    }

    const scrollContainer = document.getElementById('scrollContainer')
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', scroll)
      listeningForScroll = true
    }
  }, [page])

  let vRatio = 0.75
  if (introText.current) {
    const rect = introText.current.getBoundingClientRect()
    vRatio = 1 - rect.height / height
  }

  return (
    <AppContainer>
      <ScrollContainer id="scrollContainer" name="scrollContainer">
        <Link
          containerId="scrollContainer"
          isDynamic={true}
          to={firstPageId}
          spy={true}
          onSetActive={setPage}
        />
        <PageBlock name={firstPageId}>
          <AnimationContainer
            style={{
              opacity: page === firstPageId ? 1 : 0,
              height: `${Math.ceil(100 * vRatio)}vh`,
            }}
          >
            <SpeakerAnimation
              width={width}
              height={vRatio * height}
              snippet={timecoded.snippets[0]}
            />
          </AnimationContainer>
          <div ref={introText}>
            <Intro
              onScrollClick={() =>
                scroller.scrollTo(pageIds[0], {
                  smooth: true,
                  containerId: 'scrollContainer',
                })
              }
            />
          </div>
        </PageBlock>

        {/* Main content */}
        <div>
          {pages.map((Page, i) => (
            <PageBlock name={pageIds[i]} key={pageIds[i]}>
              <Page
                style={{
                  backgroundColor: i % 2 ? theme.lightestGray : theme.white,
                }}
              />
            </PageBlock>
          ))}

          <NavContainer
            style={{ visibility: !onContentPage ? 'hidden' : null }}
          >
            <Navbar
              pages={pageIds}
              containerId="scrollContainer"
              active={page}
              setPage={setPage}
            />
          </NavContainer>
        </div>

        <Link
          containerId="scrollContainer"
          isDynamic={true}
          to={lastPageId}
          spy={true}
          onSetActive={setPage}
        />
        <PageBlock name={lastPageId}>
          <section
            style={{
              height: '20vh',
              backgroundColor: theme.white,
              position: 'relative',
            }}
          />
          <Footer />
        </PageBlock>
      </ScrollContainer>
    </AppContainer>
  )
}

const PageBlock = styled(Element)`
  position: relative;
  scroll-snap-align: start;
`

const ScrollContainer = styled(Element)`
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y proximity;
`

const NavContainer = styled.div`
  position: fixed;
  left: 2rem;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 420px) {
    left: 1rem;
  }
`

const AnimationContainer = styled.div`
  ${vhProp('height', 75)}
  position: relative;
  width: 100%;
  background-color: ${theme.black};
  z-index: 0;
  transition: opacity 0.25s ease-in-out;
`

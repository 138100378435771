import React from 'react';
import styled from '@emotion/styled'
import { ThemeProvider } from 'emotion-theming';

const StyledAppContainer = styled('div')`
  text-align: center;
`;

const theme = {
  breakpoints: ['600px', '768px'],
};

export const mq = theme.breakpoints.map(bp => `@media (min-width: ${bp})`);

export function AppContainer(props) {
  return (
    <ThemeProvider theme={theme}>
      <StyledAppContainer {...props}>
        {props.children}
      </StyledAppContainer>
    </ThemeProvider>
  );
}


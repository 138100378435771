import React from 'react'
import { VictoryPortal, VictoryLabel } from 'victory'
import * as theme from '../components/theme'

export function ChartTitle({ text }) {
  return (
    <VictoryPortal>
      <VictoryLabel
        text={text}
        x={50}
        y={0}
        style={{
          textAnchor: 'start',
          verticalAnchor: 'start',
          fill: theme.gray,
          fontFamily: theme.titleFont,
          fontSize: '20px',
          fontWeight: 'bold',
        }}
        textAnchor="start"
      />
    </VictoryPortal>
  )
}

export function BetterWorseLabels(props) {
  const xOffset = props.xOffset || 0;
  return (
    <g>
      <VictoryLabel
        text="worse →"
        angle={-90}
        style={{
          fill: theme.red,
          fontWeight: 'bold',
          fontFamily: theme.dataFont,
          fontSize: 10,
          textAnchor: "end",
        }}
        x={xOffset}
        y={props.scale.y(props.topAnchor)}
      />
      <VictoryLabel
        text="← better"
        angle={-90}
        style={{
          fill: theme.red,
          fontWeight: 'bold',
          fontFamily: theme.dataFont,
          fontSize: 10,
        }}
        x={xOffset}
        y={props.scale.y(props.bottomAnchor)}
      />
    </g>
  )
}

export const black = '#111';
export const slate = '#333';
export const white = '#fff';
export const darkGray = "#666";
export const gray = '#999';
export const lightestGray = '#fafafa';
export const lighterGray = '#bcbcbc';
export const lightGray = '#cdcdcd';
export const blue = '#37C1FF';
export const red = '#F83030';
export const yellow = '#FBFF4D';
export const highlight = '#37C1FF99';
export const highlightAccent = '#1791FF99';
export const orange = "#FF9800";
export const gold = "rgb(242, 189, 0)";

export const bodyFont = 'Roboto, sans-serif';
export const titleFont = "'Roboto Slab', display";
export const dataFont = "'Roboto Mono', monospace";

export {theme as chart} from './chartTheme';

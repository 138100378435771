import React from 'react';
import styled from '@emotion/styled'
import {Flex, Box} from '@rebass/grid/emotion';
import * as theme from './theme';
import {vhProp} from '../style';
import {FaGithub, FaEnvelope, FaTwitter} from 'react-icons/fa';

export function Footer() {
  return (
    <FooterContainer id="footer">
      <Flex justifyContent="center" alignItems="start" flexWrap="wrap">
        <Box width={[1, 1/2, 1/2]}>
          <FooterTitleText>
            Contact
          </FooterTitleText>
          <FooterText>
            <LinkList>
              <li><a href="mailto:fairspeech@lists.stanford.edu"><FaEnvelope /><span>fairspeech@lists.stanford.edu</span></a></li>
              <li><a href="https://twitter.com/CompPolicyLab?s=20" rel="noopener noreferrer" target="_blank"><FaTwitter /><span>@CompPolicyLab</span></a></li>
            </LinkList>
          </FooterText>
        </Box>
        <Box width={[1, 1/2, 1/2]}>
          <FooterTitleText>
            Links
          </FooterTitleText>
          <FooterText>
            <LinkList>
              <li><a href="https://www.pnas.org/cgi/doi/10.1073/pnas.1915768117" rel="noopener noreferrer" target="_blank">Read our paper in PNAS</a></li>
              <li><a href="https://github.com/stanford-policylab/asr-disparities" rel="noopener noreferrer" target="_blank"><FaGithub /><span>View our code on GitHub</span></a></li>
              <li><a href="https://policylab.stanford.edu/" rel="noopener noreferrer" target="_blank">Stanford Computational Policy Lab</a></li>
            </LinkList>
          </FooterText>
        </Box>
      </Flex>
      <BottomText>
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/stanford_engineering.svg`}
            alt="Stanford Engineering"
            width={240} />
        </div>
        <div>
          <ScplLogo
            alt="SCPL"
            src={`${process.env.PUBLIC_URL}/scpl_logo.svg`} /> STANFORD COMPUTATIONAL POLICY LAB
        </div>
        <CopyrightText>
          All content © {(new Date()).getFullYear()} Stanford Computational Policy Lab. All rights reserved.
        </CopyrightText>
      </BottomText>
    </FooterContainer>
  );
}

const BottomText = styled.div`
  width: 100%;
  font-size: 0.7rem;
  text-align: center;
  padding-top: 3rem;
  div + div {
    padding-top: 0.5rem;
  }
`;

const CopyrightText = styled.div`
  color: ${theme.lightGray};
`;

const ScplLogo = styled.img`
  width: 1.5rem;
  top: 0.15rem;
  position: relative;
  margin-right: 0.3rem;
`;

const LinkList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    margin: 0.5rem 0;

    svg {
      top: 0.1rem;
      position: relative;
      margin-right: 0.5rem;
    }
  }
`;

const FooterContainer = styled.footer`
  text-align: left;
  color: ${theme.white};
  padding: 2rem 1rem 1rem 1rem;
  background-color: ${theme.slate};
  ${vhProp("min-height", 30)}
`;

const FooterTitleText = styled.h3`
  max-width: 16rem;
  margin: 0 auto;
  font-family: ${theme.titleFont};
`;

const FooterText = styled.div`
  max-width: 16rem;
  font-family: ${theme.bodyFont};
  margin: 1rem auto;
  font-size: 1rem;
`;

import React from 'react';
import { Page } from '../components/Page';
import {FaCaretDown} from 'react-icons/fa';
import styled from '@emotion/styled'
import * as theme from '../components/theme';
import { Flex, Box } from '@rebass/grid/emotion'

export default function Intro({onScrollClick}) {
  return (
    <Page style={{
      backgroundColor: "#333333cc",
      padding: 0,
      minHeight: "calc(var(--vh, 1vh) * 25)",
    }}>
      <SplashTextBlock>
        <MainTitle>
The Race Gap in Speech Recognition Technology
        </MainTitle>
        <TextContainer justifyContent="space-between" alignItems="center">
        <IntroText>
The leading speech recognition tools misunderstand Black speakers twice as often as white speakers. To close the gap, we must create more linguistically diverse and inclusive datasets. Read our paper <a href="https://www.pnas.org/cgi/doi/10.1073/pnas.1915768117" rel="noopener noreferrer" target="_blank">here</a>.
        </IntroText>
        <ScrollText onClick={onScrollClick} style={{cursor: onScrollClick ? "pointer" : null}}>
          <div style={{position: "relative", top: ".75rem"}}>
            SCROLL
          </div>
          <div style={{position: "relative", top: "-.75rem"}}>
            <FaCaretDown color={theme.white} size="5rem" />
          </div>
        </ScrollText>
        </TextContainer>
      </SplashTextBlock>
    </Page>
  );
}

const ScrollText = styled(Box)`
  text-align: center;
  font-family: ${theme.dataFont};
  font-size: 0.9rem;
`;

const SplashTextBlock = styled.div`
  width: 100%;
  position: relative;
`;

const MainTitle = styled.h1`
  margin-left: 10%;
  text-align: left;
  color: ${theme.white};
  font-family: ${theme.titleFont};
  font-size: 2rem;
`;

const TextContainer = styled(Flex)`
  color: ${theme.white};
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 1.5rem;
  width: 100%;
`;

const IntroText = styled(Box)`
  margin-bottom: 1.5rem;
  line-height: 1.5;
  font-size: 1.1rem;
  max-width: 35rem;
  text-align: left;
  a {
    color: white;
  }
`;

import React, {useEffect, useState, useRef} from 'react';
import * as d3shape from 'd3-shape';
import * as d3scale from 'd3-scale';
import styled from '@emotion/styled';
import {Flex} from '@rebass/grid/emotion';
import ScaleLoader from 'react-spinners/ScaleLoader';
import * as textgrid from '../textgrid';
import {IconButton} from '../components/IconButton';
import * as theme from '../components/theme';
import {useAudioContext} from '../components/AudioPlayer';
import {FaPause, FaPlay, FaVolumeMute} from 'react-icons/fa';

/**
 * Logic for loading a TextGrid transcription for the specified clip and
 * returning the timecoded transcription.
 */
function useTranscription(clip, silence) {
  const [tx, setTx] = useState(null);
  silence = silence || 0;

  useEffect(() => {
    setTx(null);

    fetch(`${process.env.PUBLIC_URL}/audio/${clip}`)
      .then(resp => resp.text())
      .then(text => {
        const tg = textgrid.parse(text);
        const tc = textgrid.extractSimpleTimecodes(tg);
        tc.forEach(x => x.time += silence);
        setTx(tc);
      });
  }, [clip, silence]);

  return tx;
}

const headSilhouettePath = `
M420 3530 c0 -18 54 -279 69 -337 6 -21 26 -105 46 -188 33 -142 42 -175 79 -320 55 -215 63 -271 42 -294 -39 -43 -319 -516 -370 -625 -106 -226 -158 -593 -111 -781 37 -146 115 -279 243 -409 175 -178 408 -321 607 -371 116 -30 298 -34 454 -11 53 8 141 20 196 26 306 35 430 70 523 147 70 58 111 178 112 329 0 53 7 76 51 167 50 106 89 221 89 265 0 12 -9 49 -20 82 -32 96 -41 182 -24 246 17 68 48 117 124 197 117 125 148 175 135 224 -6 26 -40 62 -124 132 -47 38 -52 65 -19 106 21 27 21 28 3 62 -23 43 -84 88 -147 109 -26 8 -48 17 -48 18 0 2 22 17 50 34 50 31 90 82 90 115 0 10 -13 30 -29 45 -42 39 -45 62 -20 140 25 80 22 107 -21 156 -57 64 -84 71 -275 69 -148 -1 -319 -17 -421 -38 l-31 -7 -18 74 c-38 163 -63 347 -67 500 -2 97 -6 118 -18 118 -8 0 -69 -7 -135 -15 -260 -31 -710 -15 -932 34 -76 16 -83 16 -83 1z
`;
const deviceSilhouettePath = `
m2403 3710h-1024c0-163 38-317 106-454h-1485v-3256h4885v3256h-1563c68 137 106 291 106 454h-1024zm-2130-3509h4337 72v72 2709 72h-72-4337-72v-72-2709-72h72zm4265 145h-4192v2564h4192v-2564z
`;

function jitter(magnitude) {
  return Math.random() * magnitude - (magnitude / 2);
}

/**
 * Compute responsive space / dimensions based on available space.
 */
function computeDimensions(aspect, width, height, textLength) {
  const headSize = {
    width: 2510,
    height: 3362,
  };
  const deviceRingSize = {
    width: 7100,
    height: 7100,
  };
  const deviceSize = {
    width: 4885,
    height: 3710,
  };
  const screenCenter = {
    x: width / 2,
    y: height / 2,
  };

  if (aspect < 1.2) {
    // Vertical orientation
    const half = width / 2;
    
    // Head
    const headScale = Math.min(0.65 * half / headSize.width, 0.5 * height / headSize.height);
    const headTranslateX = (half - headScale * headSize.width) / 2;
    const headTranslateY = 0;

    // Device
    const deviceRingScale = 0.95 * half / deviceRingSize.width;
    const deviceRingTranslateX = width * 3 / 4;
    const deviceRingTranslateY = deviceRingScale * deviceRingSize.height / 2;
    const deviceScale = Math.min(0.5 * half / deviceSize.height, 0.5 * width / deviceSize.width);
    const deviceTranslateX = half + (half - deviceScale * deviceSize.width) / 2;
    const deviceTranslateY = deviceScale * deviceSize.height / 2;

    // Text
    const textWidth = width * 0.9;
    const fontSize = width / 25;
    const lineHeight = 2.5 * fontSize;

    return {
      breaks: [0],
      transcriptionWidth: textWidth,
      headTransform: `translate(${headTranslateX} ${headTranslateY}) scale(${headScale})`,
      deviceRingTransform: `translate(${deviceRingTranslateX} ${deviceRingTranslateY}) scale(${deviceRingScale})`,
      deviceTransform: `translate(${deviceTranslateX} ${deviceTranslateY}) scale(${deviceScale})`,
      device: {x: deviceRingTranslateX, y: deviceRingTranslateY},
      init: {
        x: headTranslateX + 0.5 * headScale * headSize.width,
        y: headTranslateY + 0.25 * headScale * headSize.height,
      },
      mouth: {
        x: headTranslateX + headScale * headSize.width * 0.93,
        y: headTranslateY + headScale * headSize.height * 0.69,
      },
      transcript: {
        x: (width - textWidth) / 2,
        y: screenCenter.y + lineHeight,
      },
      fontSize,
      lineHeight,
    };
  } else {
    // Horizontal orientation
    const third = width / 3;
    const thirdCenter = third / 2;
   
    // Head dimensions
    const headScale = Math.min(0.65 * third / headSize.width, height / headSize.height);
    const headTranslateX = thirdCenter - headScale * headSize.width / 2;
    const headTranslateY = screenCenter.y - headScale * headSize.height / 2;

    // Device dimensions
    const deviceRingScale = Math.min(0.9 * third / deviceRingSize.width, 0.95 * height / deviceRingSize.height);
    const deviceScale = Math.min(0.6 * third / deviceSize.width, 0.5 * height / deviceSize.height);
    const deviceTranslateX = screenCenter.x - deviceScale * deviceSize.width / 2;
    const deviceTranslateY = screenCenter.y - deviceScale * deviceSize.height / 2;

    // Text dimensions
    const textWidth = third * 0.9;
    const fontSize = textWidth / 15;
    const lineHeight = 2.5 * fontSize;
    const estLines = Math.round(textLength * fontSize / (1.5 * textWidth)); // Estimated number of lines of text
    const textHeight = Math.max(1, estLines - 1) * lineHeight;

    return {
      breaks: [0, third, 2 * third],
      transcriptionWidth: textWidth,
      headTransform: `translate(${headTranslateX} ${headTranslateY}) scale(${headScale})`,
      deviceRingTransform: `translate(${screenCenter.x} ${screenCenter.y}) scale(${deviceRingScale})`,
      deviceTransform: `translate(${deviceTranslateX} ${deviceTranslateY}) scale(${deviceScale})`,
      device: {...screenCenter},
      init: {
        x: headTranslateX + headScale * headSize.width * 0.5,
        y: headTranslateY + headScale * headSize.height * 0.25,
      },
      mouth: {
        x: headTranslateX + headScale * headSize.width * 0.93,
        y: headTranslateY + headScale * headSize.height * 0.69,
      },
      transcript: {
        x: third * 2 + (third - textWidth) / 2,
        y: screenCenter.y - textHeight / 2,
      },
      fontSize,
      lineHeight,
    };
  }
}

export function SpeakerAnimation({snippet, height, width}) {
  const showDebug = false;
  const silence = 0.75;
  const [paused, setPaused] = useState(false);
  const [muted, setMuted] = useState(true);
  const [pos, setPos] = useState([]);
  const [progress, setProgress] = useState(0);
  const [wave, setWave] = useState("");
  const [isCurrentWordError, setCurrentWordError] = useState(false);
  const chunks = useTranscription(snippet.transcription, silence);
  const [audioAnalyzer, audioSource] = useAudioContext(snippet.source, silence);

  const svgWidth = 0.9 * width;
  const svgHeight = 0.9 * height;
  const viewHeight = svgHeight * 10;
  const viewWidth = svgWidth * 10;

  // Compute responsive dimensions / spacing
  const textLength = (chunks || []).reduce((agg, chunk) => agg + chunk.output.length + 1, 0);
  const dim = computeDimensions(width / height, viewWidth, viewHeight, textLength);

  const thetaJitter = 60;
  const xJitter = 600;
  const yJitter = 50;
  const pathContainer = useRef(null);
  const transitionDuration = 0.25;

  // Autoplay
  useEffect(() => {
    if (audioSource) {
      audioSource.play();
    }
  }, [audioSource]);

  // Mute or unmute based on state
  useEffect(() => {
    if (!audioSource) {
      return;
    }

    if (muted) {
      audioSource.mute();
    } else {
      audioSource.unmute();
    }
  }, [muted, audioSource]);

  // Set initial position of text within the head
  useEffect(() => {
    if (!audioSource || !chunks) {
      return;
    }

    audioSource.onstatechange = () => {
      if (audioSource.isEnded()) {
        setProgress(1);
        setPaused(true);
      } else if (audioSource.isPaused()) {
        setPaused(true);
      } else {
        setPaused(false);
      }
    }

    const line = d3shape.line();
    // Compute start and end positions for all text elements.
    const textCursor = {x: 0, y: 0};

    // Construct a time scale with a padding at the beginning and end.
    const timeScale = (ts, raw) => {
      const max = audioSource ? audioSource.buffer.duration : 0;
      const mod = (ts / 1000);
      return raw ? mod : mod / max;
    };

    // Compute initial positions and scales for the speech snippet text.
    const startPos = chunks.map((chunk, i) => {
      // Compute text chunk width. This only works with a monospace font.
      const width = .5 * dim.fontSize * chunk.output.length;
      // Logic to create line breaks to wrap the text.
      if (textCursor.x + width > dim.transcriptionWidth) {
        textCursor.x = 0;
        textCursor.y += dim.lineHeight;
      }
      const xpos = textCursor.x + width / 2;
      textCursor.x += width ? width + dim.fontSize : 0;

      // Set the initial position
      const initTheta = jitter(thetaJitter);
      const datum = {
        ...chunk,
        correct: true,
        x0: dim.init.x + jitter(xJitter),
        y0: dim.init.y + jitter(yJitter),
        x: 0,
        y: 0,
        theta: initTheta,
        thetaScale: pct => (1 - pct) * initTheta,
        width,
        x1: dim.transcript.x + xpos,
        y1: dim.transcript.y + textCursor.y,
        curve0: "",
        curve1: "",
        absoluteScale: timeScale,
        scale: ts => {
          // Normalize the animation timestamp to the speech time scale
          const t = timeScale(ts, true);
          if (t < chunk.time - transitionDuration) {
            return 0;
          } else if (t < chunk.time) {
            return (t - (chunk.time - transitionDuration)) / transitionDuration / 2;
          } else if (t >= chunk.time && t <= chunk.time + chunk.duration) {
            return 0.5;
          } else if (t < chunk.time + chunk.duration + transitionDuration) {
            return 0.5 + ((t - chunk.time - chunk.duration) / transitionDuration) / 2;
          } else {
            return 1.0;
          }
        },
        key: `chunk-${i}`,
      };

      // Generate tween
      line.curve(d3shape.curveCatmullRom.alpha(0.5));
      datum.curve0 = line([
        [datum.x0, datum.y0],
        [dim.mouth.x, dim.mouth.y],
        [dim.device.x, dim.device.y],
      ]);
      line.curve(d3shape.curveCatmullRom.alpha(0.5));
      datum.curve1 = line([
        [dim.device.x, dim.device.y],
        [datum.x1, datum.y1],
      ]);

      // Set initial position
      datum.x = datum.x0;
      datum.y = datum.y0;

      return datum;
    });
    setPos(startPos);
    setProgress(0);
  }, [
    audioSource,
    chunks,
    dim.fontSize,
    dim.transcriptionWidth,
    dim.init.x, dim.init.y,
    dim.device.x, dim.device.y,
    dim.lineHeight,
    dim.mouth.x, dim.mouth.y,
    dim.transcript.x, dim.transcript.y,
  ]);

  const doPause = toggle => {
    if (!audioSource) {
      return;
    }

    if (toggle) {
      audioSource.pause();
      setPaused(true);
    } else {
      // Re-play if the clip is over
      if (audioSource.isEnded()) {
        audioSource.reset();
      }
      audioSource.play();
      setPaused(false);
    }
  };

  // Evolve the position of 
  useEffect(() => {
    if (!pos.length) {
      return;
    }

    if (!audioSource) {
      return;
    }

    // Run an animation tick
    const req = requestAnimationFrame(ts => {
      if (audioSource.isPaused()) {
        // Keep the paused state in sync with the audio context if it somehow
        // got paused for other reasons.
        if (!paused) {
          setPaused(true);
        }
        return;
      }

      // Ensure mute state is in sync with audio
      if (muted !== audioSource.isMuted()) {
        if (muted) {
          audioSource.mute();
        } else {
          audioSource.unmute();
        }
      }

      const adjustedTs = 1000 * audioSource.currentPlaybackTime();

      let isCurrentWordError = false;

      const newPos = pos.map(p => {
        // Bail if the DOM hasn't been rendered
        if (!pathContainer.current) {
          return p;
        }
        
        // Compute the new position along the path based on the time and the
        // path itself.
        const pct = p.scale(adjustedTs);
        const key = `#${p.key}-${pct < 0.5 ? 0 : 1}`;
        const path = pathContainer.current.querySelector(key);
        const l = path.getTotalLength();
        const realPct = pct < 0.5 ? pct / 0.5 : (pct - 0.5) / 0.5;
        const point = path.getPointAtLength(realPct * l);
        if (pct === 0.5) {
          isCurrentWordError = p.input !== p.output;
        }
        return {
          ...p,
          x: point.x,
          y: point.y,
          theta: pct < 0.5 ? p.thetaScale(pct / 0.5) : 0,
          content: pct <= 0.5 ? p.input : p.output,
          correct: pct <= 0.5 || p.input === p.output,
        };
      });

      setPos(newPos);
      setCurrentWordError(isCurrentWordError);

      // Update progress
      setProgress(pos[0].absoluteScale(adjustedTs));

      // Update waveform
      const usableRange = Math.round(audioAnalyzer.frequencyBinCount / 4);
      const wx = d3scale.scaleLinear()
        .domain([0, usableRange])
        .range([0, 2 * Math.PI]);
      const wy = d3scale.scaleRadial()
        .domain([0, 255])
        .range([3400, 4000]);
      const wdata = new Uint8Array(audioAnalyzer.frequencyBinCount);
      audioAnalyzer.getByteFrequencyData(wdata);
      const w = d3shape.lineRadial()
        .angle(d => wx(d[0]))
        .radius(d => wy(d[1]));
      const wpts = Array.from({length: usableRange}, (_, i) => ([i, wdata[i]]));
      const wavePath = w(wpts);
      setWave(wavePath);
    });

    return () => {
      cancelAnimationFrame(req);
    }; 
  });

  if (!audioSource || !chunks) {
    return (
      <Flex justifyContent="center" alignItems="center" style={{height}}>
        <ScaleLoader color={theme.white} />
      </Flex>
    );
  }

  return (
    <>
    <SpeakerAnimationContainer style={{height}}>
      <svg
        viewBox={`0 0 ${viewWidth} ${viewHeight}`}
        width={`${svgWidth}px`}
        height={`${svgHeight}px`}>
        <g>
          <path
            transform={dim.headTransform}
            fill="black"
            stroke="white"
            strokeWidth="80px"
            d={headSilhouettePath} />
        </g>
        <g transform={dim.deviceRingTransform}>
          {Array.from({length: 6}, (_, i) => (
            <path
              key={i}
              transform={`rotate(${(i * 360 / 6) + progress * 360 * .2 * ((-1) ** (i % 2))})`}
              fill="transparent"
              stroke={isCurrentWordError ? theme.red : theme.white}
              strokeWidth="20px"
              strokeDasharray="50 100"
              d={wave} />
          ))}
        </g>
        <g transform={dim.deviceTransform}>
          <path
            fill="black"
            stroke="white"
            strokeWidth="60px"
            d={deviceSilhouettePath} />
        </g>
        <g ref={pathContainer}>
          {pos.map(p => (
            <React.Fragment key={p.key}>
              <path
                id={`${p.key}-0`}
                d={p.curve0}
                stroke={showDebug ? "red" : "transparent"}
                fill="transparent"
                strokeWidth={showDebug ? 20 : 0}
                />
              <path
                id={`${p.key}-1`}
                d={p.curve1}
                stroke={showDebug ? "red" : "transparent"}
                fill="transparent"
                strokeWidth={showDebug ? 20 : 0}
                />
            </React.Fragment>
            ))}
        </g>
        <g>
          {pos.map((p, i) => (
            <React.Fragment key={i}>
              <text
                fill={p.correct ? theme.white : theme.red}
                fontSize={dim.fontSize}
                textDecoration={p.correct ? "none" : "line-through"}
                style={{fontFamily: "'Roboto mono', monospace"}}
                textAnchor="middle"
                transform={`translate(${p.x} ${p.y}) rotate(${p.theta})`}>
                {p.content}
              </text>
              {!p.correct && !!p.output && 
              <text
                fill={theme.red}
                fontSize={dim.fontSize}
                style={{fontFamily: "'Roboto mono', monospace"}}
                textAnchor="middle"
                transform={`translate(${p.x} ${p.y - dim.lineHeight / 2}) rotate(${p.theta})`}>
                {p.input}
              </text>}
            </React.Fragment>
          ))}
        </g>
        {showDebug && (
          <g>
            {dim.breaks.map(x => <line x1={x} x2={x} y1={0} y2={viewHeight} key={x} stroke="blue" strokeWidth={30} />)}
            <circle cx={dim.init.x} cy={dim.init.y} r="20" fill="red" />
            <circle cx={dim.mouth.x} cy={dim.mouth.y} r="20" fill="red" />
            <circle cx={dim.device.x} cy={dim.device.y} r="20" fill="red" />
            <circle cx={dim.transcript.x} cy={dim.transcript.y} r="20" fill="red" />
            {pos.map((p, i) => (
              <g key={i}>
                <circle cx={p.x0} cy={p.y0} r="20" fill="red" />
                <circle cx={p.x1} cy={p.y1} r="20" fill="red" />
              </g>
            ))}
          </g>
        )}
      </svg>
    </SpeakerAnimationContainer>
    <Controls>
      <ControlButtonsContainer>
        <IconButton onClick={() => doPause(!paused)}>
          {paused ?
            <FaPlay color={theme.white} size="20px" /> :
            <FaPause color={theme.white} size="20px" />}
        </IconButton>
        <IconButton onClick={() => setMuted(!muted)}>
          {muted ?
            <FaVolumeMute color={theme.red} size="20px" /> :
            <FaVolumeMute color={theme.white} size="20px" />}
        </IconButton>
      </ControlButtonsContainer>
      <ProgressBarContainer>
        <ProgressBar style={{width: `${100 * progress}%`}} />
      </ProgressBarContainer>
    </Controls>
    </>
  ); 
}

const Controls = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
`;

const ControlButtonsContainer = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
`;

const SpeakerAnimationContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  z-index: 0;
`;

const ProgressBarContainer = styled.div`
  z-index: 999;
  height: 5px;
  width: 100%;
  background-color: ${theme.white};
  position: absolute;
  bottom: 0;
  left: 0;
`;

const ProgressBar = styled.div`
  height: 5px;
  background-color: ${theme.blue};
`;

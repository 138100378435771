import React, {useState} from 'react'
import {
  VictoryChart,
  VictoryScatter,
  VictoryAxis,
  VictoryLine,
  VictoryVoronoi,
} from 'victory'
import { ddm } from '../data/ddm.js'
import styled from '@emotion/styled'
import { Box } from '@rebass/grid/emotion'
import { Page, PageBody, PageText, PageTitle } from '../components/Page';
import * as theme from '../components/theme';
import {formatNumberWithArticle, formatSource, formatWer, formatDdm} from '../format';
import {AudioPlayer} from '../components/AudioPlayer';
import {Diff} from '../components/Diff';
import {leastSquaresFn} from '../regression';
import {ChartTitle, BetterWorseLabels} from '../components/ChartLabels';
import {getAttribution} from '../components/Attribution';

function getDomains(scatterData) {
  let maximumX = -Infinity;
  let maximumY = -Infinity;

  scatterData.forEach(({ x, y }) => {
    if (maximumX < x) {
      maximumX = x
    }

    if (maximumY < y) {
      maximumY = y
    }
  });

  return {
    x: [0, Math.min(1, maximumX * 1.2)],
    y: [0, Math.min(1, maximumY * 1.2)],
  };
}

const scatterData = ddm.map((d, i) => ({
  x: d.ddm,
  y: d.avg_wer,
  source: d.source,
  original: d,
}));

// Mark the data we'll make interactive (though we'll plot everything)
const usableScatterData = scatterData.filter(d => d.original.use);

// Compute domain
const domains = getDomains(scatterData);

// Compute regression line
const trend = leastSquaresFn(scatterData, {x: (d => d.x), y: (d => d.y)});
const trendData = [{
  x: domains.x[0],
  y: trend(domains.x[0]),
}, {
  x: domains.x[1],
  y: trend(domains.x[1]),
}];

export default function DDM(props) {
  const [selected, setSelected] = useState(usableScatterData[0].original);
  const [focusedAnnotation, setFocusedAnnotation] = useState(null);
  const [audioPos, setAudioPos] = useState({});

  return (
    <Page {...props}>
      <PageTitle>
      More errors for African American Vernacular English
      </PageTitle>

      <PageBody>
        <Box width={[1, 2/3, 1/2]}>
          <DDMChartContainer>
            <Box>
              <VictoryChart
                responsive={true}
                width={500}
                height={300}
                theme={theme.chart}
                domain={domains}
              >
                <ChartTitle text="Error rates by dialect strength" />
                <VictoryAxis
                  dependentAxis
                  label={true}
                  axisLabelComponent={
                    <BetterWorseLabels xOffset={5} topAnchor={1} bottomAnchor={0} />
                  }
                  tickValues={[0, .2, .4, .6, .8, 1.0]}
                  tickFormat={formatWer}
                  />
                <VictoryAxis
                  label="Dialect strength"
                  tickValues={[0, .1, .2, .3, .4, .5]}
                  tickFormat={formatDdm}
                  />

                <VictoryLine
                  data={trendData}
                  style={{
                    data: {
                      stroke: theme.orange,
                      strokeWidth: 2,
                      strokeDasharray: 5,
                    },
                  }}
                  />

                <VictoryScatter
                  sortKey={d => !!d.original.use}
                  style={{
                    data: {
                      cursor: d => d.datum.original.use ? "pointer" : "default",
                      fill: d => d.datum.original.use ? theme.blue : theme.lighterGray,
                      opacity: 0.75,
                      stroke: d => !!selected && selected === d.datum.original ?
                        theme.red : "transparent",
                      strokeWidth: 3,
                    },
                  }}
                  size={d => d.datum.original.use ? 6 : 3}
                  data={scatterData}
                  />
                  
                <VictoryVoronoi
                  data={usableScatterData}
                  style={{data: {cursor: "pointer"}}}
                  events={[{
                    childName: "all",
                    target: "data",
                    eventHandlers: {
                      onClick: (_, d) => {
                        setSelected(d.datum.original);
                        setAudioPos({});
                        setFocusedAnnotation(null);
                      },
                    },
                  }]}
                  />
              </VictoryChart>
            </Box>

            <Box flexBasis="40vh" style={{scrollSnapAlign: "end"}}>
              {selected ?
                <DDMPointInfo
                  audioPos={audioPos}
                  setAudioPos={setAudioPos}
                  focusedAnnotation={focusedAnnotation}
                  setFocusedAnnotation={setFocusedAnnotation}
                  point={selected} /> :
                <ChartPlaceholderText>
                  Select a point on the chart to view detailed information.
                </ChartPlaceholderText>}
            </Box>
          </DDMChartContainer>
        </Box>

        <Box width={[1, 1/3, 1/2]}>
          <PageText>
  The speech recognition technology we tested performed far worse for speakers who used more linguistic features characteristic of <a href="https://oraal.uoregon.edu/facts" target="_blank" rel="noopener noreferrer">African American Vernacular English (AAVE)</a>, exposing a significant shortcoming of these systems.
          </PageText>
          <PageText>
  Click on the highlighted dots to play an audio sample and view its corresponding linguistic features.
  For example, AAVE speakers might say “they gone” instead of “they are gone,” a grammatical pattern called <a href="https://ygdp.yale.edu/phenomena/null-copula" target="_blank" rel="noopener noreferrer">copula absence</a>.
  Similarly, AAVE speakers might use the invariant habitual “be” to signal that an event occurs habitually or regularly, as in “I be sitting.”
          </PageText>
          <PageText>
  The machine learning technology underlying speech recognition systems likely relies too heavily on audio samples of white Americans. To close the performance gaps we see, it’s important to create datasets that reflect the full diversity of accents and dialects of all Americans. We hope technology companies, academic researchers, and government agencies invest the necessary resources to ensure speech recognition technology is broadly inclusive.
          </PageText>
        </Box>
      </PageBody>

    </Page>
  )
}

function DDMPointInfo({point, setAudioPos, audioPos, focusedAnnotation, setFocusedAnnotation}) {
  const d = point;
  const playAnnotation = atn => {
    if (atn) {
      setAudioPos({start: atn.timeCtxStart, end: atn.timeCtxEnd, forcePlay: true});
    }
    setFocusedAnnotation(atn);
  };

  return (
    <div>
      <DDMPointTitle>
        {formatNumberWithArticle(d.age)}-year-old Black {d.gender.toLowerCase() === "male" ? "man" : "woman"} from {formatSource(d.source)}
      </DDMPointTitle>
      <SnippetBox>
        <AudioPlayer
          clip={d.snippet}
          annotations={d.annotations}
          onClick={s => {
            setAudioPos({start: s, end: null});
            setFocusedAnnotation(null);
          }}
          onFocusAnnotation={playAnnotation}
          onHoverAnnotation={setFocusedAnnotation}
          focused={focusedAnnotation}
          snippet={audioPos}
          attribution={getAttribution(d.attribution)} />
        <div style={{
          minHeight: "2rem",
          fontWeight: "bold",
          color: theme.highlightAccent,
        }}>
          {focusedAnnotation && focusedAnnotation.content}
        </div>
        <SnippetTranscription>
          <Diff
            onClickAnnotation={playAnnotation}
            onHoverAnnotation={setFocusedAnnotation}
            focused={focusedAnnotation}
            b={d.real_transcription}
            a={d.asr_transcription}
            annotations={d.annotations}
            />
        </SnippetTranscription>
      </SnippetBox>
    </div>
  );
}

const SnippetBox = styled.div`
  width: 90%;
  margin-top: 2rem;
`;

const SnippetTranscription = styled.div`
  font-family: ${theme.dataFont};
  line-height: 2.5;
  text-align: left;
  margin: 1rem 0;
`;

const DDMPointTitle = styled.h3`
  font-size: 1.2rem;
  color: ${theme.blue};
  text-align: left;
`;

const DDMChartContainer = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
`

const ChartPlaceholderText = styled.div`
  font-style: italic;
  color: ${theme.gray};
  margin: 3rem 0;
`;

export const wer = {
  byRace: [
    {
      race: 'black',
      wer: 0.35,
    },
    {
      race: 'white',
      wer: 0.19,
    },
  ],
  byRaceAndSex: [
    {
      race: 'black',
      sex: 'female',
      wer: 0.3,
      age: 23,
      audio: {
        snippet: 'bw-snowcone-norm.mp3',
        attribution: 'coraal',
        real_transcription:
          "And she had these little like snow cone things. I don’t even know what it was, but it’s not like the snow cones up here. Like, I don’t know how to explain it, but you know - bag of candy for a quarter. Like, a full bag of candy for a quarter.",
        asr_transcription:
          "If she had a photo like no things. Don’t even know what it was, does not like the smell comes up here. Like, I don’t know how to explain it, but you know - a bag candy for quarter. Like, a full bag candy.",
      },
    },
    {
      race: 'black',
      sex: 'male',
      wer: 0.41,
      age: 40,
      audio: {
        snippet: 'bm-basketball-norm.mp3',
        attribution: 'coraal',
        real_transcription:
          "I mean, I knew I was kinda tall for high school. I didn’t wanna play center. I didn’t because center don’t have the ball that much. You get the ball occasionally when you in the post, I mean, but I didn’t want to play it.",
        asr_transcription:
          "Me I know I’m kinda tall for asking. I didn’t because send it on have the ball that much. You get the ball occasionally when you in the post, I mean, but I didn’t want to play it.",
      },
    },
    {
      race: 'white',
      sex: 'female',
      wer: 0.17,
      age: 26,
      audio: {
        snippet: 'ww-licorice-norm.mp3',
        real_transcription: "So I saw that the FDA issued a warning about licorice which made me - which reminded me that I actually ate a lot of licorice as a kid. Cuz I wasn’t really allowed to eat sweeter candies, but licorice was fine. But apparently it’s really bad for you.",
        asr_transcription:
          "So I saw that the FDA issued a warning about licorice which made me - which reminded me that I actually had a lot of liquors have the kids. Cuz I wasn’t really allowed to eat sweetheart candies, but look where she was fine. But apparently it’s really bad for you.",
      },
    },
    {
      race: 'white',
      sex: 'male',
      wer: 0.21,
      age: 30,
      audio: {
        snippet: 'wm-basketball-norm.mp3',
        real_transcription: 'Well, when I was when I was really young I had a book of basketball statistics and I would spend a lot of time a lot of time reading them. And for some reason, I forget why now, but Jason Kidd ended up being my favorite player.',
        asr_transcription: 'Well, when I was that’s really young I had a book of basketball statistics. No spend a lot of time a lot of time reading them. And for some reason, I forget why now, but Jason Kidd pain. Be my favorite player.',
      },
    },
  ],
  byRaceAndProduct: [
    {
      product: 'amazon',
      race: 'white',
      wer: 0.16,
    },
    {
      product: 'amazon',
      race: 'black',
      wer: 0.31,
    },
    {
      product: 'apple',
      race: 'white',
      wer: 0.23,
    },
    {
      product: 'apple',
      race: 'black',
      wer: 0.45,
    },
    {
      product: 'google',
      race: 'white',
      wer: 0.19,
    },
    {
      product: 'google',
      race: 'black',
      wer: 0.31,
    },
    {
      product: 'ibm',
      race: 'white',
      wer: 0.2,
    },
    {
      product: 'ibm',
      race: 'black',
      wer: 0.38,
    },
    {
      product: 'msft',
      race: 'white',
      wer: 0.15,
    },
    {
      product: 'msft',
      race: 'black',
      wer: 0.27,
    },
  ],
}

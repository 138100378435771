import React from "react";
import {Link} from "react-scroll";

export function Navbar({pages, active, setPage, containerId}) {
  return (
    <div className="Nav" id="nav">
      {pages.map(pageId => (
        <div key={pageId}>
          <Link
            containerId={containerId}
            isDynamic={true}
            to={pageId}
            offset={0}
            duration={250}
            spy={true}
            smooth={true}
            onSetActive={setPage}
            activeClass="nav-active">
            <svg
              viewBox="0 0 100 100"
              style={{padding: "0.5rem 0"}}
              width="1rem"
              height="1rem">
              <circle r={45} cx={50} cy={50} /> 
            </svg>
          </Link>        
        </div>
      ))}
    </div>
  );
}

/**
 * Compute least squares regression from set of points.
 *
 * Optionally supply x / y accessors. By default, assumes points are [x, y].
 *
 * @argument data - List of data points
 * @argument opts - Optional parameters
 * @argument opts.x - x value accessor
 * @argument opts.y - y value accessor
 * @returns [m, b] - Line parameters
 */
export function leastSquares(data, opts) {
  opts = opts || {};
  const x = opts.x || (d => d[0]);
  const y = opts.y || (d => d[1]);

  const n = data.length;

  if (n < 2) {
    throw new Error("Can't compute regression with less than two points.");
  }

  const sum = {
    xy: 0,
    x2: 0,
    x: 0,
    y: 0,
  };

  for (let d of data) {
    const _x = x(d);
    const _y = y(d);
    const x2 = _x * _x;
    const xy = _x * _y;

    sum.xy += xy;
    sum.x2 += x2;
    sum.x += _x;
    sum.y += _y;
  }

  const m = (n * sum.xy - sum.x * sum.y) / (n * sum.x2 - sum.x * sum.x);
  const b = (sum.y - m * sum.x) / n;

  return [m, b];
}

/**
 * Get a function representing the least squares regression line.
 *
 * See the `leastSquares` function for documentation on arguments.
 *
 * @returns Function - f(x) for the trend line.
 */
export function leastSquaresFn() {
  const [m, b] = leastSquares(...arguments);
  return x => m * x + b;
}

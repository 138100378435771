export const ddm = [
  {
    "snippet": "PRV_se0_ag3_f_02_1_1750993_1757262.mp3",
    "wordcount": 17,
    "phon_count": 1,
    "phon_features": [
      "'th' fronting: they"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "They're grown now, they might not remember you completely but they remember some things that they did.",
    "age": 40,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "They're grown now they might not remember you completely but they remember some things that they did.",
    "ibm_transcription": "they are grown man they might not remember you completely but they remember some things that they demand",
    "amazon_transcription": "they're grown. They're They might not remember you completely, but they remember some things that they did.",
    "google_transcription": "they're grown now they might not remember you completely but they remember some things that they did",
    "apple_transcription": "They are grown man they might not remember you completely but I remember some things that idea",
    "clean_google_wer": 0,
    "clean_ibm_wer": 0.2353,
    "clean_amazon_wer": 0.0588,
    "clean_msft_wer": 0,
    "clean_apple_wer": 0.3529,
    "ddm": 0.0588,
    "avg_wer": 0.1294
  },
  {
    "snippet": "ROC_se0_ag1_m_02_1_164419_197782.mp3",
    "wordcount": 119,
    "phon_count": 12,
    "phon_features": [
      "l vocalization after vowel: well, older, football, football, cool",
      "CCR: friend, and, bust, freehand",
      "-ng as -n: playing",
      "haplology: probably",
      "fill-feel merger: field"
    ],
    "gram_count": 1,
    "gram_features": [],
    "total_count": 13,
    "content": "Well one friend that I still have, his name /RD-NAME-2/. Me /and him/ been friends ever since babies, cause our moms were friends. Um, he was like the chubby fat k- fat dude. Then he started playing football and slimmed up. It was a dude named /RD-NAME-2/. He was a older dude. Like probably like four years older than us. And we all like looked up to him cause he was like the- like the East High School football player. And like, he used to train us out in the field, and we used to like, play like- like t- like shoot 'em up, bust 'em up, like basically like football- freehand football. Yeah. I had some pretty cool friends on /RD-ADDRESS-1/ Court.",
    "age": 27,
    "source": "ROC",
    "gender": "Male",
    "msft_transcription": "Well, one thing that I still have. Remember friends versus babies are miles with friends. Um. He was like this should be *** *** do. These are playing football slimmed up there? Was he was an older. Dude, I'd probably 4 years older than us and we all looked up to him 'cause. He was like that, like the E high school football player. He said trainer play like **** in my bus amount like basically football freehand football. Yeah, that's pretty cool for them.",
    "ibm_transcription": "where were for the store %HESITATION member for there was a baby because our miles of friends %HESITATION he was like the Chevy fake fed to these are playful poor slammed up there's a new name he was older do I'd probably four years older than us we all I look up to him because he was like that like the ease high school football player hello he said China's own the fail it was like play like like twenty Sonoma bus moblie basically football free have football yeah that's pretty cool for the",
    "amazon_transcription": "we're one for thus tohave remember friends ever since babies are my friends, Um, he was like the chubby fact fed. Do you start playing football or slimmed up? It was doing. He was older. Do probably four years older than us, and we all looked up to him because he was like the East High School football player. And like he said, trainers on the fell we should, like, play like like shooting my bust him up like basically football freehand football, they're for the birds.",
    "google_transcription": "werewolf and I still have remember friends ever since baby cuz I'm out with friends he was like a chubby fat fat dude these are playing football slammed up them to do me to the older dude I probably four years older than us and we are looking for him cuz he was like that like to East High School football player and like you said trainers I want to feel it was like play like like to play shoot-'em-up bus route like this like football freehand football yeah that's pretty cool",
    "clean_google_wer": 0.5294,
    "clean_ibm_wer": 0.6471,
    "clean_amazon_wer": 0.5126,
    "clean_msft_wer": 0.5378,
    "clean_apple_wer": 1,
    "ddm": 0.1092,
    "avg_wer": 0.6454
  },
  {
    "snippet": "DCB_se1_ag2_f_03_1_1344348_1351219.mp3",
    "wordcount": 23,
    "phon_count": 2,
    "phon_features": [
      "monopthongization: I, I"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 2,
    "content": "Yes but I don't share. I did share at the, um, <ts> Torch Club when they had the poetry night here a few months ago.",
    "age": 25,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "But I don't share at this share at the torch club when they had the poetry night. Here, a few months ago.",
    "ibm_transcription": "but I don't share ideas share at the %HESITATION torch club when they had the fortune I hear a few months ago",
    "amazon_transcription": "Yes, but I don't share. I did share at the such club when I had the poetry night here few months ago.",
    "google_transcription": "yes but I don't share I did share at the torch Club when they had the Poetry night here a few months ago",
    "apple_transcription": "Yes but I don't share I did share at the torch club when I had the porch and I hear a few months ago",
    "clean_google_wer": 0,
    "clean_ibm_wer": 0.2609,
    "clean_amazon_wer": 0.1304,
    "clean_msft_wer": 0.1304,
    "clean_apple_wer": 0.2174,
    "ddm": 0.087,
    "avg_wer": 0.1478
  },
  {
    "snippet": "DCB_se2_ag3_m_01_1_1403432_1432834.mp3",
    "wordcount": 112,
    "phon_count": 13,
    "phon_features": [
      "CCR: pos(t)ed, around, and,    kin(d)a, and, grappled",
      "monopthongization: I, kinda (overlap)",
      "raising of 'kit' vowel: this",
      "-ng to -n: grappling",
      "l vocalization after vowel: cool",
      "th fronting: that's, that",
      "final consonant deletion: head"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 13,
    "content": "And she took a picture that she still had it, and posted it on Facebook, and she's like, I still have this. Remember you know, when we- when you did these. That you know- that m- made a difference. Blah blah blah blah blah. So I think it was around that time I was really grappling with, Yeah, art thing is cool but that's not what I wanna do. I want to help people. Um, and that's, you know, that's kind of where my head was even when I graduated. And I think cause I grappled with that so much I didn't know what I wanted to do was- when I got to",
    "age": 35,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "She took a picture that she still had it. And posted on Facebook. It's like I still have this remember you did, these that made a difference blah blah blah. So I think it was around that time, I was really grappling with yeah, our thing is cool. But that's not what I want to do, I want to help people? And that's you know that's kind of where my hair was even when I graduated and I think 'cause. I grapple with that, so much. I don't know what I wanted to do when I got to.",
    "ibm_transcription": "she took a picture that she still had and posted on Facebook inside I still have this remember no we knew did these that you know that made a difference for awhile so I think it was around the time I was really grappling with yes our thing the school board that's not what I want to do I want to help people our and that's you know that's kind of where my hair was even when I graduated I think because I grapple with that so much I don't know what I wanted to do was when I got to",
    "amazon_transcription": "and she took a picture that she still had it and posted it on Facebook. It's like I still have this, remember? You know, you did. He's that, You know, that made a difference. But so I think it was around that time I was really grappling with. Yeah, our thing is cool, but that's not what I want to do. I want to help people and that's, you know, that's kind of where my head was even when I graduated. I think cause I grapple with that so much, I don't know what I wanted to do when I got tio.",
    "google_transcription": "she took a picture that she still have it and post it on Facebook is like I still have this remember I think it was around that time I was really grappling with yeah our thing is cool but that's not what I want to do I want to help people and that's you know that's kind of where my head was you when I graduated nothing cuz I grapple with that so much I didn't know what I wanted to do was when I got to",
    "apple_transcription": "Took a picture that she still had it and post it on Facebook it's like I still have this remember no reason you did please let me know that made a difference balaclava so I think it was around that time is really grappling with yeah our thing is cool but that's not what I want to do I want to help people and that's kind of where my head was he when I graduated because I grapple with that so much I don't know what I wanted to do was when I got to",
    "clean_google_wer": 0.3125,
    "clean_ibm_wer": 0.2768,
    "clean_amazon_wer": 0.1786,
    "clean_msft_wer": 0.1964,
    "clean_apple_wer": 0.3036,
    "ddm": 0.1161,
    "avg_wer": 0.2536
  },
  {
    "snippet": "DCB_se1_ag4_m_02_1_1546069_1555346.mp3",
    "use": true,
    "attribution": "coraal",
    "wordcount": 32,
    "phon_count": 8,
    "phon_features": [
      "final consonant deletion after vowel: gave",
      "monopthongization: I",
      "-ng to -n: sitting, sitting",
      "CCR: and, second",
      "r-less after vowel: teacher",
      "l vocalization after vowel: well"
    ],
    "gram_count": 1,
    "gram_features": [
      "invariant habitual be: I be sitting"
    ],
    "total_count": 9,
    "annotations": [
      {
        "timeStart": 0.32983296805299694,
        "timeEnd": 0.7077144550542308,
        "timeCtxStart": 0.03047853828780912,
        "timeCtxEnd": 0.9664246202223203,
        "start": 3,
        "end": 9,
        "content": "Final Consonant Cluster Reduction"
      },
      {
        "timeStart": 0.9954738935835582,
        "timeEnd": 1.2882620989164801,
        "timeCtxStart": 0.9739725724959857,
        "timeCtxEnd": 2.5590425499657226,
        "start": 17,
        "end": 24,
        "content": "Vocalization of unstressed syllabic /r/"
      },
      {
        "timeStart": 1.3315438336178684,
        "timeEnd": 1.5325210407472263,
        "timeCtxStart": 0.9739725724959857,
        "timeCtxEnd": 2.5590425499657226,
        "start": 25,
        "end": 29,
        "content": "Final Consonant Deletion"
      },
      {
        "timeStart": 3.0900107861489663,
        "timeEnd": 3.6061095348908028,
        "timeCtxStart": 3.077354607698216,
        "timeCtxEnd": 4.545708882863692,
        "start": 54,
        "end": 58,
        "content": "Vocalization of postvocalic /l/"
      },
      {
        "timeStart": 3.665985963928291,
        "timeEnd": 4.119204472950508,
        "timeCtxStart": 3.077354607698216,
        "timeCtxEnd": 4.545708882863692,
        "start": 68,
        "end": 69,
        "content": "Glide reduction of /ai/"
      },
      {
        "timeStart": 6.030914532810742,
        "timeEnd": 6.277358980855025,
        "timeCtxStart": 6.039129347745551,
        "timeCtxEnd": 7.271351587966967,
        "start": 92,
        "end": 93,
        "content": "Glide reduction of /ai/: \"I\" -> '\"ah\""
      },
      {
        "timeStart": 6.277358980855025,
        "timeEnd": 6.414614530824841,
        "timeCtxStart": 6.039129347745551,
        "timeCtxEnd": 7.271351587966967,
        "start": 94,
        "end": 96,
        "content": "Invariant Habitual \"be\""
      },
      {
        "timeStart": 6.414614530824841,
        "timeEnd": 6.7072427028529855,
        "timeCtxStart": 6.039129347745551,
        "timeCtxEnd": 7.271351587966967,
        "start": 97,
        "end": 104,
        "content": "Realization of final ng as n"
      },
      {
        "timeStart": 8.281773824948527,
        "timeEnd": 8.585721977536476,
        "timeCtxStart": 8.010684932099815,
        "timeCtxEnd": 9.277006802721088,
        "start": 142,
        "end": 149,
        "content": "Realization of final ng as n"
      }
    ],
    "content": "In, uh, second grade, teacher gave me nickname Snake cause well she said I was sneaky, you know. I'd be sitting in one place, and she turn around I'm sitting some place else.",
    "real_transcription": "In second grade, teacher gave me nickname Snake cause well she said I was sneaky. You know. I be sitting in one place and she turn around I'm sitting someplace else.",
    "asr_transcription": "With seven braids. He'd give me a nickname Snake cause she said I was sneaking. You know. Me sit in one place and she China, man, I'm staying someplace.",
    "age": 67,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "On the 7th grade sheet gave mean ignited Snake 'cause well, she said, I was sneaky. Miss it in one place and she China Man, I'm sitting someplace out.",
    "ibm_transcription": "seventh grade to to give me a nickname snake because of this is that I was sneaking into I miss in one place and she turned around once in somebody's out",
    "amazon_transcription": "with seven braids. He'd give me a nickname snake because she said I was sneaking. You know, me sit in one place and she China, man, I'm staying someplace.",
    "google_transcription": "second grade teacher gave me the nickname snake cuz she said I was sneaking I miss it in one place and she trying to man I'm sitting someplace",
    "apple_transcription": "Send Bree to do give me a nickname snake because it was he said I was sneaky I missed in one place and she Chyna well I'm sitting someplace that",
    "clean_google_wer": 0.4688,
    "clean_ibm_wer": 0.7188,
    "clean_amazon_wer": 0.5625,
    "clean_msft_wer": 0.5,
    "clean_apple_wer": 0.625,
    "ddm": 0.2812,
    "avg_wer": 0.575
  },
  {
    "snippet": "PRV_se0_ag1_f_01_1_239146_247906.mp3",
    "wordcount": 40,
    "phon_count": 3,
    "phon_features": [
      "l vocalization after vowel: yall",
      "-ang for 'ing: everything",
      "r-less after vowel: for"
    ],
    "gram_count": 3,
    "gram_features": [
      "absence third person singular: when it snow",
      "verb stem as past tense: I say",
      "multiple negation: we're not used to no snow"
    ],
    "total_count": 6,
    "content": "what is the problem? She was like, when it snow y'all shut everything down. She was like, it is not that serious. I said, yes it is for us. We're not used to no snow. We shut every- She said,",
    "age": 19,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "When the property was like when is the Yellow Shirt everything now shoes that is not that serious I say yes it is far is win? How you smell smell? We should every she said.",
    "ibm_transcription": "the problem seems like what is it everything actually but is not this year that's a yes it is how you smell smoke we should have received a",
    "amazon_transcription": "What's the problem? She was like, When it's so Yashin, Everything I should've but is not that serious. I say, Yes, it is. You know, we should every she's there.",
    "google_transcription": "what the problem she was like everything I should like it's not that serious I say yes it is",
    "apple_transcription": "What's the problem seems like everything I wish that every shift",
    "clean_google_wer": 0.625,
    "clean_ibm_wer": 0.75,
    "clean_amazon_wer": 0.575,
    "clean_msft_wer": 0.575,
    "clean_apple_wer": 0.85,
    "ddm": 0.15,
    "avg_wer": 0.675
  },
  {
    "snippet": "ROC_se0_ag2_f_02_1_1720121_1726612.mp3",
    "use": false,
    "wordcount": 34,
    "phon_count": 1,
    "phon_features": [
      "-ng as -n: looking"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "Oh yeah, that's what I told her, I said- like we were looking at the rental house. She's like, oh my god. I said, don't worry. I'm buying a house. You won't have to (laughing)",
    "age": 32,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "Oh yeah that's what I told dress and when we were looking at the rental houses at home. My God, I said, Don't worry. I'm buying a house you won't.",
    "ibm_transcription": "%HESITATION yeah that's what I told us and when we were looking at the run the house she's at home I got a don't worry I'm buying a house you will have to",
    "amazon_transcription": "Oh, yeah, That's what I told her. I said when we were looking at the Renault How she's Oh, my God, it's Don't worry, I'm buying a house. You won't have to.",
    "google_transcription": "oh yeah that's what I told her I said when we were looking at the rental house she's at home I got her don't worry I'm by the house you won't have to",
    "apple_transcription": "Oh yeah that's what I told her when we were looking at the rental house she's at home I got it I don't worry",
    "clean_google_wer": 0.2647,
    "clean_ibm_wer": 0.4118,
    "clean_amazon_wer": 0.1765,
    "clean_msft_wer": 0.2941,
    "clean_apple_wer": 0.5,
    "ddm": 0.0294,
    "avg_wer": 0.3294
  },
  {
    "snippet": "ROC_se0_ag2_f_04_1_193297_198300.mp3",
    "wordcount": 18,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "Well, you know, the class of- of- of- of nineteen ninety was probably the best last graduat- no (laughing)",
    "age": 43,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "Well, you know the class of 1990 was probably the best last graduate.",
    "ibm_transcription": "well you know the class of of of a nineteen ninety was probably the best last graduating",
    "amazon_transcription": "Well, you know, the class of 1990 was probably the best last graduate.",
    "google_transcription": "well you know the class of 1990 was probably the best last graduate",
    "apple_transcription": "Well you know the class of of of of 1990 was probably the best last graduate",
    "clean_google_wer": 0.2778,
    "clean_ibm_wer": 0.1667,
    "clean_amazon_wer": 0.2778,
    "clean_msft_wer": 0.2778,
    "clean_apple_wer": 0.1111,
    "ddm": 0,
    "avg_wer": 0.2222
  },
  {
    "snippet": "DCB_se1_ag3_f_01_1_682434_731413.mp3",
    "wordcount": 161,
    "phon_count": 15,
    "phon_features": [
      "final consonant deletion after vowel: what, could, road, side",
      "r-less after vowel: market, for",
      "th stopping: there, that, there, there",
      "-ng as -n: benning, benning",
      "CCR: left, hand, owned"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 15,
    "content": "That used to be what we used to call The Market. We used to can go down there, and it was different little- e- sh- stands inside of there. We could shop for hair goods, t shirts. You- they had a place in there that had the best f- ch- steak and cheese. I mean these are all the things that we saw- You know, we've seen change over the years. The um, corner store that sits right there next to Kipp used to be a People's Drug Store. And if you go further up Benning Road, on the left hand side, right there- I think that's Benning Road and H, that used to be the only black owned record store on this side of town, and it was called Anne's Record Shop. And then where the hair braiding place is now, that used to be a cleaners. And then, the twenty four hour store used to be an actual Seven Eleven.",
    "age": 42,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "That used to be what we used to call the market. We used to can go down there and it was different little stands insider weekly shop forehead goods T shirts. They had a place never had the best steak and cheese. I mean, these are all the things that we saw you know, we've seen change over the years, the corner store. This is right, there next to Kip used to be a People's drug store. And if you go further up in a row. On the left hand side right there. I think that's been enrolled at 8:00 that used to be the only black on. Record store on this side of town and it was called Anne's record shop and then with a hair braiding places now used to be a cleaners. And then the 24 hour store used to be an actual 711.",
    "ibm_transcription": "that used to be what we used to call the market we used to can go down there and it was different little should stands inside we can shop for Hey good T. shirt they had a place to move ahead V. best which they can she I mean these are all the things that we saw you know we've seen change over the years the %HESITATION corner store this is right there next to Kip used to be a people struck still and if you go further up in a row on the left hand side right they think there's been a road eight that used to be the only black owned record store on this side of town and it was call Anya's record shop and then with a hair braiding places man I used to be a cleaners and then the twenty four hour store used to be an actual seven a lot",
    "amazon_transcription": "there used to be what we used to call the market. We used to Congar down there and it was different. Little stands inside of a week in shop for hair goods, T shirts. They had a place and it had the best steak and cheese. I mean, these are all the things that we saw. You know, we've seen change over the years. The corner store. This is right there next to Kit used to be a people's drugstore. And if you go further, I've been a road on the left hand style right there. Think that's been Road eight. That used to be the only black own record store on this side of town, and it was called Aliens Record Shop and then with hair Brain places now, and I used to be a cleaners, and then the 24 hour store used to be an actual seven eleven 11",
    "google_transcription": "that used to be what we used to call the Market we used to can go down there and it was different little stands inside that we can shop for hair goods t-shirt they had a place neighborhood V best steak and cheese I mean these are all the things that we saw you know we've seen change over the years the corner store that says right there next to tip used to be a people's drug store but if you go further I've been in road on the left hand side right there think that's been enrolled at 8 that used to be the only black on record store on this side of town and never call and record shop and then with hair braiding place is now that used to be a cleaners and then the 24-hour store used to be an actual 7-Eleven",
    "apple_transcription": "That used to be what we used to call the market we used to can go down there and it was different little H stands for Saturday we can shop for Higgins T-shirt they had a place in ahead be best steak and cheese I mean is that all the things that we saw your note should we swing change over to use the arm corner store this is right there next to Kip used to be a peoples drugstore and if you go for that I've been a road on the left there's been a road at eight that used to be the only black on the record store on this side of town and it was call and use record shop and then with a harebrained place is Nana used to be a cleaners and then the 24 hour store used to be an actual 7-Eleven",
    "clean_google_wer": 0.1988,
    "clean_ibm_wer": 0.2795,
    "clean_amazon_wer": 0.2298,
    "clean_msft_wer": 0.205,
    "clean_apple_wer": 0.354,
    "ddm": 0.0932,
    "avg_wer": 0.2534
  },
  {
    "snippet": "ROC_se0_ag3_f_02_1_1699087_1707565.mp3",
    "wordcount": 25,
    "phon_count": 4,
    "phon_features": [
      "final consonant deletion after vowel: that, would",
      "th fronting: that",
      "l vocalization after vowel: school"
    ],
    "gram_count": 1,
    "gram_features": [
      "'them' as ___: take them shoes"
    ],
    "total_count": 5,
    "content": "If- if it was that high. And I would take that- take them shoes to school with me, and change 'em on my way there.",
    "age": 80,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "It if it was that high an I would take that take them shoes to school with me and change him on my way there.",
    "ibm_transcription": "if it was that high and now IT take that take them shoes to school with me change I'm on my way there",
    "amazon_transcription": "if it was that high and I would take that, take them shoes to school with me and change them on my way there.",
    "google_transcription": "if it was that high and I would take that take them shoes to school with me and change them on my way there",
    "apple_transcription": "If it was that I had and I would take that take those shoes to school with me and change them on my way there",
    "clean_google_wer": 0.08,
    "clean_ibm_wer": 0.2,
    "clean_amazon_wer": 0.08,
    "clean_msft_wer": 0.12,
    "clean_apple_wer": 0.2,
    "ddm": 0.2,
    "avg_wer": 0.136
  },
  {
    "snippet": "ROC_se0_ag2_f_02_1_2901032_2936926.mp3",
    "wordcount": 104,
    "phon_count": 1,
    "phon_features": [
      "CCR: passed"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "She was born in a little town in /RD-ADDRESS-2/, Georgia, and everybody there- if we- if we go- because, uh, we have family members that go every year we're related to every damn body in this little baby town, like it's a very small town. And they came up there f- with, um, my mother's oldest sister, she passed. Her husband came up here to sell drugs so he moved the whole- my grandmother, like my aunt said, I'm not going without my family, so he moved my grandmother and all her kids up here in order to get his wife to come up th- here with him.",
    "age": 32,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "She was born in a little town in Georgia. In everybody there if we go because we have family members that go every year were related to every damn body in this little baby town like it's a very small town and they came up there. With my mother's older sister, she passed her husband came up here to sell drugs. So he moved the whole my grandmother like my aunt said. I'm not going without my family. So he moved my grandmother. In all her kids up here in order to get his wife to come up here with it.",
    "ibm_transcription": "she was born in a little town in Georgia in everybody there is if we go because we have family members I go every year were related to everyday somebody in this little baby town like it's a very small town and they came up there with %HESITATION my mother's older sister she passed her husband came up here to sell drugs so he moved the whole my grandmother like my aunt said I'm not going without my family so he moved my grandmother in all her kids up here in order to get his wife to come up here with it",
    "amazon_transcription": "She was born in a little town in Georgia, and everybody there if we go because we have family members that go every year, we're related toe every damn body in this little baby town like it's a very small town, and they came up there with my mother's older sister. She passed. Her husband, came up here to sell drugs, so he moved. But I'll hold my grandmother like my said, I'm not going without my family. So he moved my grandmother and all her kids up here in order to get his wife to come up here with.",
    "google_transcription": "she was born in a little town in Georgia and everybody there and see if we go because we have family members that we're related to every damn body and this little baby sound like it's a very small town and they came up there with my mother's older sister she passed her husband came up here to sell drugs so he moved the whole my grandmother like my aunt said I'm not going without my family so he moved my grandmother and all her kids up here in order to get his wife to come up here with him",
    "apple_transcription": "She was born in a little town in Georgia and everybody there is the if we go because we have family members that go every year we're related to every damn body in this little baby town Mike it's a very small town and they came up there with arm my mother's oldest sister she passed her husband came up here to sell drugs so he moved the whole my grandmother like my aunt said I'm not going without my family so he moved my grandmother and all her kids up here in order to get his wife to come up here with us",
    "clean_google_wer": 0.0962,
    "clean_ibm_wer": 0.1058,
    "clean_amazon_wer": 0.1058,
    "clean_msft_wer": 0.0769,
    "clean_apple_wer": 0.0673,
    "ddm": 0.0096,
    "avg_wer": 0.0904
  },
  {
    "snippet": "PRV_se0_ag3_m_01_1_1239328_1249532.mp3",
    "wordcount": 26,
    "phon_count": 1,
    "phon_features": [
      "monopthongization: time"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "But at the same time, they need to be gently awakened to say well, the only way we can get past that is to address it",
    "age": 64,
    "source": "PRV",
    "gender": "Male",
    "msft_transcription": "But at the same time, they need to be gently. Awakened to say, Well, the only way. We can get past that is too. Address.",
    "ibm_transcription": "at the same time they need to be gently awaken to say well the only way we can get past that is to addresses",
    "amazon_transcription": "But at the same time, they need to be gently awakened to say, Well, the only way we can get past that is too addresses.",
    "google_transcription": "but at the same time they need to be gently awaken to say well the only way we can get past that is too address",
    "apple_transcription": "But at the same time they need to be gently awakened to say well the only way we can get past that is to address",
    "clean_google_wer": 0.1154,
    "clean_ibm_wer": 0.1538,
    "clean_amazon_wer": 0.1154,
    "clean_msft_wer": 0.0769,
    "clean_apple_wer": 0.0385,
    "ddm": 0.0385,
    "avg_wer": 0.1
  },
  {
    "snippet": "PRV_se0_ag1_f_01_1_263425_268630.mp3",
    "wordcount": 22,
    "phon_count": 3,
    "phon_features": [
      "CCR: just",
      "th stopping: something",
      "Monopthongization: I,"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 3,
    "content": "It's just no something that just really like, I mean we always- we got snow but it's not like a lot. Like,",
    "age": 19,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "This is not something that just really like I mean, we always workout smell, but it's not like a light light.",
    "ibm_transcription": "this is not something that just really like I mean we always look at snow but it's not like a lot of my",
    "amazon_transcription": "This is not something that just really like. I mean, we always look at snow, but it's not like a lot like.",
    "google_transcription": "this is not something that just really like snow but it's not like a lot",
    "apple_transcription": "Is this not something that just really like what is that like a lot",
    "clean_google_wer": 0.4545,
    "clean_ibm_wer": 0.3182,
    "clean_amazon_wer": 0.2273,
    "clean_msft_wer": 0.3636,
    "clean_apple_wer": 0.6364,
    "ddm": 0.1364,
    "avg_wer": 0.4
  },
  {
    "snippet": "ROC_se0_ag2_f_03_1_1240617_1251125.mp3",
    "wordcount": 34,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "<ts> I've- I've, uh, looked at U of R and RIT. Um, what I find is that most of them want you to have college experience, which is frustrating cause I'm not looking for teaching jo- roles. <laugh>",
    "age": 39,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "I've looked at U of R&R. IT what I find is that most of them want you to have college experience, which is frustrating 'cause I'm not looking for teaching jet rolls.",
    "ibm_transcription": "I've I've looked at your car and IT what I find is that most of them want you to have college experience which is frustrating because I'm not looking for teaching chat roles",
    "amazon_transcription": "I've looked at U of r and R I t. What I find is that most of them want you to have college experience, which is frustrating, cause I'm not looking for teaching jet rolls.",
    "google_transcription": "I've ever looked at U of r and r i t what I find is that most of them want you to have college experience which is frustrating cuz I'm not looking for teaching that rolls",
    "apple_transcription": "I've ever looked at your bar and ITA what I find is that most of them want you to have college experience which is frustrating as I'm not looking for teaching that rolls",
    "clean_google_wer": 0.1765,
    "clean_ibm_wer": 0.1765,
    "clean_amazon_wer": 0.1765,
    "clean_msft_wer": 0.1471,
    "clean_apple_wer": 0.2353,
    "ddm": 0,
    "avg_wer": 0.1824
  },
  {
    "snippet": "PRV_se0_ag1_f_01_1_739073_752753.mp3",
    "use": false,
    "wordcount": 49,
    "phon_count": 15,
    "phon_features": [
      "grainville?",
      "CCR: around, surroun(d)ings",
      "medial /r/ deletion: tarboro, parts, tarboro",
      "haplology: tarboro (overlap), tarboro (overlap)",
      "th stopping: that, that, that",
      "final consonant deletion after vowel: would, cause, with",
      "CCR neg: didn't",
      "raising of kit vowel: did",
      "friccative stopping: wasn't"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 15,
    "content": "either Greenville, somewhere around the surroundings of Princeville and Tarboro that we would stay with cause um, parts of Tarboro didn't get flooded. Downtown really did, but it wasn't as bad as Princeville, you know. That wasn't but for the grace of God that He just didn't wipe everything out.",
    "age": 19,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "The Greenville somewhere around surroundings of. Principle in Tabor, they will stay work of party top didn't get flooded downtown really did, but it wasn't advantage. Princeville you know that would be the grace of God that he just didn't wipe everything out.",
    "ibm_transcription": "even Plainville somewhere around surrounded of prince William Thomas in with a look of %HESITATION part of targeting it flooded downtown really be it but it wasn't advance prince too you know no one but the grace of god and they just they wipe everything out",
    "amazon_transcription": "even Brain Ville somewhere around surroundings of prints were on top of that. We would stay because I'm part of top. Didn't get flooded downtown really be it. But it wasn't as bad as Prince. You know that one but the grace of God and just wiped everything out.",
    "google_transcription": "even Greenville somewhere around surroundings of Princeville and terrible that we would stay because positive didn't get flooded downtown really did but it wasn't as bad as prince who you know that we got it adjusted wiped everything out",
    "apple_transcription": "Even Greenville somewhere around surroundings of Princeville Pinetop didn't get flooded downtown Willoughby it but it wasn't in the grace of God it is just been wiped everything out",
    "clean_google_wer": 0.449,
    "clean_ibm_wer": 0.5918,
    "clean_amazon_wer": 0.449,
    "clean_msft_wer": 0.3878,
    "clean_apple_wer": 0.5918,
    "ddm": 0.3061,
    "avg_wer": 0.4939
  },
  {
    "snippet": "ROC_se0_ag3_f_02_1_2471400_2503647.mp3",
    "wordcount": 62,
    "phon_count": 3,
    "phon_features": [
      "CCR: moved",
      "unstressed intiial syllable deletion: across",
      "th stopping: with"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 3,
    "content": "restaurant somewhere else. Where did he go? <ts> I don't know where he went, but eventually he was up on Central Avenue up with th- all the hot dogs. But he was on the corner of North Street and Central Avenue. And from there he moved cross town on Clarissa Street, and from there he moved here. Where did he- he was somewhere",
    "age": 80,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "Restaurant somewhere else where did he go. I don't know where he went but eventually he was up on Central Ave up with all the hot dogs, but he was on the corner North Street and Central Ave. And from there, he moved crosstown on Clarisas St. Anne from there. He moved Here, where did he? He was somewhere.",
    "ibm_transcription": "restaurant somewhere else where did he go I don't know where he went but eventually he was up on Central Avenue up with all the hot dogs but he was on the corner on north street and Central Avenue and from there he moved across town on Clarissa street and from there he moved here where did he he was somewhere",
    "amazon_transcription": "the rest of that somewhere else. Where did he go? I don't know where he went. But eventually he was up on Central Avenue, up with all the hot dogs. But he was on the corner, North street and Central Avenue. And from there he moved across town on Claressa Street. And from there he moved here. Where did he? He was somewhere.",
    "google_transcription": "restaurant somewhere else where did he go I don't know where he went but eventually he was up on Central Avenue up with all the hot dogs but he was on the corner of North Street and Central Avenue and from there he moved across town on Clarissa Street and from there he moved here where did he he was somewhere",
    "apple_transcription": "Restaurant somewhere else where did he go I don't know where he went but eventually he was up on Central Avenue up with all the hotdogs but he was on the corner of North Street and Central Avenue and from there he moved cross town on Claris the street and from there he moved here where did he he was somewhere",
    "clean_google_wer": 0.0323,
    "clean_ibm_wer": 0.0484,
    "clean_amazon_wer": 0.129,
    "clean_msft_wer": 0.0968,
    "clean_apple_wer": 0.0806,
    "ddm": 0.0484,
    "avg_wer": 0.0774
  },
  {
    "snippet": "PRV_se0_ag3_m_01_2_337858_348258.mp3",
    "wordcount": 16,
    "phon_count": 2,
    "phon_features": [
      "th stopping: that",
      "CCR: find,"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 2,
    "content": "that it um- it was just a- a rough place to try to find your- your stardom.",
    "age": 64,
    "source": "PRV",
    "gender": "Male",
    "msft_transcription": "Bet it. It was jester. A rough place. To try to find your your starter.",
    "ibm_transcription": "read it %HESITATION it was just the a rough place to try to find your your stardom",
    "amazon_transcription": "but it, uh it was just a rough place to try to find your your stardom.",
    "google_transcription": "bedded it was just a a rough place to try to find your your starter",
    "apple_transcription": "Bedded down it was just in a rough place to try to find you you're a star",
    "clean_google_wer": 0.1875,
    "clean_ibm_wer": 0.125,
    "clean_amazon_wer": 0.125,
    "clean_msft_wer": 0.25,
    "clean_apple_wer": 0.4375,
    "ddm": 0.125,
    "avg_wer": 0.225
  },
  {
    "snippet": "ROC_se0_ag1_f_02_1_422637_464536.mp3",
    "wordcount": 128,
    "phon_count": 1,
    "phon_features": [
      "CCR: noticed"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "Um, it was challenging because I think at that point, um, so when I entered, I was like thirteen, thirteen or fourteen. And I think at that point I started to realize that, you know, not everybody's- So, like, uh, let me take a step back. So, I would participate in different community service activities. So, one of the things that we did is like, interact with like, um, sch- students that went to like suburban high schools. And so I think like going to their facilities and stuff like that, I noticed- like started to notice differences between like, the schools, like, my school and their school. So like, my school, you know, soon as you walk- before you can get into the school you have to go through medical- medical- uh, <ts>",
    "age": 28,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "It was challenging because I think at that point, so when I entered I was like 1313 or 14 nothing at that point I started to realize that you know, not everybody so like let me take some back so I would participate in different community service activities. So one of the things that we did is like interact with like students that went to like suburban high schools and so I think like going to their facilities and stuff like that I notice. Like start to notice differences between like the schools, like my school in their school so like my school. You know soon as you walk before you can get into the school you have to go to medical medical.",
    "ibm_transcription": "it was challenging because I think at that point %HESITATION so when I entered I was like thirteen thirteen or fourteen I think at that point I started to realize that you know not everybody's so like %HESITATION them take some back so I I would participate in different community service activities the one of the things that we did is like interact with like %HESITATION school students that once like suburban high schools and so I think like going to their facilities and stuff like that I noticed like starts notice differences between like the schools like my school in their schools like my school you know some as you walk before you can get into the school you have to go through metal coat medical",
    "amazon_transcription": "come. It was challenging because I think at that point eso when I entered, I was like 13 13 or 14. I think at that point I started to realize that you know, not everybody. So, like lunatics. So back so I would participate in different community service activities. So one of the things that we did is like interact would like students that went like suburban high schools. And so I think, like going to their facilities and stuff like that. I noticed, like, starts to notice differences between, like, the schools, like my schooling. Their school's like my school. You know, Soon as you walk before you can get into the school, you have to go to metal medical.",
    "google_transcription": "it was challenging because I think I that point so when I answer I was like 13 13 or 14 nothing at that point I started to realize that you know not everybody is so like me text her back so I will participate in different community service activities the one of the things that we did is like interact with like students like Suburban high schools and so I think like going to their facilities and stuff like that I noticed I start to notice differences between like the schools like mice when they are schools like my school you know soon as you walk before you can get into the school you have to go through medical Medical",
    "apple_transcription": "It was challenging because I think at that point I am so wanna answer is like 1313 or 14 nothing at that when I started to realize that you know not everybody is so like back so I will participate in different community service activities the one of the things that we did is like interact with like suburban high schools and so I think we're going to bare facilities and stuff like that I noticed bike starts to notice differences between like the schools like my school and their schools like my school you know soon as you want before I can get into the school you have to go to Meadow call medical",
    "clean_google_wer": 0.1953,
    "clean_ibm_wer": 0.1406,
    "clean_amazon_wer": 0.1484,
    "clean_msft_wer": 0.0859,
    "clean_apple_wer": 0.2812,
    "ddm": 0.0078,
    "avg_wer": 0.1703
  },
  {
    "snippet": "PRV_se0_ag3_f_04_1_1293311_1304852.mp3",
    "wordcount": 14,
    "phon_count": 3,
    "phon_features": [
      "CCR: and",
      "th stopping: the",
      "final consonant deletion after vowel: christmas"
    ],
    "gram_count": 1,
    "gram_features": [
      "absence third person singular: she wants"
    ],
    "total_count": 4,
    "content": "and um- <clears throat> She want to go s- to the um, Christmas Parade to see the horses,",
    "age": 76,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "Ann. Oh. She want it goes to the Christmas Parade is see the Horse.",
    "ibm_transcription": "and %HESITATION she wants to go today on crystal the rate if even a hall of",
    "amazon_transcription": "man. Oh, does she want it? Goes to the Christmas for radio. See the hole.",
    "google_transcription": "oh she want to go to the Christmas parade to see the horse",
    "apple_transcription": "I am oh she want to go to today\" all the radius even a hoe",
    "clean_google_wer": 0.2143,
    "clean_ibm_wer": 0.7143,
    "clean_amazon_wer": 0.6429,
    "clean_msft_wer": 0.5,
    "clean_apple_wer": 0.7857,
    "ddm": 0.2857,
    "avg_wer": 0.5714
  },
  {
    "snippet": "PRV_se0_ag2_f_02_1_1322156_1330598.mp3",
    "wordcount": 24,
    "phon_count": 5,
    "phon_features": [
      "th stopping: then, they",
      "final consonant deletion after vowel: but, but",
      "CCR: and"
    ],
    "gram_count": 1,
    "gram_features": [
      "absence of plural -s: good time"
    ],
    "total_count": 6,
    "content": "but it was much distant than they are now. And, uh, but when we did, we had good time, but it was basically on Sundays.",
    "age": 48,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "But it was much distant in the are there. An but when we do, we're good tablet would basically on Sunday's?",
    "ibm_transcription": "that was less distant in the army and %HESITATION but when we did we had good tablet would basically on Sundays",
    "amazon_transcription": "but it was much distant in the art game, and, uh, but when we do, we have a good time but was basically on Sundays.",
    "google_transcription": "but it was much distant than they are now Anna but when we did we had good time but it was basically on Sundays",
    "apple_transcription": "Let's just in the army and Anna but when we do we have a good tablet was basically on Sundays",
    "clean_google_wer": 0.0417,
    "clean_ibm_wer": 0.4583,
    "clean_amazon_wer": 0.3333,
    "clean_msft_wer": 0.4583,
    "clean_apple_wer": 0.6667,
    "ddm": 0.25,
    "avg_wer": 0.3917
  },
  {
    "snippet": "PRV_se0_ag1_f_01_1_1848815_1854862.mp3",
    "wordcount": 9,
    "phon_count": 1,
    "phon_features": [
      "l vocalization after vowel: called maybe remove this snippet cuz it's bad"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "She's um, a- s- staff something? Mm. What is it called?",
    "age": 19,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "She's on the. Staff something where there's a car.",
    "ibm_transcription": "she's on the staff something for the car",
    "amazon_transcription": "she's on staff something. When did you call?",
    "google_transcription": "she's on ask something what is it called",
    "apple_transcription": "She's on the way to the car",
    "clean_google_wer": 0.3333,
    "clean_ibm_wer": 0.6667,
    "clean_amazon_wer": 0.6667,
    "clean_msft_wer": 0.6667,
    "clean_apple_wer": 0.8889,
    "ddm": 0.1111,
    "avg_wer": 0.6444
  },
  {
    "snippet": "DCB_se2_ag4_f_02_1_2043679_2058057.mp3",
    "wordcount": 41,
    "phon_count": 6,
    "phon_features": [
      "final consonant deletion after vowel: head, would",
      "th stopping: there",
      "medial unstressed syllable deletion: totally",
      "monopthongization: my",
      "-ng to -n: wrecking"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 6,
    "content": "um, I can remember uh, cause my father's v- /has a/ hot head, hot temper, very- father's a very mean man. Um, but there was certain things that would happen that he would be- totally be calm. My mother was good at wrecking cars.",
    "age": 51,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "I can remember 'cause I found is hot here at hot temper very funds are very mean man, but there were certain things that. Would happen that he would totally be calm. My mother was good at wrecking cars.",
    "ibm_transcription": "I can remember uncle fun is hi head hot temper very funds very mean man %HESITATION but there were certain things that would happen that he would totally be call my mother was good it reckon cars",
    "amazon_transcription": "I can't remember. Uh, because of father's hothead hot temper. Very funds. Very mean man. But there were certain things that what happened, that he would totally be calm. My mother was good at wrecking cars.",
    "google_transcription": "I can remember cuz I fall is Hot Head hot temper very very mean man but there was certain things that would happen that he would totally be a mother was good at wrecking cars",
    "apple_transcription": "I can remember uncle far as the hot heat hot temper very very mean man but they were certain things that would happen that he would totally be calm a mother was good it reckon car",
    "clean_google_wer": 0.2439,
    "clean_ibm_wer": 0.3415,
    "clean_amazon_wer": 0.3415,
    "clean_msft_wer": 0.2683,
    "clean_apple_wer": 0.3902,
    "ddm": 0.1463,
    "avg_wer": 0.3171
  },
  {
    "snippet": "ROC_se0_ag3_f_02_1_1053864_1089199.mp3",
    "wordcount": 74,
    "phon_count": 7,
    "phon_features": [
      "monopthongization: i, I",
      "th stopping: with, they",
      "r-less after vowel: grandmother",
      "-ng as -n: going",
      "ccr neg: couldn't"
    ],
    "gram_count": 2,
    "gram_features": [
      "verb stem as past tense: stay",
      "copula absence: she going…"
    ],
    "total_count": 9,
    "content": "I stayed with my grandmother until I was thirteen. So when we first got up- when I f- when she first got here and- to take me to school, it was Number /RD-SCHOOL-1/ School on /RD-ADDRESS-2/ Avenue. It's on /RD-ADDRESS-2/ Avenue now. But the r- original was on /RD-ADDRESS-2/ Avenue. So I was go to f- fifth grade, and they said I couldn't, uh, cause I was coming from the South. And she said, she going to fifth grade. (breathy)",
    "age": 80,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "Yes, they with my grandmother. Until I was 13. So when we first got up when I when she first got here. Ann. To take me to school it was number School Ave. Is on an Ave now but the original was on Ave? So I was go to 5th grade. And they said, I couldn't 'cause I was coming from the S. And she said. She gonna 5th grade.",
    "ibm_transcription": "stay with my grandmother until I was thirteen so when we first got up when asked when she first got here and to take me to school it was number school and Amna his son Levin %HESITATION now but they're regionalists on banning of so I was code of fifth grade and they said I couldn't %HESITATION because I was coming from the south and she said she gonna feel great",
    "amazon_transcription": "I stay with my grandmother until I was 13. So when we first got up, when I when she first got here and to take me to school, it was number. School Avenue. Hey, Song never knew now, but the original was on bad enough. So I was goto fifth grade, and they said I couldn't, uh, because I was coming from itself and she said I should go on 1/5 grade.",
    "google_transcription": "I stayed with my grandmother until I was 13 so when we first got up when I when she first got here and to take me to school it was no school Avenue is Saint Anne Avenue now but the original was on Avenue so I was go to 5th grade and they said I couldn't cuz I was coming from the south and she said she's going to 5th grade",
    "apple_transcription": "I stay with my grandmother until I was 13 so when we first got up when I have when she first got here and to take me to school there was none at school and Avenue is on and Avenue now but the original was on the Avenue so I was go to fifth grade and they said I couldn't because I was coming from the south and she said she's going to feel great",
    "clean_google_wer": 0.1216,
    "clean_ibm_wer": 0.2838,
    "clean_amazon_wer": 0.2973,
    "clean_msft_wer": 0.1351,
    "clean_apple_wer": 0.2027,
    "ddm": 0.1216,
    "avg_wer": 0.2081
  },
  {
    "snippet": "ROC_se0_ag1_f_01_1_2814366_2829390.mp3",
    "wordcount": 48,
    "phon_count": 3,
    "phon_features": [
      "final consonant deletion after vowel: would",
      "-ng as -n: talking",
      "th stopping: that"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 3,
    "content": "If that makes sense /?/ And they don't get a lot of my references like <laugh> jokes or, um, I can't think of anything right now, but. Things I would say, they'd be like who- if I were to say, who shot John? You know, you know what I'm talking about.",
    "age": 26,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "And excites me and they don't get a lot of my references like. Joke there, I can't think of maybe thing right now, but Things I would say it would be like if I were to say who shot John you know you know what I'm talking about.",
    "ibm_transcription": "and they think me and they don't get a lot of my references like joke there %HESITATION I can't think of anything right now but I think I would say that because I if I were to say who said that you know you know what I'm talking about",
    "amazon_transcription": "it's in the sentencing and they don't get a lot of my references like jokes there. I can't think of anything right now, But the things I was saying, if I were to say who shot, you know, you know what I'm talking about?",
    "google_transcription": "if that makes sense and I don't get a lot of my references like jokes I can't think of anything right now but things I was saying if I were to say who shot John you know you know what I'm talking about",
    "apple_transcription": "If that makes anything and I don't get a lot of my references like I can't think of anything right now but I think I was saying if I were to say who sheds are you know you know what I'm talking about",
    "clean_google_wer": 0.1667,
    "clean_ibm_wer": 0.2917,
    "clean_amazon_wer": 0.2708,
    "clean_msft_wer": 0.2292,
    "clean_apple_wer": 0.2708,
    "ddm": 0.0625,
    "avg_wer": 0.2458
  },
  {
    "snippet": "DCB_se1_ag2_m_01_1_51264_58418.mp3",
    "wordcount": 12,
    "phon_count": 3,
    "phon_features": [
      "r-less after vowel: for",
      "unstressed intial syllable deletion: about",
      "final consonant deletion after vowel: what"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 3,
    "content": "For about six months. Like six months. What flavor would you like?",
    "age": 27,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "About. What flavor would you like?",
    "ibm_transcription": "flavor which",
    "amazon_transcription": "about six months. What flavor would you?",
    "google_transcription": "about six months what flavor would you like",
    "apple_transcription": "About six months",
    "clean_google_wer": 0.3333,
    "clean_ibm_wer": 0.9167,
    "clean_amazon_wer": 0.4167,
    "clean_msft_wer": 0.5,
    "clean_apple_wer": 0.75,
    "ddm": 0.25,
    "avg_wer": 0.5833
  },
  {
    "snippet": "ROC_se0_ag1_m_03_1_1325836_1333639.mp3",
    "wordcount": 17,
    "phon_count": 1,
    "phon_features": [
      "th stopping: that"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "it's beautiful- it was beautiful how things come together like that, you know. So now, yeah it's /RD-NAME-3/ /RD-WORK-3/.",
    "age": 25,
    "source": "ROC",
    "gender": "Male",
    "msft_transcription": "Is beautiful is beautiful? How things going together like that you know? So now yeah.",
    "ibm_transcription": "it is beautiful whose beautiful out these come together like that you know so now yeah",
    "amazon_transcription": "It's beautiful. It's beautiful How things come together like that, you know? So now, yeah.",
    "google_transcription": "is beautiful is beautiful how things come together like that you know so now I feel ya",
    "apple_transcription": "He is beautiful who is beautiful out these going together like that so now yeah",
    "clean_google_wer": 0.3529,
    "clean_ibm_wer": 0.4118,
    "clean_amazon_wer": 0.1765,
    "clean_msft_wer": 0.2941,
    "clean_apple_wer": 0.5882,
    "ddm": 0.0588,
    "avg_wer": 0.3647
  },
  {
    "snippet": "ROC_se0_ag2_f_03_1_2266432_2313606.mp3",
    "wordcount": 110,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "Yep. And we all, you know, <exhale> we're all guilty of some type of collusion, or you know, whatever it takes to survive. We've all had to swallow our pride at some point. And so, I'm not judgmental at all. Um, and I think we and- and- and I don't know where you are in your journey, but a lot of us, carry guilt. <ts> Um, because in one sense, it's your responsibility to stand up, in another sense, you need to survive. And these folks aren't, you know, into explanation, you know. <laugh> You just need your paycheck. And so what are you willing to put up with in order to pay your bills.",
    "age": 39,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "Yeah, and we all you know. We're all guilty of some type of collusion or. You know, whatever it takes to survive and we've all had to swallow our pride at some point and so I'm not judgmental at all. And I think we And I don't know where you are in your journey, but a lot of us carry guilt. Because. In one sense it's your responsibility to stand up in another sense. You need to survive and these folks aren't you know into explanation. You know you just need your paycheck. And So what are you willing to put up with in order to pay your bills?",
    "ibm_transcription": "yep and we all you know we're all guilty of some type of collusion or you know whatever it takes to survive we've all had to swallow our pride at some point and so I'm not judgmental at all %HESITATION and I think we and and I don't know where you are in your journey but a lot of us carry guilt %HESITATION because in one sense it's your responsibility to stand up in another sense you need to survive and these folks are in you know into explanation you know you just need your paycheck and so what are you willing to put up with in order to pay your bills",
    "amazon_transcription": "Yep. And we all you know, we're all guilty of some type of collusion or, you know, whatever it takes to survive, we all had to swallow our pride at some point, and so I'm not judgmental at all. And I think we and I don't know where you are in your journey, but a lot of us carry guilt because in one, since it's your responsibility to stand up in another sense, you need to survive. And these folks are, you know, into explanation. You know, you just need your paycheck. And so, what are you willing to put up with in order to pay your bills?",
    "google_transcription": "and we all know we're all guilty of some type of collusion or you know whatever it takes to survive we've all had to swallow our pride at some point and so I'm not judgmental at all and I think we In-N-Out know where you are in your journey but a lot of Us carry guilt because in one sense it's your responsibility to stand up in another sense you need to survive and these folks aren't you know into explanation you know you just need your paycheck and so what are you willing to put up with in order to pay your bills",
    "apple_transcription": "Yep and we all know we're all guilty of some type of collusion or email whatever it takes to survive we all had to swallow our pride at some point and so I am not judge mental at all and I think we in and out know where you are in your journey but a lot of us carry guilt because in one sense it's your responsibility to stand up in another sense you need to survive in these folks are you know into exclamation you know you just need your paycheck and so what are you willing to put up with in order to pay your bills",
    "clean_google_wer": 0.0636,
    "clean_ibm_wer": 0.0273,
    "clean_amazon_wer": 0.0455,
    "clean_msft_wer": 0.0364,
    "clean_apple_wer": 0.1364,
    "ddm": 0,
    "avg_wer": 0.0618
  },
  {
    "snippet": "PRV_se0_ag3_m_02_2_65047_72323.mp3",
    "wordcount": 17,
    "phon_count": 7,
    "phon_features": [
      "quasi modals: sposta",
      "th stopping: something",
      "r-less after vowel: four, four, your",
      "final consonant deletion: out",
      "CCR: district"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 7,
    "content": "it runs uh- ought- we sposta be /something like/- where it's calls that /??/- four miles out your district.",
    "age": 78,
    "source": "PRV",
    "gender": "Male",
    "msft_transcription": "They run with the subway with skulls the poem Oh mother, you dis.",
    "ibm_transcription": "Iran always will be sold lay waste calls the old loud loud you district",
    "amazon_transcription": "and runs way spoke with the sub light where schools there.",
    "google_transcription": "are we supposed to be some light when it's cold",
    "apple_transcription": "They run all of us will be so black West Coast Lappula oh my love you did",
    "clean_google_wer": 0.8824,
    "clean_ibm_wer": 0.8235,
    "clean_amazon_wer": 0.8824,
    "clean_msft_wer": 1,
    "clean_apple_wer": 1,
    "ddm": 0.4118,
    "avg_wer": 0.9176
  },
  {
    "snippet": "PRV_se0_ag3_m_01_1_1321129_1339820.mp3",
    "wordcount": 44,
    "phon_count": 3,
    "phon_features": [
      "CCR: and, assigned",
      "-ng as n: going"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 3,
    "content": "uh, and so all of a sudden I guess someone assigned, said well, that's a safe haven for them because Jim Crowism was still going on- that the- some of the southerners just was bitter about taking their free- free slave labor away from 'em.",
    "age": 64,
    "source": "PRV",
    "gender": "Male",
    "msft_transcription": "And so. All of a sudden I guess someone. Assign said well. That's a safe Haven for them. Becaus Jim Crowism was still going on that. The sum of the southerners just was bitter about taking their free free slave labor away from.",
    "ibm_transcription": "%HESITATION is so all of a sudden I guess someone I signed so well that's a safe haven for them because Jim crow is was steel going on that the some of the seven as Jess was bitter about taken their free free slave labor away from",
    "amazon_transcription": "um, s o all of a sudden, I guess someone, a sign, said, Well, that's a safe haven for them because Jim Crowism was still going on that some of the Southerners just was bitter about taken their free, free slave labour away from him.",
    "google_transcription": "and so all of a sudden I guess someone assigned so well that's a safe haven for them because Jim crowism was still going on that the sum of the southerners just was bitter about taking their free free slave labor away from",
    "apple_transcription": "Hey so all of a sudden I guess someone aside and said well that's a safe haven for them because Jim Grissom was still going on that the sum of the Southerners just was bitter about taking their free free slave labor away from",
    "clean_google_wer": 0.0682,
    "clean_ibm_wer": 0.2727,
    "clean_amazon_wer": 0.1818,
    "clean_msft_wer": 0.0909,
    "clean_apple_wer": 0.1364,
    "ddm": 0.0682,
    "avg_wer": 0.15
  },
  {
    "snippet": "PRV_se0_ag3_m_01_1_1997146_2020374.mp3",
    "wordcount": 63,
    "phon_count": 4,
    "phon_features": [
      "CCR: ground, ground",
      "th stopping: that, then"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 4,
    "content": "And we used to sweep it with the brush brooms. And it would look neat a- uh, once you cl- you know, got it cleaned and neat. And um, I used to lay on that ground and look at that dog, look him in his eyes and he'd look me right in mine and then I would draw him. I would draw on this ground.",
    "age": 64,
    "source": "PRV",
    "gender": "Male",
    "msft_transcription": "And we used to sweep it with the brush brooms. And it would look neat once you, you know got it clean and an. I used to lay on the ground and look at that dog looking in his eyes and. He looked me right in mine and. Then I would draw him I would draw on this ground.",
    "ibm_transcription": "you used to sweep it with the brush brooms and would look neat eight L. once you you know got its clean and neat and %HESITATION I used to lay on that ground and look at that dog look him in his eyes and he looked me right in line in then I would draw in I would draw on this ground",
    "amazon_transcription": "and we used to sweep it with the brush brooms and it would look neat once you got it clean. And and I used to lay on it ground and look at that dog. Look him in his eyes and he'd look me right in mine. And then I would draw him. I would draw on this ground.",
    "google_transcription": "and used to sweep it with the brush brooms and would look neat and once you enter got it clean and Nick and I used to lay on that ground and look at that dog look at me in his eyes and he looked me right in mine and then I would drawing I would draw on this graph",
    "apple_transcription": "And we used to sweep it with the brush brooms and it would look neat it out once you you know God is clean and neat and none are used to lay on the ground and look at that dog look him in his eyes and he looked me right in my hand and then I would draw him I would draw on his ground",
    "clean_google_wer": 0.2381,
    "clean_ibm_wer": 0.2063,
    "clean_amazon_wer": 0.1111,
    "clean_msft_wer": 0.1587,
    "clean_apple_wer": 0.2222,
    "ddm": 0.0635,
    "avg_wer": 0.1873
  },
  {
    "snippet": "DCB_se2_ag4_f_05_1_1003212_1026158.mp3",
    "wordcount": 55,
    "phon_count": 5,
    "phon_features": [
      "CCR neg: didn't",
      "r-less after vowel:  your",
      "final consonant deletion after vowel: dead",
      "-ng as -n: going",
      "initial d deletion: I don't care"
    ],
    "gram_count": 2,
    "gram_features": [
      "coupla absence: they dead, why you going tell me"
    ],
    "total_count": 7,
    "content": "Yes. I- I- I can't say I didn't like English, math, and all of that. I was good at that too. I hated history. I mean, what's your point? They dead. It's over. Why you gonna tell me about it? Put that drama in my life? I don't care. That's the way I felt.",
    "age": 61,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "Yes. I I can't say I didn't like English math knowledge that I was good at that too. I hated history. I mean was she born. Day day is so. Why you wanna tell me about it? Drama in my life. That's where I felt.",
    "ibm_transcription": "I again said then like English math and all of that I was good at that too I hated history militia point day in day is so long my you want ME about the bed drama in my life okay those left but %HESITATION",
    "amazon_transcription": "Yeah. I can't say I didn't like English math known that I was good at that, too. Hated history. I mean, what's your point? They isso. Why? You want to hear about a drum in my life? Okay, that's left.",
    "google_transcription": "yes I guess I didn't like English math and all that I was good at that too I hated history Mission Point they did it's over by you want to tell me about it but that drama in my life I don't care that's why I fell",
    "apple_transcription": "Yes I can't say I didn't like English math and all that I was good at that too I hated history the day is so long why you want to give me a bag of the drama in my life OK no sweat",
    "clean_google_wer": 0.2909,
    "clean_ibm_wer": 0.5636,
    "clean_amazon_wer": 0.4364,
    "clean_msft_wer": 0.3636,
    "clean_apple_wer": 0.4727,
    "ddm": 0.1273,
    "avg_wer": 0.4255
  },
  {
    "snippet": "DCB_se3_ag3_f_02_1_2883645_2918424.mp3",
    "wordcount": 121,
    "phon_count": 6,
    "phon_features": [
      "-ng as n: branding,going",
      "CCR neg: didn't",
      "haplology: probably",
      "final consonant deletion: time",
      "th stopping: that"
    ],
    "gram_count": 2,
    "gram_features": [
      "copula absence: we going to the arc",
      "unstressed been:been here…"
    ],
    "total_count": 8,
    "content": "it was very welcome. Even to the point I didn't even know that- <laugh> /but/ I don't think they did branding very well back then. (laughing) But I didn't even know. I thought, we going to the ARC. That's what it was, it was the ARC, period. So when I interviewed, and we talked about it, even then, I said <ts> I was about probably my mid-twenties, and that's when I- I went back there for something. And, um, <ts> I remember saying- And at that time I was like, oh, Boys and Girls Club took over? <laugh> Been here the whole time. Uh, but I was telling, uh, /RD-NAME-3/ I was like, yeah, <ts> that's when I first realized it was the Boys and Girls Club. All that time.",
    "age": 39,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "It was very welcome even to the point. I'll even know that have nothing today. Brandon very well. Back then, but I don't even know I thought you wanted art does it wasn't dark period. So when I interviewed and we talked about it even then I said, I was about. Are my mid 20s and that's why? I went back there for something and I remember seeing and at that time, I was like all boys and Girls Club took over. If we had a whole time, I don't know selling yeah. That's not friends realize is with this well. All the time.",
    "ibm_transcription": "it was very welcome it was to the point I even know that about the brand a very well by the way I don't even know I thought that art period so when I interviewed and we talked about the even then I said I was about hi my mid twenties and that's when I I went back there for something and %HESITATION I love the scene in that that's almost like always the girls hope to go for they had a whole front allows %HESITATION and I both I guess that's my friends realize it wasn't as well all the time",
    "amazon_transcription": "It was very welcome even to the point that I even know that have mounting today, Brandon. Very well back then. I didn't even know I thought wanted art because it was a dark period. So when I interviewed on, we talked about it. Even then I said I was about Hi, my mid twenties. And that's when I I went back there for something. And I remember seeing. And at that time I was like, Oh, boys and girls will take over the whole time, you know? So, uh, yeah, that's when I first realized the Boys and Girls Club What time?",
    "google_transcription": "it was very welcome you to the point of even know that have found any day Brandy very well by then why don't even know I thought. So when I interviewed I mean talk about it even then I said I was about her my mid-twenties and that's why I went back there for something and I remember seeing it and that's how all the Boys & Girls Club to go first",
    "apple_transcription": "It was very welcome it was the point of even know that they branded very well even know I thought that I like. So when I interviewed and we talked about the evening then I said I was about I'm a mess when he's an ass and I I went back there for something and I am I've ever seen him at that time I was like oh boy the girls got to go because I guess that's enough as well as the weather goes well whatever",
    "clean_google_wer": 0.5868,
    "clean_ibm_wer": 0.5372,
    "clean_amazon_wer": 0.3223,
    "clean_msft_wer": 0.405,
    "clean_apple_wer": 0.6116,
    "ddm": 0.0661,
    "avg_wer": 0.4926
  },
  {
    "snippet": "DCB_se2_ag2_f_02_1_729651_746981.mp3",
    "wordcount": 57,
    "phon_count": 1,
    "phon_features": [
      "unstresssed syllable deletion: dominion"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "<ts> Caroline County, Virginia, that's down by King's Dominion. So when I was still up here, I went through that program. Um, all three years, and basically I'm like, okay, yeah. I definitely still wanna be a lawyer. So when I was at /RD-SCHOOL-3/ High School, I went to another law program for uh, I wanna say two weeks in Louisiana.",
    "age": 23,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "Caroline County, Virginia, this down by Kings mean so when I was still up here. I won't do. That program all 3 years and basically only OK, yeah, definitely still want to be a lawyer. So when I was at high school. I would set another law program for I want to say 2 weeks in Louisiana.",
    "ibm_transcription": "Caroline county Virginia that's down I think when I was still up here I went to that program %HESITATION other years and basically I'm like okay I definitely want to be a lawyer so when I was at high school I was another law program forever up I want to say two weeks in Louisiana",
    "amazon_transcription": "Caroline County, Virginia. That's down by kings. So when I was still up here, I went to that program out all the years. And basically I'm like, OK, I definitely don't want to be a way So when I was at my school, I wants another law program for a want to say two weeks in Louisiana.",
    "google_transcription": "Caroline County Virginia that sound like he's being so when I was still up here I went to that program all three years and basically I'm like okay I definitely want to be away so when I was at ask why what's another law program for I want to say two weeks in Louisiana",
    "apple_transcription": "Still up here I went to that program other years and basically I'm like OK I deafly so would be away so when I was at high school I was another law program for him I want to say two weeks in Louisiana",
    "clean_google_wer": 0.3158,
    "clean_ibm_wer": 0.2807,
    "clean_amazon_wer": 0.2632,
    "clean_msft_wer": 0.2281,
    "clean_apple_wer": 0.4561,
    "ddm": 0.0175,
    "avg_wer": 0.3088
  },
  {
    "snippet": "PRV_se0_ag2_f_02_2_410739_422869.mp3",
    "wordcount": 31,
    "phon_count": 3,
    "phon_features": [
      "final consonant deletion: have",
      "r-less after vowel: pastor",
      "CCR: pas(t)or"
    ],
    "gram_count": 1,
    "gram_features": [
      "copula absence: he a pastor,"
    ],
    "total_count": 4,
    "content": "And, um, like, he's a pastor of two churches. He used to have three. But he a pastor of, uh, two churches. And at Mount Zion here in Princeville. Which is the oldest,",
    "age": 48,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "And. Like he's a pastor 2 churches. He usually had 3 but he had passed 2 churches and at Mount Sinai here in Princeville, which is the oldest.",
    "ibm_transcription": "like he's a past the two churches use it three but you know past the two churches and events that you imprints via which is the oldest",
    "amazon_transcription": "no, like he's a pastor to churches. You saying three? But he passed a two churches and that mountainside hearing Prince Fear, which is the oldest.",
    "google_transcription": "like he's a pastor to churches you said three for your pastor two churches and I'm outside here in Princeville which is the oldest",
    "apple_transcription": "Like he's a pastor to churches use it through the churches which is the old is",
    "clean_google_wer": 0.4516,
    "clean_ibm_wer": 0.5484,
    "clean_amazon_wer": 0.5161,
    "clean_msft_wer": 0.2581,
    "clean_apple_wer": 0.7419,
    "ddm": 0.129,
    "avg_wer": 0.5032
  },
  {
    "snippet": "PRV_se0_ag3_f_02_2_1083422_1090341.mp3",
    "wordcount": 12,
    "phon_count": 1,
    "phon_features": [
      "final consonant deletion: le(t)s"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "And let's see, somebody else, uh, have you ever heard of Thomas Reeves?",
    "age": 40,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "And let's see somebody else. Uh. Have you ever heard of Thomas reads?",
    "ibm_transcription": "and let us say somebody else %HESITATION have you ever heard of Thomas reads",
    "amazon_transcription": "and see somebody else. Um, have you ever heard of Thomas reads?",
    "google_transcription": "and let's see somebody else have you ever heard of Thomas Reeves",
    "apple_transcription": "And C somebody else have you ever heard of Thomas Reeves",
    "clean_google_wer": 0,
    "clean_ibm_wer": 0.3333,
    "clean_amazon_wer": 0.1667,
    "clean_msft_wer": 0.0833,
    "clean_apple_wer": 0.1667,
    "ddm": 0.0833,
    "avg_wer": 0.15
  },
  {
    "snippet": "ROC_se0_ag2_f_02_1_2640085_2645206.mp3",
    "wordcount": 19,
    "phon_count": 1,
    "phon_features": [
      "r-less after vowel: you're"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "it really is, like I lived in Greece for three years. I'll never forget because- you're gonna laugh. (laughing)",
    "age": 32,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "Really is like I lived in Greece for 3 years I'll never forget because you're going to laugh.",
    "ibm_transcription": "it really is like I lived in Greece for three years I'll never forget but none the less",
    "amazon_transcription": "it really is like I lived in Greece for three years. I'll never forget, but it's gonna last.",
    "google_transcription": "it really is like I lived in Greece for 3 years I'll never forget because",
    "apple_transcription": "It really is like I lived in Greece for three years I'll never forget",
    "clean_google_wer": 0.2105,
    "clean_ibm_wer": 0.2632,
    "clean_amazon_wer": 0.1579,
    "clean_msft_wer": 0.0526,
    "clean_apple_wer": 0.2632,
    "ddm": 0.0526,
    "avg_wer": 0.1895
  },
  {
    "snippet": "ROC_se0_ag3_f_02_1_3135582_3162790.mp3",
    "wordcount": 60,
    "phon_count": 2,
    "phon_features": [
      "-ng as n: coming",
      "r-less after vowel: your"
    ],
    "gram_count": 2,
    "gram_features": [
      "verb stem as past: what they call serials",
      "absence of plural -s: and at the movie"
    ],
    "total_count": 4,
    "content": "And at the movie when you went then you got a choice of you- you always had two full length movies. And you had I don't know how many cartoons, then you had the coming attractions. You had uh, what they call serials, which was chapters. And- and you always had a western. You got all of that for your money.",
    "age": 80,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "Then that movie when you went then. You've got a choice of. You always had 2 full length movies. An you had I don't know how many cartoons. Then you had the common attractions, you had what they call cereals, which was chapters and you always had a western you got all of that for your money.",
    "ibm_transcription": "and that that movie when you went then you get a choice of you always had to full length movies and you head out on how many cartoons then you had the common attractions you head of what they call cereals which woods chapters and and you always had a western you got all that for you money",
    "amazon_transcription": "and that the movie when you went, then you got a choice of you always had to full of life movies. And you had out how many car tools. Then you had the common attractions you had, uh, what they call serials, which was chapters. And and you always had a Western. You got all of that for your money.",
    "google_transcription": "and that the movie when you went in you got a choice of you always had two full-length movies and you had I don't how many cartoons then you had to come in a traction you had what they call cereals which was chapters and and you always had a western you got all that for your money",
    "apple_transcription": "And that the movie when you went then you would get a choice of you you always had two full length movies and you had I don't how many cartoons then you had the common attractions you had a what they call cereals which was chapters and and you always had a western do you get out all the debt for your money",
    "clean_google_wer": 0.1833,
    "clean_ibm_wer": 0.2667,
    "clean_amazon_wer": 0.1833,
    "clean_msft_wer": 0.15,
    "clean_apple_wer": 0.2,
    "ddm": 0.0667,
    "avg_wer": 0.1967
  },
  {
    "snippet": "DCB_se3_ag4_m_01_1_3212518_3245845.mp3",
    "wordcount": 114,
    "phon_count": 8,
    "phon_features": [
      "th stopping: they, this, with",
      "-ng as -n: putting,",
      "l vocalization after vowel: yall, yall",
      "unstressed sylablle deletion: supposed",
      "CCR: lost"
    ],
    "gram_count": 5,
    "gram_features": [
      "ain't preverbal negator: we ain't putting, how many marbles in the bottle",
      "copula absence: where they supposed to",
      "absence plural -s: all kids, tests"
    ],
    "total_count": 13,
    "content": "You know they saw us like, oh okay well these black people, you know, niggas getting unr- r- restless, you know. We ain't putting up with this. You know. Particularly when they saw us in like positions as Senators and they were like uh-uh. Y'all- y'all got to fix the rules to get them where they sposta belong. And all of those positions that they got during reconstruction they lost because the white government the white state government figured out a way to get them out of office with all kind of crazy tests that you had to- how many marbles in a bottle? Read this paragraph. Oh you didn't read it right. You can't vote.",
    "age": 60,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "You know they saw us like Oh, OK well, he's like people, you know ****** get restless. You know, we input for this. Particular when they saw us in like positions at senators and yeah, you're going to fix the rules to get them back where they supposed to blow and all of those positions that they got during reconstruction, they lossed. Because the white government Dwight state governments figured out a way to get them out of office with all kind of crazy test that you had? How many marbles in a bottle read this paragraph. All you didn't read it right you can vote.",
    "ibm_transcription": "you know they thought I was like oh okay with these black people you know I think it gets restless you know we input for this you know particular when they saw us in like positions as senators and %HESITATION yada yada six the rules to get them back when he spoke and all those positions that they got to a reconstruction a loss because the white government the white state government figured out a way to get them out office with all kind of crazy tested you had how many marbles in a bottle read this paragraph %HESITATION eating reader right you can't go",
    "amazon_transcription": "you know, they saw. It's like, Okay with these black people, you know, I think it's getting restless, you know, we put up with it, you know, particularly when they saw us in, like, positions of senators. And they were like, Yeah, you're gonna fix the rules to get them back where they spoke and all of those positions that they got during reconstruction. They lost because the white government, the white state government, figured out a way to get them out of office with all kind of crazy test that you had. How many marbles in a bottle? Read this paragraph or you didn't read it right? You can't vote.",
    "google_transcription": "you know they thought I was like okay with y'all going to fix the rules to get them back where they supposed while and all of those positions that they got during Reconstruction they lost because the white government the right state government figure out a way to get them out of office with all kind of crazy test if you had how many marbles in a bottle read this paragraph o you didn't read it right you can vote",
    "apple_transcription": "You know they thought was like OK what is black restless you know we ain't put up with it you know but tickly when they saw us in it like positions of senators and yada yada to fix the rules to get them back where they spoke and all those positions that they got the reconstruction they lost because of the white government the white state government figured out a way to get them out office with all kind of crazy test that you had how many marbles in a bottle read this paragraph how are you doing read or write your camper",
    "clean_google_wer": 0.4211,
    "clean_ibm_wer": 0.3509,
    "clean_amazon_wer": 0.1754,
    "clean_msft_wer": 0.2719,
    "clean_apple_wer": 0.3509,
    "ddm": 0.114,
    "avg_wer": 0.314
  },
  {
    "snippet": "PRV_se0_ag3_m_03_1_1782492_1788009.mp3",
    "use": true,
    "attribution": "coraal",
    "wordcount": 26,
    "phon_count": 8,
    "phon_features": [
      "final consonant deletion: side, cause, crown",
      "th stopping: the, their,then",
      "intersyllabic /r/ deletion: government",
      "unstressed sylabble deletion: pre(si)dent"
    ],
    "gram_count": 1,
    "gram_features": [
      "'and them' associative construction: president and all them crowd (overlap)",
      "'done' construction: done took"
    ],
    "total_count": 9,
    "annotations": [
      {
        "timeStart": 0.8947483752925497,
        "timeEnd": 1.1683254304411022,
        "timeCtxStart": 0,
        "timeCtxEnd": 1.3441963944651716,
        "start": 27,
        "end": 31,
        "content": "Final Consonant Deletion"
      },
      {
        "timeStart": 1.3979347445836374,
        "timeEnd": 1.5054114448205687,
        "timeCtxStart": 1.3588523081338442,
        "timeCtxEnd": 2.3749956558284677,
        "start": 36,
        "end": 41,
        "content": "Final Consonant Deletion"
      },
      {
        "timeStart": 1.5054114448205687,
        "timeEnd": 1.6128881450575001,
        "timeCtxStart": 1.3588523081338442,
        "timeCtxEnd": 2.3749956558284677,
        "start": 42,
        "end": 45,
        "content": "Syllable initial fricative stopping"
      },
      {
        "timeStart": 1.6128881450575001,
        "timeEnd": 2.0427949460052255,
        "timeCtxStart": 1.3588523081338442,
        "timeCtxEnd": 2.3749956558284677,
        "start": 46,
        "end": 56,
        "content": "Vocalization of intersyllabic /r/"
      },
      {
        "timeStart": 2.120959818904812,
        "timeEnd": 2.291945478372657,
        "timeCtxStart": 1.3588523081338442,
        "timeCtxEnd": 2.3749956558284677,
        "start": 62,
        "end": 67,
        "content": "Syllable initial fricative stopping"
      },
      {
        "timeStart": 2.8537555023384344,
        "timeEnd": 3.293432912398608,
        "timeCtxStart": 2.7462788021015028,
        "timeCtxEnd": 4.392626437549042,
        "start": 68,
        "end": 77,
        "content": "Unstressed medial syllable deletion"
      },
      {
        "timeStart": 3.293432912398608,
        "timeEnd": 3.5328128356535915,
        "timeCtxStart": 2.7462788021015028,
        "timeCtxEnd": 4.392626437549042,
        "start": 82, // Manual
        "end": 96, // Manual
        "content": "1) \"And them\" to mark associative plurals; 2) Syllable Initial Fricative Stopping: \"Them\" -> \"Dem\""
      },
      {
        "timeStart": 3.7575368452399025,
        "timeEnd": 3.9516984126984127,
        "timeCtxStart": 2.7462788021015028,
        "timeCtxEnd": 4.392626437549042,
        "start": 97,
        "end": 101,
        "content": "Completive done"
      }
    ],
    "content": "it's a little on the tight side now cause the government took their- president and all them crowd done took all /this/ money away from 'em.",
    "age": 83,
    "source": "PRV",
    "gender": "Male",
    "ibm_transcription": "a little bit tight and I could go Brill dental crown and took all the money away from",
    "amazon_transcription": "isn't it? On the type of inequity. Good. April did no wrong crowd and took all the money we from",
    "google_transcription": "turn it on a type V took all this money away from",
    "apple_transcription": "Turn on the toilet for real didn't know no problem took all his money away from",
    "clean_google_wer": 0.7308,
    "clean_ibm_wer": 0.6538,
    "clean_amazon_wer": 0.7308,
    "clean_msft_wer": 1,
    "clean_apple_wer": 0.7308,
    "ddm": 0.3462,
    "avg_wer": 0.7692,
    "real_transcription": "It's a little on the tight side now cause the government took their president and all them crowd done took all this money away from ‘em.",
    "asr_transcription": "Isn't it? On the type of inequity. Good. April did no wrong crowd and took all the money we from"
  },
  {
    "snippet": "DCB_se2_ag2_f_02_1_3603855_3628279.mp3",
    "wordcount": 86,
    "phon_count": 2,
    "phon_features": [
      "L vocalization: always (areays)",
      "final consonant deletion: nigh(t)s"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 2,
    "content": "Um, and it's a v- variety, and they're /areays/- always speaking the truth. In this new go go where they're just taking other songs and turning into go go music- it's like, no. It's speaking the truth. I love Chuck Brown. Um, and it's- I- I remember um- and that's why I can't- I can't complain at you know, what environment I was exposed to. Cause as I'm older, I'm remembering the late nights, and that's why my dad's dancing. Like, that's what we used to do all night.",
    "age": 23,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "In its of variety in there already is always speaking the truth. And this new go-go where they're just taking other songs and turning into Google music window is speaking the truth. I love Chuck Brown. And it's I remember that's why I can't. I can't complain at you know what environment I was exposed because as I'm older. I'm remembering the late nights and that's why my dad is dancing like that's what we used to do on me.",
    "ibm_transcription": "of %HESITATION pricey and there are you always speaking the truth in this new go go where they're just taking other songs and turning and sick of the music thing now is speaking the truth I love Chuck brown %HESITATION and as I remember and that's why I can't I can't complain at you know what environment I was exposed to because as I'm older I'm remembering the late night that's what my dad dancing like that's what we used to do on a",
    "amazon_transcription": "variety, and they're already is always speaking the truth and this new go go where they're just taking other songs and turning into global music. No, it's speaking the truth. I love Chuck Brown, Um, and it's I remember, uh, that's why I can't I can't complain at you know what environment. I was exposed because as I'm older, I'm remembering the late night, and that's why my dad's dancing. We that's what we used to do on the",
    "google_transcription": "and if Variety in there already is always speaking the truth in this new go go where they're just taking other songs and turning into Goku music from now is speaking the truth I love Chuck Brown and if I remember and that's why I can't I can't complain at you know what environment I was exposed to cuz I'm older I'm remembering the late night that's what my dad's dancing like that's what we do on",
    "apple_transcription": "And it's a Maria see in there already is always speaking the truth in this new gogo where they're just taking other songs attorney is speaking the truth I love Chuck Brown and as I remember that's why I can't I can't complain at a nowhere environment I was exposed think that I'm older I'm remembering the late night that's what my dad dancing way that's what we used to do on it",
    "clean_google_wer": 0.2674,
    "clean_ibm_wer": 0.2907,
    "clean_amazon_wer": 0.2209,
    "clean_msft_wer": 0.2558,
    "clean_apple_wer": 0.3953,
    "ddm": 0.0233,
    "avg_wer": 0.286
  },
  {
    "snippet": "ROC_se0_ag1_f_03_1_868173_881744.mp3",
    "wordcount": 26,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "So if you combine that with, uh, a career goal, that it- it- it only reaches success if someone likes the way you look. You could be",
    "age": 28,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "So if you combine that with. Career goal. That. It it, it only reaches success. If someone likes the way you look. You can be.",
    "ibm_transcription": "so if you combine that with %HESITATION career goal that it it only reaches excessive someone likes the way you look you can be in",
    "amazon_transcription": "So if you combine that with, uh, career goal that it is on ly reaches successive Someone likes the way you look, you could be",
    "google_transcription": "if you combine that with career goal that it is only reaches excessive someone likes the way you look you can be",
    "apple_transcription": "If you combine that with a career goal that it it it only reaches excessive someone likes the way you look you can be",
    "clean_google_wer": 0.2692,
    "clean_ibm_wer": 0.2308,
    "clean_amazon_wer": 0.2308,
    "clean_msft_wer": 0.0769,
    "clean_apple_wer": 0.1538,
    "ddm": 0,
    "avg_wer": 0.1923
  },
  {
    "snippet": "ROC_se0_ag1_f_03_1_2721964_2731344.mp3",
    "wordcount": 22,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "um, I think that like, places deep on the West Side and deep on the East Side are probably like in the city.",
    "age": 28,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "I think that. Like places deep on the Westside in deep on the side are probably like in the city.",
    "ibm_transcription": "I think that thank places deep on the west side and deep on the inside %HESITATION probably like in the city",
    "amazon_transcription": "I think that, like places deep on the west side and deep on the east side, are probably like in this city.",
    "google_transcription": "I think that like places deep on the westside in deep on the east side or probably like in the city",
    "apple_transcription": "I think that like places deep on the west side and deep on the side or probably like in the city",
    "clean_google_wer": 0.1818,
    "clean_ibm_wer": 0.1818,
    "clean_amazon_wer": 0.0455,
    "clean_msft_wer": 0.1818,
    "clean_apple_wer": 0.0909,
    "ddm": 0,
    "avg_wer": 0.1364
  },
  {
    "snippet": "DCB_se2_ag4_f_03_1_547321_561317.mp3",
    "wordcount": 52,
    "phon_count": 4,
    "phon_features": [
      "-ng as -n: going",
      "monopthongization: I, I",
      "final consonant deletion: preach"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 4,
    "content": "and going- I mean he could pre- Oh he could just like, fire and just work everybody up. You know, he was just that kind of peo- I can see that. I c- I don't remember exactly what it was he talked about, but I remember- I can see him in the-",
    "age": 65,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "One I mean he could pre oh, he could just like fire and just work. Everybody up you know, he was just that kind of I can see that I don't remember exactly what it was. He talked about, but I remember I can see him in.",
    "ibm_transcription": "one I mean you get free or you can dislike fatter than just wake everybody up you know uses that canopy I can see that I don't remember exactly what it was he talked about but I've moved but I can see him in the",
    "amazon_transcription": "and go on. I mean, he could pre Oh, you could just, like, fire and just work everybody up. You know, he was just that kind of thing. I can see that. I don't remember exactly what it was he talked about, but I can see him and this.",
    "google_transcription": "the one I mean he caprito he could just like fire and just work everybody up you know he was just that kind of P I can see that I don't remember exactly what it was he talked about but I can see him in",
    "apple_transcription": "And the one I mean you get free or you could just like fire and just work everybody up you know uses the canopy I could see that at I don't remember exactly what it was he talked about but I will I can see him",
    "clean_google_wer": 0.2115,
    "clean_ibm_wer": 0.4615,
    "clean_amazon_wer": 0.1923,
    "clean_msft_wer": 0.1154,
    "clean_apple_wer": 0.3846,
    "ddm": 0.0769,
    "avg_wer": 0.2731
  },
  {
    "snippet": "ROC_se0_ag2_f_03_1_2989460_3013623.mp3",
    "wordcount": 71,
    "phon_count": 1,
    "phon_features": [
      "CCR: around"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "Um, my wife points it out. She says, o- w- as soon as I get on the phone with my family, or she said we can be driving to their house, and my- my language changes. And without even thinking about it, and she's like, it happens automatically. Cause I don't pay attention to it. Um, but the way I speak changes when I'm around, um, my family, or predominantly around people of color.",
    "age": 39,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "Hum. My wife pointed out she says as soon as I get on the phone with my family. Or she said. We can be driving to their house and my my language changes and without even thinking about it and she's like it happens automatically 'cause I don't even pay attention to it, but the way I speak changes. When I'm around my family or predominantly around people of color.",
    "ibm_transcription": "%HESITATION my wife pointed out ceases as soon as I get on the phone my family or she said we can be driving to their house and my my language changes and without even thinking about it and she's like it happens automatically because I don't pay attention to it %HESITATION but the way I speak changes when I'm around my family or print out early around people of color",
    "amazon_transcription": "um, my wife pointed out, she says, But soon as I get on the phone with my family, or she said, we could be driving to their house and my my language changes and without even thinking about it. And she's like, It happens automatically, so don't even pay attention to it. Um, but the way I speak changes when I'm around my family or predominantly around people of color.",
    "google_transcription": "my wife pointed out she says as soon as I get on the phone with my family or she said we can be driving to their house and my my language changes and without even thinking about it and she's like it happens automatically cuz I don't pay attention to it but the way I speak changes when I'm around my family or predominately around people of color",
    "apple_transcription": "My wife pointed out she says that soon as I get on the phone with my family or she said we can be driving to their house am I my language changes and without even thinking about it and she's like it happens automatically cause I don't pay attention to it but the way I speak changes when I'm around my family or put out a way around people of color",
    "clean_google_wer": 0.0704,
    "clean_ibm_wer": 0.1549,
    "clean_amazon_wer": 0.1268,
    "clean_msft_wer": 0.0845,
    "clean_apple_wer": 0.1549,
    "ddm": 0.0141,
    "avg_wer": 0.1183
  },
  {
    "snippet": "ROC_se0_ag3_f_02_1_3098829_3122479.mp3",
    "wordcount": 44,
    "phon_count": 3,
    "phon_features": [
      "final consonant deletion: that",
      "r-less after vowel: other",
      "CCR: east"
    ],
    "gram_count": 1,
    "gram_features": [
      "existential 'it': it was so many movies"
    ],
    "total_count": 4,
    "content": "And that was- that was really nice. <ts> My grandmother and I came up here yeah they had the RKO Palace, the RKO Temple, The RKO? The other one was on East Avenue. Oh it was so many movies. Um, I lost my train of thought.",
    "age": 80,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "And that was that was really nice. My grandmother and I came up here. Yeah, they had archeo para list Archail Temple. The Archail. The other one was on East Ave. How it was so many movies? I lost my train.",
    "ibm_transcription": "and that was that was really nice my grandmother and I came up here yeah there are killed panelist RKO temple are killed the other one was on the stand the new how so many movies %HESITATION I lost my train",
    "amazon_transcription": "and that was That was really nice. My grandmother and I came up here. Yeah, they had archeo palest RKO tipple, our kill. The other one was on east and no, however, so many movies. Um, I lost my train of thought.",
    "google_transcription": "and that was that was really nice my grandmother and I came up here yeah they had RKO Palace RKO Temple to RKO the other one was on 5th Avenue however so many movies I lost my train of thought",
    "clean_google_wer": 0.1591,
    "clean_ibm_wer": 0.3409,
    "clean_amazon_wer": 0.25,
    "clean_msft_wer": 0.2045,
    "clean_apple_wer": 1,
    "ddm": 0.0909,
    "avg_wer": 0.3909
  },
  {
    "snippet": "DCB_se3_ag3_m_02_1_2176050_2183078.mp3",
    "wordcount": 33,
    "phon_count": 4,
    "phon_features": [
      "monopthongization:  I",
      "ccr: mind",
      "th stopping: that",
      "r-less after vowel: for"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 4,
    "content": "But I wanted it- I don't wanna get- have it in her mind that- the hate that I have for little boys that she gotta, well I- I don't wanna tell him this.",
    "age": 40,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "But I wanted it. I don't want to get to Heaven in her mind that they hate that I have for Little boys, that she got well, I don't want to tell him this.",
    "ibm_transcription": "but I wanted it I don't want to get have an inner mind it they hate that I have little boys she got it well I don't want to tell me if",
    "amazon_transcription": "but I wanted it. I don't want to get having in their mind. I hate that I have more boys. She had a Well, I don't want to tell him this.",
    "google_transcription": "but I wanted it I don't want to get it to have it in her mind that the hate that I have for little boys that she got a I don't want to tell him this",
    "apple_transcription": "But I want to be I don't want to get it have a name Amanda the hate that I have a little boys",
    "clean_google_wer": 0.2727,
    "clean_ibm_wer": 0.4848,
    "clean_amazon_wer": 0.4545,
    "clean_msft_wer": 0.2727,
    "clean_apple_wer": 0.6667,
    "ddm": 0.1212,
    "avg_wer": 0.4303
  },
  {
    "snippet": "DCB_se1_ag2_f_03_1_3274626_3305744.mp3",
    "wordcount": 92,
    "phon_count": 4,
    "phon_features": [
      "final consonant deletion: like, part",
      "r-less after vowel: for",
      "pin-pen merger: ben's"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 4,
    "content": "But- /like/ when we get off early, of course I can go. But for the most part, Alero's is like, my favorite like, hang out spot. Cause especially in the summer they have the outdoor part, and then at night it doesn't- it's not just like- they have- they turn half of it into like a little, um, club or whatever at night time on some Saturdays and stuff, so. I liked it there. But if I'm gonna sit down and eat somewhere, <sigh> I like Busboys, I also like Ben's next door.",
    "age": 25,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "But. When we get off early of course I'm go, but for the most part aleros like my favorite like hang out spot. 'Cause especially in the summer, they have the outdoor part and then at night. It doesn't. It's not just like they have they turn half of it inside like a little club or whatever at night time on some Saturdays and stuff, so I liked it there. But if I'm gonna sit down to eat somewhere. I like busboys also like Benz next door.",
    "ibm_transcription": "but I've only get off early of course I'm go before the most part a layer of like my favorite like hang out spot because in the summer they have the outdoor par in then and night it does it is not just like they have the time half of it and so like a little %HESITATION club or whatever and night time on some Saturdays and stuff so I liked it there but if I'm gonna sit down to eat somewhere I like busboys also like bins next door",
    "amazon_transcription": "but And when we get off early, of course, I'm go before the most part. Alaric is like my favorite like hangout spot because the sweat in the summer they have the outdoor par and then and night. It doesn't. It's not just like they have the turn half of it, and still like a little club or whatever at night time on some Saturdays and stuff. So I liked it there. But if I'm going to sit down and eat somewhere, I like bus boys also like Ben's next door.",
    "google_transcription": "but when we get off early of course I can go but for the most part alero's like my favorite like hang out spot cuz it's in the summer they have the outdoor part and then at night it doesn't it's not just like they have the turn half of it into like a little club or whatever and nighttime on some Saturdays and stuff so I liked it there but if I'm going to sit down and eat somewhere I like Busboys also like Ben's Next Door",
    "apple_transcription": "But I don't we get up early of course I'm go before the most part the lyrics like my favorite like hangouts but because of that in the summer they have the outdoor part and then at night it doesn't it's not just like they have the time and half of it into like a little arm club or whatever at night time I have some Saturdays off so I like that there but if I'm gonna sit down to eat somewhere I like busboys also like Ben's next-door",
    "clean_google_wer": 0.087,
    "clean_ibm_wer": 0.2826,
    "clean_amazon_wer": 0.1957,
    "clean_msft_wer": 0.087,
    "clean_apple_wer": 0.2826,
    "ddm": 0.0435,
    "avg_wer": 0.187
  },
  {
    "snippet": "DCB_se2_ag2_f_02_1_3279089_3285518.mp3",
    "wordcount": 20,
    "phon_count": 1,
    "phon_features": [
      "CCR: and"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "Mm-hm. And I- I- I honestly- And it's- I have these conversations with him when he's vulnerable to have those conversations.",
    "age": 23,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "And I honestly I had these conversations with him when he's vulnerable to have those conversations.",
    "ibm_transcription": "I honestly in this I had a conversation with him when he's vulnerable to have those conversations",
    "amazon_transcription": "I honestly, I had conversations with him when he's vulnerable to have those conversations.",
    "google_transcription": "I honestly Miss I haven't from a patient with him when he's vulnerable to have those conversations",
    "apple_transcription": "When he's vulnerable to have a conversation",
    "clean_google_wer": 0.45,
    "clean_ibm_wer": 0.4,
    "clean_amazon_wer": 0.35,
    "clean_msft_wer": 0.25,
    "clean_apple_wer": 0.75,
    "ddm": 0.05,
    "avg_wer": 0.44
  },
  {
    "snippet": "PRV_se0_ag1_f_01_2_361659_369799.mp3",
    "wordcount": 29,
    "phon_count": 5,
    "phon_features": [
      "raising of kit vowel: chill",
      "CCR: planned",
      "monopthongization: mahself, I'm",
      "r-less after vowel: mother"
    ],
    "gram_count": 1,
    "gram_features": [
      "copula absence: now they gone"
    ],
    "total_count": 6,
    "content": "Like I'm just gonna chill. Just out there /??/. Have nothing planned. Because I got the house to myself because my mother now they gone to Greensboro. I'm like,",
    "age": 19,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "I'm just going to chill just out there. Have nothing plain 'cause I got the house on myself, because my mother, not a ganza greens, Brown line.",
    "ibm_transcription": "right now I'm just going to see %HESITATION this actually has have meant the plan because I got the house to myself as a mother now they call the great well my",
    "amazon_transcription": "I know I'm just going to chill. Just barely have meant playing because I got the house of myself because my mother, they calls a great round mike.",
    "google_transcription": "I know you just want to see you just after I leave him have meant to plan cuz I got the house to myself with my mother now they gone to Greensboro",
    "apple_transcription": "I know I'm just gonna chill does Abby have meant the plane because I got the house to myself and have a mother not a God the glory",
    "clean_google_wer": 0.5862,
    "clean_ibm_wer": 0.5517,
    "clean_amazon_wer": 0.4483,
    "clean_msft_wer": 0.3793,
    "clean_apple_wer": 0.6207,
    "ddm": 0.2069,
    "avg_wer": 0.5172
  },
  {
    "snippet": "PRV_se0_ag3_m_01_1_2638522_2653973.mp3",
    "wordcount": 17,
    "phon_count": 2,
    "phon_features": [
      "final consonat deletion: would",
      "r-less after vowel: our"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 2,
    "content": "Yep. The years uh, was it forty nine and fifty? And um, I would meet <ts> our music teacher, Mrs /RD-NAME-2/.",
    "age": 64,
    "source": "PRV",
    "gender": "Male",
    "msft_transcription": "Yeah, the years of was 49 and 50. Ann. I would meet. Music teacher Mrs.",
    "ibm_transcription": "yeah two years %HESITATION forty nine and fifty and %HESITATION I would meet a music teacher Mrs",
    "amazon_transcription": "Yeah. The years of 49 50 and ira meat. A music teacher, Mrs.",
    "google_transcription": "yeah the years I was 49 and 50 and I would meet a music teacher mrs.",
    "apple_transcription": "Yeah the years I was 49 and 50 and I would meet our music teacher Mrs.",
    "clean_google_wer": 0.2353,
    "clean_ibm_wer": 0.2941,
    "clean_amazon_wer": 0.4706,
    "clean_msft_wer": 0.2941,
    "clean_apple_wer": 0.1765,
    "ddm": 0.1176,
    "avg_wer": 0.2941
  },
  {
    "snippet": "PRV_se0_ag2_f_03_1_33962_40487.mp3",
    "wordcount": 21,
    "phon_count": 3,
    "phon_features": [
      "final consonant deletion: of",
      "dress vowel raising: dead",
      "-ng to -n: living"
    ],
    "gram_count": 4,
    "gram_features": [
      "copula absence: she dead, my grandaddy gone, my mama she still here, she still living"
    ],
    "total_count": 7,
    "content": "All of 'em- all of 'em- My grandmama she dead, my granddaddy gone, my mama she still here. She still living.",
    "age": 48,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "Album Grandma machine there my Granddad are gone my mama, she still here.",
    "ibm_transcription": "I'll never out of a grandma machine did not granted it down my mom she Steve here live",
    "amazon_transcription": "olive grandmama. She did my granddaddy going. My mom, She's still here. We live in.",
    "google_transcription": "I love my grandma she did my granddaddy going with my mom she still here",
    "apple_transcription": "I'll never out of a grandma machine there my friend ago my mom she still here",
    "clean_google_wer": 0.6667,
    "clean_ibm_wer": 0.8095,
    "clean_amazon_wer": 0.6667,
    "clean_msft_wer": 0.6667,
    "clean_apple_wer": 0.7143,
    "ddm": 0.3333,
    "avg_wer": 0.7048
  },
  {
    "snippet": "PRV_se0_ag3_m_03_1_624766_631311.mp3",
    "wordcount": 14,
    "phon_count": 6,
    "phon_features": [
      "monopthongization: join, I",
      "th stopping: with",
      "haplology: general, general",
      "intersyallbic /r/ deletion: general (overlap), general (overlap)",
      "l vocalization: general (overlap), general (overlap)",
      "th stopping: that's"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 6,
    "content": "/Join in with/ General Mark Clark, I think that's his name. General Mark Clark.",
    "age": 83,
    "source": "PRV",
    "gender": "Male",
    "msft_transcription": "Joining with gentle. Mark Clark I think this is name, John Mark law.",
    "ibm_transcription": "joining with general mark clog up in his name John mark low",
    "amazon_transcription": "John and with Jill. Mark Clark. I think this is a John Mark Clark.",
    "google_transcription": "going in with General Mark Clark I think that's his name John Mark Clark",
    "apple_transcription": "Join in with Joel Mark Clark I think this and I don't know",
    "clean_google_wer": 0.1429,
    "clean_ibm_wer": 0.5714,
    "clean_amazon_wer": 0.5,
    "clean_msft_wer": 0.5,
    "clean_apple_wer": 0.5,
    "ddm": 0.4286,
    "avg_wer": 0.4429
  },
  {
    "snippet": "DCB_se3_ag3_m_01_1_338323_346905.mp3",
    "wordcount": 33,
    "phon_count": 1,
    "phon_features": [
      "CCR: child"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "No actually, I'm a middle child. So I have a older brother, and I have a younger brother. And so I'm dead smack in the middle. Three- my parents had three boys, so.",
    "age": 33,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "No actually I'm a middle child, so I have an older brother and I have a younger brother. And so I'm dead smack in the middle 3 appears at 3:00 boys.",
    "ibm_transcription": "no actually I'm a middle child so I have a older brother and I have a younger brother and so I'm dead smack in the middle three three boys so",
    "amazon_transcription": "No, Actually, I'm a middle child, so I have older brother and I have a younger brother and some dead smack in the middle. Three pairs of three boys, so",
    "google_transcription": "no actually I'm a middle child so I have older brother and I have a younger brother and so I'm dead smack in the middle 3 repairs at 3 boys",
    "apple_transcription": "No actually I'm a middle child so I have a older brother and I have a younger brother and so I'm dead smack in the middle three pairs of three boys",
    "clean_google_wer": 0.1515,
    "clean_ibm_wer": 0.0909,
    "clean_amazon_wer": 0.1818,
    "clean_msft_wer": 0.1818,
    "clean_apple_wer": 0.1212,
    "ddm": 0.0303,
    "avg_wer": 0.1455
  },
  {
    "snippet": "ROC_se0_ag3_f_01_1_1341401_1376200.mp3",
    "wordcount": 89,
    "phon_count": 2,
    "phon_features": [
      "th stopping: both",
      "final consonant deletion (after vowel): be(d)-room"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 2,
    "content": "um, that's basically it. Once they, um, both got jobs, then, you know, they were out on their own, and then they had an apartment on /RD-ADDRESS-2/ then they bought an apa- had an apartment on /RD-ADDRESS-3/ then they bought this house. So they actually bought the house for seventeen thousand dollars. Six bedroom house, three bathrooms, seventeen thousand year- you know, back then. So that was crazy. You- so they payed their house off real quick because she was at Kodak, and he was in construction, so it was basically a steal.",
    "age": 52,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "Home. That's basically and once they both get jobs. Then you know they were out on their own and then they had an apartment on then they bought a had an apartment on then they bought this house, so they actually bought the house for 17,000. 6 bedroom house 3 bathrooms 17,000 years, you know back then. So that was crazy, so they paid their house off real quick because she was at Kodak and he was in construction, so it was basically a steel.",
    "ibm_transcription": "that's basically it once they %HESITATION both got jobs then you know they were out on their own and then they had an apartment on the in the bottom I had an apartment on the name what this house so they actually bought the house for seventeen thousand dollars six bedroom house three bathrooms seventeen thousand years you know back then so that was crazy so they pay their house off real quick because she was at Kodak a he was in construction so was basically steal",
    "amazon_transcription": "home. That's basically it. Once they, um, both got jobs. Then you know, they were out on their own, and then they had an apartment on. Then they bought it, had an apartment on. Then they bought this house. So they actually bought the house for $17,000 6 bedroom house, three bathrooms, 17,000 years. You know, back then. So that was crazy. So they paid their house off real quick because she was that Kodak, and he was in construction, so it was basically a steal.",
    "google_transcription": "that's basically at once they both got jobs then you know they were out on their own and then they had an apartment then they bought it had an apartment and then they bought this house so they actually bought the house for $17,000 6 bedroom house three bathrooms 17000 you know back then so that was crazy so they paid their house off real quick because she was at Kodak and he was in construction so it was basically it still",
    "apple_transcription": "That's basically yet once they both got jobs then you know they were out on their own and then they had an apartment and then the bottom I had an apartment on the new bought this house so they actually bought the house for $17,000.06 bedroom house three bathrooms 17,000 year you know back then though that was crazy so they pay their house off real quick because she was at Kodiak and he was in construction so it was basically asleep",
    "clean_google_wer": 0.1348,
    "clean_ibm_wer": 0.1573,
    "clean_amazon_wer": 0.1124,
    "clean_msft_wer": 0.1348,
    "clean_apple_wer": 0.191,
    "ddm": 0.0225,
    "avg_wer": 0.1461
  },
  {
    "snippet": "PRV_se0_ag3_m_02_1_11595_17685.mp3",
    "wordcount": 28,
    "phon_count": 6,
    "phon_features": [
      "l vocalization: well, actually",
      "-ng as -n: working,getting, getting",
      "final consonant deletion: bad"
    ],
    "gram_count": 3,
    "gram_features": [
      "aint: aint even working",
      "copula absence: his knees getting bad, he getting ready"
    ],
    "total_count": 9,
    "content": "Yeah, well actually he ain't even working right now, his knee's getting bad right. He getting ready to go to the hospital to get his knee s- o-",
    "age": 78,
    "source": "PRV",
    "gender": "Male",
    "msft_transcription": "Oh yeah, it would actually even working on Negan in bed to get ready to go to the hospital to get diesel?",
    "ibm_transcription": "we're at the age of the work that needs in advance here to go to hospital to give the",
    "amazon_transcription": "Yeah, but you would not need getting bad. I get to go to hospital.",
    "google_transcription": "leaving work. Need to get in bed I got to get ready to go to the hospital again.",
    "apple_transcription": "Yeah I would ask Eva would not need to get a bed that he did go to hospital again",
    "clean_google_wer": 0.7857,
    "clean_ibm_wer": 0.8214,
    "clean_amazon_wer": 0.75,
    "clean_msft_wer": 0.6429,
    "clean_apple_wer": 0.8214,
    "ddm": 0.3214,
    "avg_wer": 0.7643
  },
  {
    "snippet": "DCB_se1_ag3_m_02_1_683270_732731.mp3",
    "wordcount": 132,
    "phon_count": 11,
    "phon_features": [
      "-ng as -n: letting, telling, going, saying, going",
      "monopthongization: I, time",
      "raising of dress vowel: get",
      "th fronting: the, the, that"
    ],
    "gram_count": 3,
    "gram_features": [
      "lack of plural -s: doors",
      "habitual be: be being",
      "copula absence: we able to"
    ],
    "total_count": 14,
    "content": "Like as- as far as you know letting the government just do anything that they wanna do basically. <laugh> Cause nobody's really there to fight for us or, you know, actually see what really goes /down/ behind closed doors. I mean, yeah we have people on television telling us one thing but they could just be being a puppet for the next man. <clears throat> But we really wouldn't know until we able to fight for the cause and get what we want right inf- in- in front of us, so it'll be shown instead of going off what everybody else is saying and it's going the other way but the whole plan was to go the other way the whole time. But you'll never be able to know that until you open your eyes.",
    "age": 36,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "I guess as far as you know, letting the government just do anything. Do they want to do basically? 'Cause nobody is really there to fight 4, so you know actually see what really goes doing behind closed doors. I mean here we have people on television, telling us one thing, but they could just be being a puppet for the next month. We really won't know until we able to fight for the calls. In Gable, we went right in front of us, so it'll be shown instead of going off with everybody else is saying. And is going to other way, but the whole plan would go to other way to hold her. You'll never be able to know that. Yeah.",
    "ibm_transcription": "as far as you know and then the government just do anything did I want to do basically because nobody really there to fight for school you know actually see what really goes on behind closed doors yeah we have people kind of it is kind of one thing but they could just be be a pup afforded maximize where we really wouldn't know until we able to fight for the calls we get what we want right if the frontal sort of the show instead of going off what everybody else is saying in this corner of the way but the whole planet would go the other way to hold that should never be able to notice it's very open yeah",
    "amazon_transcription": "I guess, as far as you know, letting the government just do anything that they want to do. Basically. Hey, because nobody really there too Fire force or, you know, actually see what really goes on behind closed door. Yeah, we have people on television. One thing. But they could just be being a puppet for the next man. Wait, really wouldn't know until we're able to fight for the calls, get what we want right in front of us. Sort of be show instead of going off when everybody else is saying and it's going other way. But the whole plan was going the other way to hold that, you know, be able to know that. So you open yet?",
    "google_transcription": "as far as you know letting the government just do anything did I want to do basically cuz nobody's really there to fight for so you know actually see what really goes doing behind closed doors I mean yeah we have people on television to tell him that one thing but they could just be being a puppet for the next man but we really wouldn't know until we able to fight for the car and get what we want right in front of us so it'll be shown instead of going off with everybody else aside and it's going the other way but the whole plan was going by the way the hoes at should never be able to know that until you open your eyes",
    "apple_transcription": "As far as you know and then go to the majors do anything that I want to do mesa grill because mobile road beer to fight for you no actually she would really go to one thing but they could just be being a puppet for next month we really won't know until we are able to fight with calls and get what we want right of the front of the show going off on everybody else's name and is gone either way but hole do you know where it's at",
    "clean_google_wer": 0.197,
    "clean_ibm_wer": 0.4394,
    "clean_amazon_wer": 0.303,
    "clean_msft_wer": 0.2803,
    "clean_apple_wer": 0.6591,
    "ddm": 0.1061,
    "avg_wer": 0.3758
  },
  {
    "snippet": "PRV_se0_ag3_m_01_1_1059096_1070592.mp3",
    "wordcount": 17,
    "phon_count": 1,
    "phon_features": [
      "metathesis: acorn"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "If you don't uh, nurture it, uh, take care of it, it will still produce um, a small defected /acron/.",
    "age": 64,
    "source": "PRV",
    "gender": "Male",
    "msft_transcription": "If you don't nurture it. Take care of it, it will still produce. A small defected Aiken.",
    "ibm_transcription": "if you don't %HESITATION nurture it %HESITATION took care of it it was still produce %HESITATION a small defected taken",
    "amazon_transcription": "if you don't nurture it, uh, take care of it. It was deal produced? No, a small defected a Quran.",
    "google_transcription": "if you don't nurture it take care of it it will still produce small defected acre",
    "apple_transcription": "If you don't nurture it and take care of it it will still produce on a small defect it",
    "clean_google_wer": 0.1176,
    "clean_ibm_wer": 0.1765,
    "clean_amazon_wer": 0.3529,
    "clean_msft_wer": 0.0588,
    "clean_apple_wer": 0.2353,
    "ddm": 0.0588,
    "avg_wer": 0.1882
  },
  {
    "snippet": "ROC_se0_ag1_m_03_1_2810906_2818522.mp3",
    "wordcount": 22,
    "phon_count": 3,
    "phon_features": [
      "th stopping: then, that’s, thats"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 3,
    "content": "or- or way that we say things. Um, and that's the- that's- to me, that's what- that's what s- Geechee is to me.",
    "age": 25,
    "source": "ROC",
    "gender": "Male",
    "msft_transcription": "Or our way that we say things then that's the death to me. That's what that's what guy she is to me.",
    "ibm_transcription": "or at our weight we say things %HESITATION and then ask the desk to me that's what vessels DC is to me",
    "amazon_transcription": "guard our way that we say things. Um, then that's the desk to me. That's what that's what g she is to me.",
    "google_transcription": "our way that we say things then that's the best to me that's what that's what she is to me",
    "apple_transcription": "Toward our way that we say things and then ask the desk to me that's what that's what the fee is to me",
    "clean_google_wer": 0.2727,
    "clean_ibm_wer": 0.4545,
    "clean_amazon_wer": 0.2727,
    "clean_msft_wer": 0.2273,
    "clean_apple_wer": 0.3182,
    "ddm": 0.1364,
    "avg_wer": 0.3091
  },
  {
    "snippet": "PRV_se0_ag3_f_02_2_889873_895622.mp3",
    "wordcount": 17,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "N- You know where the park- where um, the trail began, after you come off the Tar River.",
    "age": 40,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "You know where the park for the trail began after you come out to Tar River.",
    "ibm_transcription": "you know for the park for %HESITATION the trail began after you come out to tar river",
    "amazon_transcription": "you know where the park for? Um, the trail began after you come out the Tar River.",
    "google_transcription": "you know where the park for the trail begin after you come out to Tar River",
    "apple_transcription": "You know where the park for on the trail began after you come out to Torio",
    "clean_google_wer": 0.2941,
    "clean_ibm_wer": 0.2941,
    "clean_amazon_wer": 0.1765,
    "clean_msft_wer": 0.2353,
    "clean_apple_wer": 0.4118,
    "ddm": 0,
    "avg_wer": 0.2824
  },
  {
    "snippet": "PRV_se0_ag3_m_01_1_2244102_2252630.mp3",
    "wordcount": 28,
    "phon_count": 4,
    "phon_features": [
      "monopthongization: time",
      "th stopping: that",
      "-NG as -n: standing",
      "final consonant deletion: sidewalk"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 4,
    "content": "And when he cut it just in time, it wrapped around that car- the car's wheel, and we were standing on it- on the line, on the sidewalk.",
    "age": 64,
    "source": "PRV",
    "gender": "Male",
    "msft_transcription": "And when you cut. It just in time. It wrapped around that car cars wheel and we were standing on it on the line on the side.",
    "ibm_transcription": "and when you cut it just in time it wrapped around a car cars we'll and we will stand in known on the line on the sidewalk",
    "amazon_transcription": "and when you cut it just in time, it wrapped around their car cars wheel and we were standing on it on the line on this one.",
    "google_transcription": "and when you cut it just in time it wrapped around that car car squeal and we were standing on it on the line on the side",
    "apple_transcription": "And when you cut it just in time it wrapped around the car cart wheel and we were standing on it on the line on the side",
    "clean_google_wer": 0.1786,
    "clean_ibm_wer": 0.2857,
    "clean_amazon_wer": 0.1786,
    "clean_msft_wer": 0.1071,
    "clean_apple_wer": 0.1786,
    "ddm": 0.1429,
    "avg_wer": 0.1857
  },
  {
    "snippet": "DCB_se2_ag3_m_01_1_3230540_3246025.mp3",
    "wordcount": 33,
    "phon_count": 1,
    "phon_features": [
      "ccr: recognized"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "It um- one of the things that I- I recognized was uh, an increase in their pride and their participation in the Club. Um, and it t- it took me until now to really see that.",
    "age": 35,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "It. One of the things that are recognized was. An increase in their pride in their participation in the club. And it took me until now to really see that.",
    "ibm_transcription": "and %HESITATION one thing that our our recognize or %HESITATION an increase in their pride in their participation in the club %HESITATION it took me until now to really see that",
    "amazon_transcription": "it one of things that are recognized, wass Ah, an increase in their pride in their participation in the club. And it took me. So now to really see that",
    "google_transcription": "what are things that are recognized was an increase in their pride and their participation in the club and it took me until now to really see the",
    "apple_transcription": "What are things that are a recognize words are an increase in their pride and their participation in the club on exit took me until now to really see that",
    "clean_google_wer": 0.2727,
    "clean_ibm_wer": 0.3636,
    "clean_amazon_wer": 0.2727,
    "clean_msft_wer": 0.1515,
    "clean_apple_wer": 0.3939,
    "ddm": 0.0303,
    "avg_wer": 0.2909
  },
  {
    "snippet": "DCB_se3_ag4_m_02_1_330093_349820.mp3",
    "wordcount": 57,
    "phon_count": 1,
    "phon_features": [
      "monopthongization: I"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "to take the test for /RD-SCHOOL-2/. And I did. Initially I wasn't accepted, and, um, but they asked me to come back for a second interview and I ultimately was accepted, so I- I- I went there. My sister went to Coolidge High School, which was the high school in our neighborhood in- in Tacoma Park section of DC.",
    "age": 66,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "To take the test. Anne I did initially I wasn't accepted an but they asked me to come back for a second interview and ultimately was accepted so I went there. My sister went to Coolidge High School, which was the high school in our neighborhood and in Takoma Park section.",
    "ibm_transcription": "to take the test with and I did a initially I wasn't accepted and %HESITATION but they asked me to come by for a second interview and ultimately was accepted so I I I went there my sister went to cool is high school which was the high school in our neighborhood and call park section of the city",
    "amazon_transcription": "to take the test with you. And I did. Initially, I wasn't accepted. And, um, but they asked me to come back for a second interview and ultimately was accepted. So I I I went there. My sister went to Coolidge High School, which was the high school in our neighborhood and Tacoma Park section of the city.",
    "google_transcription": "to take the test and I did initially I wasn't accepted and but they asked me to come back for a second interview and ultimately was accepted so I went there my sister went to Coolidge High School which was the high school in our neighborhood in Takoma Park section of DC",
    "apple_transcription": "To take the test and I did initially I wasn't excepted and Dan but they asked me to come back for second interview and ultimately was excepted so I I went there my sister went to Coolidge high school which was the high school in our neighborhood and in the car park section of this",
    "clean_google_wer": 0.1053,
    "clean_ibm_wer": 0.193,
    "clean_amazon_wer": 0.1228,
    "clean_msft_wer": 0.1754,
    "clean_apple_wer": 0.193,
    "ddm": 0.0175,
    "avg_wer": 0.1579
  },
  {
    "snippet": "PRV_se0_ag3_m_02_2_1033958_1042796.mp3",
    "wordcount": 35,
    "phon_count": 3,
    "phon_features": [
      "CCR: and",
      "th fronting: they, these"
    ],
    "gram_count": 2,
    "gram_features": [
      "verb stem as past tense: come in, a lot of people don't know"
    ],
    "total_count": 5,
    "content": "And then the water come in, washed it out and a lot of people don't know which way to go to get the stuff back. See a lot of them people, they got these- got",
    "age": 78,
    "source": "PRV",
    "gender": "Male",
    "msft_transcription": "And then the water coming out washed it out and. A lot of people don't know who is the way to go get the stuff back see a lot of people they got the.",
    "ibm_transcription": "and in the world to come and washed it out a lot of people don't know which way to go get the stuff back so a lot of people they got the got",
    "amazon_transcription": "and in the world coming washed it out. A lot of people don't know which way to go. Get the stuff back. See a lot of people. They got the guy.",
    "google_transcription": "and then the water came and washed it out and a lot of people don't know which way to go get the stuff back sell all them people they got these",
    "apple_transcription": "I didn't want to commit washed it out and a lot of people don't know which way to go get the stuff back to a lot of people they got these",
    "clean_google_wer": 0.2286,
    "clean_ibm_wer": 0.2571,
    "clean_amazon_wer": 0.2571,
    "clean_msft_wer": 0.2571,
    "clean_apple_wer": 0.2857,
    "ddm": 0.1429,
    "avg_wer": 0.2571
  },
  {
    "snippet": "ROC_se0_ag3_f_02_1_2579639_2587011.mp3",
    "wordcount": 16,
    "phon_count": 2,
    "phon_features": [
      "final consonant deletion: could",
      "r-less after vowel: remember"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 2,
    "content": "I wish I could remember where he was before he moved to Thurston. <ts> But I- that's-",
    "age": 80,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "I wish I could remember where he was before he moved to Thurston. But at this.",
    "ibm_transcription": "exactly remember where he was before he moved there's but at this",
    "amazon_transcription": "How is Jack? Remember where he Waas before he moved Thurston? But this",
    "google_transcription": "I wish I could remember where he was before he moved to Thurston butt abscess",
    "apple_transcription": "Who is Zach remember where he was before he moved to Thursday but at this",
    "clean_google_wer": 0.1875,
    "clean_ibm_wer": 0.5,
    "clean_amazon_wer": 0.5,
    "clean_msft_wer": 0.125,
    "clean_apple_wer": 0.4375,
    "ddm": 0.125,
    "avg_wer": 0.35
  },
  {
    "snippet": "PRV_se0_ag3_f_02_2_1965995_1971167.mp3",
    "wordcount": 17,
    "phon_count": 3,
    "phon_features": [
      "-ng to n: getting",
      "r-less after vowel: traveler, trailer"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 3,
    "content": "And it was really really getting ready to fall in, and they had a traveler- travel trailer",
    "age": 40,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "And it was really, really getting rid of fall in and they had a trailer travel trailer.",
    "ibm_transcription": "and it was really really getting rid of pollen and they had a trial of travel trailer",
    "amazon_transcription": "and it was really, really getting rid of fall in and they had a trail of travel training.",
    "google_transcription": "and it was really really getting rid of fall in and they have a trailer travel trailer",
    "apple_transcription": "And it was really really getting rid of Farland and they have a trailer travel trailer",
    "clean_google_wer": 0.2353,
    "clean_ibm_wer": 0.3529,
    "clean_amazon_wer": 0.2941,
    "clean_msft_wer": 0.1765,
    "clean_apple_wer": 0.3529,
    "ddm": 0.1765,
    "avg_wer": 0.2824
  },
  {
    "snippet": "ROC_se0_ag1_m_01_1_3413916_3427781.mp3",
    "wordcount": 43,
    "phon_count": 3,
    "phon_features": [
      "intial d deletion: I don't know",
      "-ng to -n: speaking, speaking"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 3,
    "content": "I don't know. Like, I think to me, everybody in Rochester pretty much be speaking English, or they're not from Rochester. So that's understandable that they have an accent, or like, English is not their first speaking language. Cause, I mean we have,",
    "age": 24,
    "source": "ROC",
    "gender": "Male",
    "msft_transcription": "No no like I think to me, everybody in Rochester. We pretty much who speak in English or they're not from Rochester. So that's understandable, then they have the accent over like English is not their first speaking language 'cause I mean, we have.",
    "ibm_transcription": "I don't know I think to me everybody writes is it pretty much in English or in there not from Rochester so that's understandable the and they have access or like English now there for speaking language because I mean we have",
    "amazon_transcription": "I don't know. Like I think to me, everybody rats has been pretty much they're speaking English or they're not from Rochester. So that's understandable that they have excited or like English, not there for speaking language, because, I mean we have.",
    "google_transcription": "I don't know like I think everybody around children pretty much they speak in English or they're not from Rochester so that's understandable that they have accident or like English now their first speaking language cuz I mean we have",
    "apple_transcription": "I don't know why I think to me everybody right as you pretty much speak in English or in there not from Rochester so that's understandable that you may have asked our light English is not there for speaking language because I mean we have",
    "clean_google_wer": 0.2558,
    "clean_ibm_wer": 0.3953,
    "clean_amazon_wer": 0.2326,
    "clean_msft_wer": 0.2326,
    "clean_apple_wer": 0.3953,
    "ddm": 0.0698,
    "avg_wer": 0.3023
  },
  {
    "snippet": "DCB_se2_ag4_f_02_1_678229_691711.mp3",
    "use": true,
    "attribution": "coraal",
    "wordcount": 40,
    "phon_count": 1,
    "phon_features": [
      "th fronting: the"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "annotations": [
      {
        "timeStart": 13.118953809160228,
        "timeEnd": 13.482018140589568,
        "timeCtxStart": 12.384678357138766,
        "timeCtxEnd": 13.482018140589568,
        "start": 175,
        "end": 178,
        "content": "Syllable initial fricative stopping"
      }
    ],
    "content": "grow older, we get tarker. So I was extremely light when I was a child. And very skinny. And um, so I was like an outcast because I was made fun of because I was the white girl at the school.",
    "real_transcription": "Grow older, we get darker. So I was extremely light when I was a child and very skinny. And so I was like an outcast because I was made fun of because I was the white girl at the school.",
    "asr_transcription": "World older, we get darker. So I was extremely light when I was a child and very skinny. And so I was like an outcast because I was made fun of because I was the white girl at the school.",
    "age": 51,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "World older we get darker so I was extremely light. When I was a child and very skinny and so I was like an outcast because I was made fun of because I was the white girl at the school.",
    "ibm_transcription": "world older we get darker so I was extremely light when I was a child and very skinny and %HESITATION so I was like an outcast because I was made fun of because I was the white girl at the school",
    "amazon_transcription": "world older, we get darker. So I was extremely light when I was a child and very skinny. And so I was like an outcast because I was made fun of because I was the white girl at the school.",
    "google_transcription": "Coral older we get darker so I was extremely light when I was a child and very skinny and so I was like an outcast because I was made fun of because I was the white girl at the school",
    "apple_transcription": "We're older we get darker so I was extremely late when I was a child and very skinny and so I was like an outcast because I was made fun of because I was the white girl at the school",
    "clean_google_wer": 0.025,
    "clean_ibm_wer": 0.025,
    "clean_amazon_wer": 0.025,
    "clean_msft_wer": 0.025,
    "clean_apple_wer": 0.05,
    "ddm": 0.025,
    "avg_wer": 0.03
  },
  {
    "snippet": "ROC_se0_ag1_f_01_1_1786399_1804459.mp3",
    "wordcount": 61,
    "phon_count": 5,
    "phon_features": [
      "monopthongization: I'm, I'm, I'm,",
      "-ng as -n: driving",
      "medial /d/ deletion: somebody"
    ],
    "gram_count": 1,
    "gram_features": [
      "verb stem as past tense: worry"
    ],
    "total_count": 6,
    "content": "I'm- I'm not worry about a confederate (laughing) flag flying, you know, across my face or driving behind somebody and they spit on my car because I'm black, you know. /But the-/ and my brother has told me about things that have happened just because he's black, so. I don't worry about that here, so. I'm thankful for that, I should say.",
    "age": 26,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "I'm I'm not worry about it. Confederate flag flying you know across my face or driving behind somebody and they spit on my car because I'm black you know, my brothers told me about things that have happened just because he's black so I don't worry about that here so I'm thankful for dash I say.",
    "ibm_transcription": "and I'm not worry about it Confederate flag flying you know crossed my face or driving behind somebody needs to bid on my car because I'm black you know my day my brothers told me about things that have happened just because he's black so I don't worry about that here so I'm thankful for dash I say",
    "amazon_transcription": "I'm not worried about a Confederate flag flying, you know, across my face were driving behind somebody and they spit on my car because I'm black. You know what my brother has told me about things that have happened just because he's black? So don't worry about that here, So I'm thinking over Dash, okay?",
    "google_transcription": "I'm not worried about a Confederate flag flying you know across my face or driving behind somebody and they spit on my car because I'm black you know my brother is told me about things that have happened just because he's black so I don't worry about that here so I'm thankful for that",
    "apple_transcription": "I'm not I'm not worry about it come for her at flag flying across a fort driving by a bit on my car because I'm black you know my brother told me about things that have happened it because he's black so I don't worry about that here so I'm thankful for that if they",
    "clean_google_wer": 0.1475,
    "clean_ibm_wer": 0.2131,
    "clean_amazon_wer": 0.2131,
    "clean_msft_wer": 0.1311,
    "clean_apple_wer": 0.3934,
    "ddm": 0.0984,
    "avg_wer": 0.2197
  },
  {
    "snippet": "DCB_se2_ag3_m_01_1_4702217_4724189.mp3",
    "wordcount": 74,
    "phon_count": 1,
    "phon_features": [
      "th fronting: the"
    ],
    "gram_count": 1,
    "gram_features": [
      "is-are leveling: floodgates is"
    ],
    "total_count": 2,
    "content": "What's wrong? And it's like, n- she can say two words and the flood gates is like oh my god. You know. And so, she passed that, I think along to me. And when these young people come in, and- like you said, you see it. It's in your eyes. It's in your body language. It's in how you set down in the chair as opposed to how you set in the chair yesterday.",
    "age": 35,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "And it's like she can say 2 words and the floodgates is like. You know, and so she passed at, I think along to Maine when these young people come in. And like you said you see it is in your eyes. It's in your body language isn't how you set down in the chair as opposed to how you sit in a chair yesterday.",
    "ibm_transcription": "and it's like she is a two word from the flood gates of like %HESITATION %HESITATION you know and so she passed that I think along to me and when these young people come in and like you said you see it is in your eyes it's in your body language is somehow you set down in the chair as opposed to how you said that she %HESITATION yesterday",
    "amazon_transcription": "was wrong, and it's like she could say two words and the floodgates are over, you know? And so she passed that I think along to me, I wouldn't We will come in and like you said, You see, it's in your eyes, it's in your body Language isn't how you set down in the chair as opposed to how you sit in a chair yesterday.",
    "google_transcription": "what's wrong and it's like she can say two words and the floodgates you know and so she passed that I think along to me and when these young people come in and like you said you see it's in your eyes it's in your body language and somehow you set down in the chair as opposed to how you sit in the chair yesterday",
    "apple_transcription": "What's wrong and it's like she can say two words and the floodgates of my overflow you know and so she passed it I think a lot to me that one reason people come in and like you said you see it it's in your eyes it's in your body language is and how you sit down in the chair as a post are you set a chair yesterday",
    "clean_google_wer": 0.1757,
    "clean_ibm_wer": 0.2568,
    "clean_amazon_wer": 0.2703,
    "clean_msft_wer": 0.2027,
    "clean_apple_wer": 0.2973,
    "ddm": 0.027,
    "avg_wer": 0.2405
  },
  {
    "snippet": "PRV_se0_ag3_f_01_2_488476_509921.mp3",
    "wordcount": 45,
    "phon_count": 9,
    "phon_features": [
      "INTERsylabbic /r/ vocalizaiton: ordeal, ward, crying",
      "-ng as -n: seeing",
      "th stopping: that, this,the",
      "monopthongization: soil",
      "r-less after vowel: after"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 9,
    "content": "That was a- that was a real sad ordeal. Can you imagine seeing one of the citizens in your ward on their knees, face down in the Earth under the condition that this soil was in after the flood boohooing and crying for their losses?",
    "age": 61,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "That was that was a real sad audio Can you imagine seeing one of the citizens in your Ward? On their knees. Face down in the Earth. Under condition that this soul was in after the flood. Boo Hoo and crying for their losses.",
    "ibm_transcription": "that was a that was a real say at all deal can you imagine see and one of the citizens in your will work on their knees face down in the earth under the condition that this solo with and after the flood both women cried for their losses",
    "amazon_transcription": "That was a That was a really sad all deal. Can you imagine seeing one of the citizens in your ward on their knees, face down in the earth on the condition that this soul was in after the flood? Boo hoo, in a crime for their losses?",
    "google_transcription": "that was a that was a real sad all deal can you imagine send one of the citizens in your ward on their knees face down in the earth under the condition that this song was in After the flood boohoo and crying for their losses",
    "apple_transcription": "That was him that was a real say it all deal can you imagine saying one of the citizens in your ward on their knees face down in the earth under the condition that this solo was an app of the fluid boo-hoo and cry for their losses",
    "clean_google_wer": 0.1111,
    "clean_ibm_wer": 0.3111,
    "clean_amazon_wer": 0.2222,
    "clean_msft_wer": 0.1333,
    "clean_apple_wer": 0.3111,
    "ddm": 0.2,
    "avg_wer": 0.2178
  },
  {
    "snippet": "DCB_se3_ag3_m_01_1_144766_157246.mp3",
    "wordcount": 25,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "Um, my mom and dad are from Mississippi. So my father was born in uh, Hattiesburg, Mississippi, and my mother is from Smith- Smithville, Mississippi, the farmlands.",
    "age": 33,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "My mom and dad are from Mississippi, so my father was born in Hattisburg, Mississippi. And my mother is from Smith Smithville, Ms. Farmleigh.",
    "ibm_transcription": "my mom and dad from Mississippi so my father was born in Hattiesburg Mississippi and my mother is from Smith Smithville Mississippi farmland",
    "amazon_transcription": "Amama Data from Mississippi. My father was born in Hattiesburg, Mississippi, and my mother is from Smith Smithville, Mississippi Farmland.",
    "google_transcription": "my mom and dad from Mississippi. My father was born in Hattiesburg Mississippi and my mother is from Smith Smithville Mississippi farmland",
    "apple_transcription": "My mom and dad for Mississippi to my father was born in Hattiesburg Mississippi and my mother is from Smith Smithville Mississippi farmer",
    "clean_google_wer": 0.16,
    "clean_ibm_wer": 0.12,
    "clean_amazon_wer": 0.32,
    "clean_msft_wer": 0.12,
    "clean_apple_wer": 0.2,
    "ddm": 0,
    "avg_wer": 0.184
  },
  {
    "snippet": "PRV_se0_ag3_f_04_3_1432645_1439701.mp3",
    "wordcount": 19,
    "phon_count": 6,
    "phon_features": [
      "th stopping: there, then",
      "r-less after vowel: far",
      "final consonant deletion: it",
      "ccr (neg): wont"
    ],
    "gram_count": 1,
    "gram_features": [
      "existential it: it wont far from it"
    ],
    "total_count": 7,
    "content": "And she flew up there. And I know she- if she wont eighty then it wont far from it.",
    "age": 76,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "And she blew up there and I know she is. She won't eat. It then it won't **** from.",
    "ibm_transcription": "room that day and I know she is she will aid in me and I won't well from",
    "amazon_transcription": "and she ruler there. And I know See if she won't wait a day and it won't be from",
    "google_transcription": "and I know she is she going to eat it then it won't buffer",
    "apple_transcription": "Actually wrote that day and I love you and she won't won't",
    "clean_google_wer": 0.6842,
    "clean_ibm_wer": 0.7368,
    "clean_amazon_wer": 0.4737,
    "clean_msft_wer": 0.3158,
    "clean_apple_wer": 0.7368,
    "ddm": 0.3684,
    "avg_wer": 0.5895
  },
  {
    "snippet": "ROC_se0_ag2_f_03_1_2571104_2580940.mp3",
    "wordcount": 18,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "<ts> Um, activism. You know, I see /RD-NAME-3/'s group. I see, um, the group B-L-A-K- C-K Um, BLACK.",
    "age": 39,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "Hum. Activism you know, I see group I see the group BLAKCK Black.",
    "ibm_transcription": "activism you know I see it group I see %HESITATION the group B. L. A. K. C. K. %HESITATION black",
    "amazon_transcription": "um, activism, you know, I see group. I see. Um, the Group B L a c k black.",
    "google_transcription": "activism and I see group I see the group b l a k c k black",
    "apple_transcription": "I am activism and I see group I see the group BLAKACK black",
    "clean_google_wer": 0.1667,
    "clean_ibm_wer": 0.0556,
    "clean_amazon_wer": 0.1111,
    "clean_msft_wer": 0.4444,
    "clean_apple_wer": 0.6111,
    "ddm": 0,
    "avg_wer": 0.2778
  },
  {
    "snippet": "ROC_se0_ag2_f_02_1_2165127_2184916.mp3",
    "wordcount": 61,
    "phon_count": 1,
    "phon_features": [
      "fill-feel merger: feel"
    ],
    "gram_count": 1,
    "gram_features": [
      "aint preverbal: she aint going there"
    ],
    "total_count": 2,
    "content": "I am not. And I feel like if you are an involved parent with your kid's education, they're gonna be successful wherever they are. It's just the element and the environment that I want changed. Like, in /RD-SCHOOL-3/ they're getting kicked out of their building for her sixth grade year. Who knows, like if they're at Franklin, she ain't going there.",
    "age": 32,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "And I feel like. If you are an involved parent with your kids education and they're going to be successful. Wherever they are is just the element in the environment that I want changed like in there, getting kicked out of their building for her 6th grade year? Who knows like that there are Franklin, she ain't going there.",
    "ibm_transcription": "I am not and I feel like if you are involved clear it with your kids education they're going to be successful wherever they are it's just the element in them vitamins that I want change like in there getting kicked out of their building for her sixth grade year who knows like their I frankly sitting on their",
    "amazon_transcription": "I'm not, and I feel like if you are an involved parent would be a kid's education and they're going to be successful wherever they are. Just the element in the environment that I want changed. Like they're getting kicked out of their building for her sixth grade year. Who knows like that there are Franklin Shing going there?",
    "google_transcription": "I'm not and I feel like if you are an evolved parents when get his education they're going to be successful wherever they are is just the element in the environment that I want change so I can they're getting kicked out of their building for her 6th grade year",
    "apple_transcription": "I am not and I feel like if you are in a ball player it will give his education and they're going to be successful wherever they are it's just the element in them Bierman that I want changed their building for her six grade year who knows like",
    "clean_google_wer": 0.3934,
    "clean_ibm_wer": 0.2623,
    "clean_amazon_wer": 0.2295,
    "clean_msft_wer": 0.1639,
    "clean_apple_wer": 0.459,
    "ddm": 0.0328,
    "avg_wer": 0.3016
  },
  {
    "snippet": "DCB_se2_ag3_m_01_1_4416802_4423275.mp3",
    "wordcount": 9,
    "phon_count": 1,
    "phon_features": [
      "th stopping: that"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "Um, moments that made you laugh. Like that gut wrenching",
    "age": 35,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "Moments that. Made you laugh like that gut wrenching.",
    "ibm_transcription": "moments that made you laugh like that gut wrenching",
    "amazon_transcription": "Yeah. Moments that made you laugh like that. Good, wrenching.",
    "google_transcription": "moments that made you laugh like that gut-wrenching",
    "apple_transcription": "Moments that made you laugh like that got wrenching",
    "clean_google_wer": 0,
    "clean_ibm_wer": 0,
    "clean_amazon_wer": 0.2222,
    "clean_msft_wer": 0,
    "clean_apple_wer": 0.1111,
    "ddm": 0.1111,
    "avg_wer": 0.0667
  },
  {
    "snippet": "PRV_se0_ag3_f_04_1_21942_34079.mp3",
    "wordcount": 25,
    "phon_count": 8,
    "phon_features": [
      "th stopping: then, the, something",
      "l vocalizaition: school",
      "unstressed intial syllable deletion: around",
      "CCR: left",
      "fricative stopping: eleven",
      "final consonant deletion: service"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 8,
    "content": "And then after the service, we went on into Sunday School. And around ten thirty, maybe something /eleven/, we left and went to Saint Luke",
    "age": 76,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "Indian after the service window in the Sunday school. An round came 3rd and maybe something together, we left and went to Saint Louis.",
    "ibm_transcription": "Andy and there it is they're moving now in the center of and round can there to maybe thought and they have a we left and went to St new",
    "amazon_transcription": "ending in after the service with, you know, in the Sunday school around can't There's maybe something endeavor. We live with it saying I do.",
    "google_transcription": "Andy and after the service is Reno in the Sunday school and around 10:32 maybe something the devil we left and went to",
    "apple_transcription": "Andy and they're necessary right now in the sun if you around 1030 to Mavis out in the Devil went to Saint",
    "clean_google_wer": 0.44,
    "clean_ibm_wer": 0.84,
    "clean_amazon_wer": 0.68,
    "clean_msft_wer": 0.52,
    "clean_apple_wer": 0.84,
    "ddm": 0.32,
    "avg_wer": 0.664
  },
  {
    "snippet": "ROC_se0_ag1_m_01_1_3459295_3468601.mp3",
    "wordcount": 18,
    "phon_count": 1,
    "phon_features": [
      "final consonant deletion: with"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "But it's I think with Rochester it's more so like, because of what our city gets portrayed as.",
    "age": 24,
    "source": "ROC",
    "gender": "Male",
    "msft_transcription": "That is I think would Rochester is some more so like. Because of what our city gets portrayed as.",
    "ibm_transcription": "it is I think what righteous here is some more so like because of whatever seeding is portrayed as",
    "amazon_transcription": "What is? I think what Rochester is more so like, uh, because of what our sitting is portrayed as.",
    "google_transcription": "what is I think we're Rochester is some more so like because of what our city gets portrayed as",
    "apple_transcription": "What is I think would Rochester is more so late because of what our seating is pretrade as",
    "clean_google_wer": 0.2778,
    "clean_ibm_wer": 0.6111,
    "clean_amazon_wer": 0.3333,
    "clean_msft_wer": 0.2778,
    "clean_apple_wer": 0.4444,
    "ddm": 0.0556,
    "avg_wer": 0.3889
  },
  {
    "snippet": "DCB_se3_ag4_f_02_1_3624332_3665194.mp3",
    "wordcount": 99,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "I have been so- That really just- The only time that I do that type of stuff- I mean I really wanna do better at that, and um- is when people come to town. Now I- You know, and I have a- And as I've gotten older, I definitely- You know, that's definitely on m- my to do list. Um, and then- You know, there's certain things that I even forgot existed, like the Frederick Douglass house is like, right in Anacostia, up on the hill, and I had totally forgotten about that until somebody mentioned- Did you tell me that?",
    "age": 54,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "So that really just. The only time that I do that type of stuff I mean, I really want to do better at that an. Is when people come to town? Now. You know, and I have. And as I've gotten older I'd definitely you know that's definitely on my To Do List. And then you know, there's certain things that even forgot existed like the Frederick Douglass houses like right in. Anacostia up on the Hill and I had totally forgotten about that until somebody. Mentioned did you tell me that?",
    "ibm_transcription": "so that really just the only time that I do that type of stuff I mean I really want to do better at that in %HESITATION is when people come to town now I you know and I have a and as I've gotten older I definitely you know that's definitely on my to do list %HESITATION and it you know there's certain things I even forgot existed like the Frederick Douglass houses like right in Anacostia up on the hill and I totally forgot about that until somebody mention did you tell me that",
    "amazon_transcription": "happened. So that really just the only time that I do that type of stuff. I mean, I really want to do better at that inn, Um, is when people come to town now, uh, you know, and I have. Ah. And as I've gotten older, I've definitely, you know, that's definitely on what my to do list. Um, and that, you know, there's certain things I even forgot existed like the Frederick Douglass houses, like right in Anacostia. Up on who? And I had totally forgotten about that until somebody mentioned Did you tell me that?",
    "google_transcription": "so that really just the only time that I do that type of stuff I mean I really want to do better at that and is when people come to town now you know and I have a and as I've gotten older I've definitely you know that's definitely don't want my to do list and then you know there's certain things I even forgot existed like the Frederick Douglass houses like right in Anacostia up on the hill and I totally forgot about that until somebody mentioned did you tell me",
    "apple_transcription": "I happen so that really just the only time that I do that type of stuff I mean I really want to do better at that and is when people come to town now that you know and I have a and as I've gotten older I definitely know that's definitely on my to do list on and then another certain things I even forgot existed like the Frederick Douglass house is like right in Anacostia up on the hill and I totally forgot about that until somebody mentioned did you tell me that",
    "clean_google_wer": 0.1515,
    "clean_ibm_wer": 0.1414,
    "clean_amazon_wer": 0.1616,
    "clean_msft_wer": 0.1313,
    "clean_apple_wer": 0.1414,
    "ddm": 0,
    "avg_wer": 0.1455
  },
  {
    "snippet": "DCB_se2_ag3_f_02_1_2183557_2191315.mp3",
    "wordcount": 22,
    "phon_count": 4,
    "phon_features": [
      "th stopping: theyre, theyre",
      "final consonant deletion after vowel: what, of"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 4,
    "content": "What- <laugh> what more do you need? They're- they're literally building buildings on top of overpasses in DC to get people in here.",
    "age": 31,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "One more do you needed there are literally building buildings on top of overpasses and DC to get people were here.",
    "ibm_transcription": "what do you mean they're they're literally building buildings on topple over passes and easy to get people in here",
    "amazon_transcription": "What do you need? They're there literally building buildings on top of overpasses in D. C to get people were here.",
    "google_transcription": "swimmer do you need then they're literally building building on top of overpasses in DC to get people in here",
    "apple_transcription": "What do you mean you did there a literally building buildings on top of overpasses and easy to get people here",
    "clean_google_wer": 0.2273,
    "clean_ibm_wer": 0.3636,
    "clean_amazon_wer": 0.2727,
    "clean_msft_wer": 0.3182,
    "clean_apple_wer": 0.4091,
    "ddm": 0.1818,
    "avg_wer": 0.3182
  },
  {
    "snippet": "ROC_se0_ag1_f_01_1_453489_469577.mp3",
    "wordcount": 71,
    "phon_count": 4,
    "phon_features": [
      "-ng as -n: growing",
      "monopthongization: I",
      "CCR: boyfriend",
      "l vocalization: all"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 4,
    "content": "a lot of the kids I went to elementary from- I was overweight growing up, so a lot of the kids were going to Wilson, and I didn't want to go because they used to tease me all the time, so I didn't want to go to Wilson. And I didn't want to go to a all girls school, cause I was like, great I'm never gonna have a boyfriend. <laugh>",
    "age": 26,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "Data kids, I went to elementary from I was overweight growing up so a lot of the kids were going to Wilson and I didn't want to go because they sees me all the time so I didn't want to go to Wilson. And I didn't want to go to all girls school sounds like great. I'm never going to have a boyfriend.",
    "ibm_transcription": "a lot of the kids I went to elementary from I was overweight around that so that at the kids were going to listen and I didn't want to go because these sees me all the time so I didn't want to go to a thing and I didn't want to go to all girls school sounds like great I'm never gonna have a boyfriend",
    "amazon_transcription": "a lot of the kids I went to elementary from I was overweight growing up. So a lot of the kids were going to listen and I didn't want to go because they seize me all the time. So I didn't want to go to work thing, and I didn't want to goto all girl school. Sounds like great. I'm never gonna have a boyfriend.",
    "google_transcription": "a lot of the kids I went to Elementary from that was overweight growing up so a lot of the kids were going to Wilson and I didn't want to go because they sees me all the time so I didn't want to go to Wilson and I didn't want to go to all girl school sounds like great I'm never going to have a boyfriend",
    "apple_transcription": "A lot of the kids I went to elementary firm that was overweight growing up so a lot of the kids were gone within and I didn't want to go because the season out at five so I didn't want to go to work and I didn't want to go to loggers school 58 great I'm never gonna have a boyfriend",
    "clean_google_wer": 0.1268,
    "clean_ibm_wer": 0.2254,
    "clean_amazon_wer": 0.1831,
    "clean_msft_wer": 0.1549,
    "clean_apple_wer": 0.2958,
    "ddm": 0.0563,
    "avg_wer": 0.1972
  },
  {
    "snippet": "PRV_se0_ag1_f_01_1_817743_824865.mp3",
    "wordcount": 28,
    "phon_count": 7,
    "phon_features": [
      "th stopping: the, their, everything",
      "final consonant deletion: of, had, be(d)room",
      "monotphogonzation: I"
    ],
    "gram_count": 1,
    "gram_features": [
      "pluras -s absence: shoes"
    ],
    "total_count": 8,
    "content": "maybe the clothes on their back, some of might had on bedroom shoes and everything. Or some of 'em- I don't think some of 'em had on shoes.",
    "age": 19,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "Maybe D close on that bag some of my head on Berry choosing anything or some of the I don't think someone hadn't shows.",
    "ibm_transcription": "maybe the calls on that bag some of my head a bit which is a neat thing or some of the someone had %HESITATION shown",
    "amazon_transcription": "maybe the clothes on their bag, some of my head on their issues In a thing. You are someone I think someone had Xun.",
    "google_transcription": "maybe the clothes on that bag someone might had on Beverages and everything or some of it",
    "apple_transcription": "Maybe the cold on the bag and some on my head a bit",
    "clean_google_wer": 0.5714,
    "clean_ibm_wer": 0.75,
    "clean_amazon_wer": 0.6071,
    "clean_msft_wer": 0.6071,
    "clean_apple_wer": 0.8214,
    "ddm": 0.2857,
    "avg_wer": 0.6714
  },
  {
    "snippet": "DCB_se1_ag3_m_02_1_1563477_1588686.mp3",
    "wordcount": 51,
    "phon_count": 15,
    "phon_features": [
      "final consonant deletion after vowel: it, was, band",
      "intersyallabic /r/ deletion: sacrificial",
      "th stopping: they, that, there, though",
      "fricative stopping: wasn't",
      "CCR: band, and",
      "l vocalization after vowel: aight, ball",
      "intial syllable deletion: remember",
      "-ng as -n: working"
    ],
    "gram_count": 1,
    "gram_features": [
      "'it' instead of 'there': it was one band"
    ],
    "total_count": 16,
    "content": "Yeah. It was more like a sacrificial type thing. They wasn't really radio oriented. Radio stations weren't playing go go like that. It was one band that got on though, who was it? Uh, EU? Who was that band, EU? And that was aight. Used to crank too though. Remember, Working the /?/?",
    "age": 36,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "Microsoft. No one really radio Warriors. Radio station 1 playing go go like that. It would. I was. Use a crying through mobile working bowl.",
    "ibm_transcription": "more like a sack fish no one really ready %HESITATION for years radio station one plane go online one man I got %HESITATION knows that %HESITATION the eighteen when I was our use of cars to mobile work on Wall",
    "amazon_transcription": "more like a sacrificial. They weren't really ready. O warriors radio station weren't playing go like this. My band got old news. You I was used to crying, remember? We're going. Whoa!",
    "google_transcription": "need more like a sacrificial there wasn't really a radio Orioles radio station one playing Google like that it was my bad I got on the music I was all right you so crazy. I will work in the bar",
    "apple_transcription": "No one really radio or your radio station I barely got on her own and I was on your screen and we're gonna ball",
    "clean_google_wer": 0.7059,
    "clean_ibm_wer": 0.8235,
    "clean_amazon_wer": 0.7059,
    "clean_msft_wer": 0.7843,
    "clean_apple_wer": 0.8627,
    "ddm": 0.3137,
    "avg_wer": 0.7765
  },
  {
    "snippet": "ROC_se0_ag1_m_01_1_1094303_1131206.mp3",
    "wordcount": 79,
    "phon_count": 1,
    "phon_features": [
      "-ng as -n: realizing lots of omissions"
    ],
    "gram_count": 1,
    "gram_features": [
      "verb stem as past tense: they come up to me"
    ],
    "total_count": 2,
    "content": "Um, honestly, my mom picked every single school that I went to. Um, so it started of with pre-K at /RD-SCHOOL-3/. And then I went to /RD-SCHOOL-4/, um, from K through second. After /RD-SCHOOL-4/, I went to /RD-SCHOOL-2/, from third until six. At /RD-SCHOOL-2/, is where I started realizing why I was different from other people. Cause like, I was in fourth grade literally like a week. And they come up to me and are like, have you ever heard about the /RD-SCHOOL-3/? And I'm like, no.",
    "age": 24,
    "source": "ROC",
    "gender": "Male",
    "msft_transcription": "Honestly, my mom picked every single school that I went to. So we started off with pre cap. And then I went to. From K through 2nd after. I want to from 3rd until 6:00 at. Is where? I started realizing wireless different from other people 'cause like I was at 4. Gray literally like a week and he come up to me like Have you ever heard about them? And I'm like no.",
    "ibm_transcription": "%HESITATION honestly my mom picked every single school that went to all so to start off with pre K. F. and then I went to %HESITATION from K. through second after I want to from thirty till six at is aware I started realizing while is different from other people because like I was in for a very literally like a week or a couple of them they're like have you ever heard about the I'm way known",
    "amazon_transcription": "Um, honestly, my mom picked every single school that I went to home, so it started off with pretty caf. And then I went to, um from K through a second after I went to from third until six is where I started. Realizing while is different from other people because, like, I was in for a curate, literally like a week, they come up to me like, have you ever heard about them anyway? No.",
    "google_transcription": "honestly my mom pick every single school that I went to so it started off with Pre-K and then I went to I'm from K through a second after I went to from third until 6 at is weird I started realizing why I was different from other people cuz like I was in 4th grade literature like a week and it come up to me or like have you ever heard about the and I'm like no",
    "apple_transcription": "Honestly my mom paid every single school that went to Haro so he started off with pre-KF and then I went to arm from K through second after I went from 30 until six at is where I started realizing why I was different from other people because like I was in for a gray literally like a week to come up to me or like have you ever heard about them and I'm like no",
    "clean_google_wer": 0.1266,
    "clean_ibm_wer": 0.3544,
    "clean_amazon_wer": 0.2658,
    "clean_msft_wer": 0.2025,
    "clean_apple_wer": 0.2405,
    "ddm": 0.0253,
    "avg_wer": 0.238
  },
  {
    "snippet": "PRV_se0_ag1_f_01_1_2023946_2032637.mp3",
    "wordcount": 28,
    "phon_count": 5,
    "phon_features": [
      "r-less after vowel: for",
      "th stopping: the, there",
      "monopthongization: boys",
      "friccative stopping: have"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 5,
    "content": "for the Boys and Girls Club. And um, you- you have to- like, if they have problems, you should be there for them to talk to them, you know?",
    "age": 19,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "For the boys and Girls Club Ann. You have to like if they have problems. You should be there for them to talk to their Reno.",
    "ibm_transcription": "what was yours and %HESITATION you you have to like if they have problems he should be there for them to talk to them you know",
    "amazon_transcription": "for the Boys and Girls Club, and you have to like, if they have problems, you should be there for them to talk to them, you know?",
    "google_transcription": "for the Boys & Girls Club and if you have to act like if they have problems we should be there for them to talk to them you know",
    "apple_transcription": "What a Boys and Girls Club and I are you have is it like if I have problems you should be there for her to talk to them you know",
    "clean_google_wer": 0.1429,
    "clean_ibm_wer": 0.25,
    "clean_amazon_wer": 0.0357,
    "clean_msft_wer": 0.1786,
    "clean_apple_wer": 0.2857,
    "ddm": 0.1786,
    "avg_wer": 0.1786
  },
  {
    "snippet": "DCB_se1_ag2_f_03_1_2594369_2619310.mp3",
    "use": true,
    "attribution": "coraal",
    "wordcount": 77,
    "phon_count": 7,
    "phon_features": [
      "th stopping: without",
      "-ng as -n: thinking",
      "final consonant deletion: food",
      "dress vowel raising: metro",
      "l vocalization: school",
      "fill-feel merger: feel",
      "CCR: traveled"
    ],
    "gram_count": 0,
    "gram_features": [
      "'been' unstressed: i just kind of been"
    ],
    "total_count": 7,
    "annotations": [
      {
        "timeStart": 7.555775191390452,
        "timeEnd": 8.134690574464225,
        "timeCtxStart": 6.311737397815983,
        "timeCtxEnd": 9.659999384747884,
        "start": 123,
        "end": 130,
        "content": "Syllable initial fricative stopping"
      },
      {
        "timeStart": 8.727904908085021,
        "timeEnd": 8.962349797359002,
        "timeCtxStart": 6.311737397815983,
        "timeCtxEnd": 9.659999384747884,
        "start": 138,
        "end": 146,
        "content": "Realization of -ng as -n"
      },
      {
        "timeStart": 14.160038858538117,
        "timeEnd": 15.043452534070921,
        "timeCtxStart": 11.790711558250003,
        "timeCtxEnd": 16.234768377268708,
        "start": 224,
        "end": 228,
        "content": "Final consonant deletion"
      },
      {
        "timeStart": 21.005716695495146,
        "timeEnd": 21.432129472082558,
        "timeCtxStart": 20.277267784696733,
        "timeCtxEnd": 23.43860477339492,
        "start": 312,
        "end": 318,
        "content": "Vocalization of post vocalic /l/"
      },
      {
        "timeStart": 21.733399368584532,
        "timeEnd": 21.90798141117286,
        "timeCtxStart": 20.277267784696733,
        "timeCtxEnd": 23.43860477339492,
        "start": 325,
        "end": 329,
        "content": "Merger of tense and lax vowels before /l/"
      },
      {
        "timeStart": 22.038422487950672,
        "timeEnd": 23.073339829365988,
        "timeCtxStart": 20.277267784696733,
        "timeCtxEnd": 23.43860477339492,
        "start": 350,
        "end": 354,
        "content": "Unstressed \"been\" for present perfect"
      }
    ],
    "content": "I just wanna experience living somewhere else. And then it's so expensive to live here now. You can't even live comfortably without really thinking about, okay, I gotta pay all this money for rent, gotta pay all this money for food, for this, for Metro, X, Y, and Z, so. I do wanna move, cause I didn't go away for school, so I feel like I just kind of been stuck h- Even though I have traveled.",
    "real_transcription": "I just wanna experience living somewhere else and then it's so expensive to live here now. You can't even live comfortably without really thinking about okay I gotta pay all this money for rent, gotta pay all this money for food, for this, for Metro, X, Y, and Z, so. I do wanna move, cause I didn't go away for school, so I feel like I just kind of been stuck Even though I have traveled.",
    "asr_transcription": "an experience living somewhere else and then it's so expensive to live here now. You can't live comfortably without really thinking about okay I gotta pay all this money for France got to pay all this money for food but I do want to move, cause I didn't go away for school, so I feel like I just kind of been stuck here I have travel ",
    "age": 25,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "I just want to experience living somewhere else and then it's so expensive to live here now you can't even. Live comfortably but I'll really thinking about OK gotta pay all this money for rent. Gotta pay all this money for food. But this metro XY and Z so I do wanna move 'cause. I didn't go away for school so I feel like I just kind of been stuck even though I have traveled.",
    "ibm_transcription": "experience living somewhere else and then this so it's most of the live here now you can't even live comfortably but I'll will be thinking about okay got to pay all this money for rent get avail this money for food but this with metro X. Y. Z. so I do want to move because I didn't go away for school so I feel like I just kind of been stuck in I have traveled",
    "amazon_transcription": "I just want to experience living somewhere else. And then it's so expensive to live here now you can't even live comfortably but out really thinking about Okay, Gotta pay all this money for Grant. Gotta pay all this money for food. But this natural x, y and Z So I do want to move because I didn't go away for school, so I feel like I just kind of been stuck and I have travelled.",
    "google_transcription": "an experience living somewhere else and then it's so expensive to live here now you can't live comfortably without really thinking about okay I got to pay all this money for France got to pay all this money for food but I do want to move cuz I didn't go away for school so I feel like I just kind of been stuck here I have travel",
    "apple_transcription": "I just want to experience living somewhere else and then I saw his message to live here now you can't even live comfortably without really thinking about OK to pay all this money for rent got a pay all this money for food but this retro XYZ so I do want to move because I didn't go away for school so I feel like I just kind of been stuck in life travel",
    "clean_google_wer": 0.3117,
    "clean_ibm_wer": 0.3377,
    "clean_amazon_wer": 0.2078,
    "clean_msft_wer": 0.1299,
    "clean_apple_wer": 0.3377,
    "ddm": 0.0909,
    "avg_wer": 0.2649
  },
  {
    "snippet": "ROC_se0_ag1_f_03_1_1654023_1664888.mp3",
    "wordcount": 42,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "I would do those. And I would be really happy doing it. I just- like now I know. And if I have a kid, like, I'll just try not to make their life hell. And make them feel like they can stay.",
    "age": 28,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "I would do those and I would be really happy doing it. I just like now. I know and if I have a kid. Like I'll just try not to make their life hell and make them feel like they can stay.",
    "ibm_transcription": "I would do those and I'd be really happy doing it I just think now I know and if I have a kid I'll just try not to make their life hell and make them feel like they can stay",
    "amazon_transcription": "I would do those and I would be really happy doing it. I just now I know. And if I have a kid, look, I'll just try not to make their life hell and make them feel like they can stay.",
    "google_transcription": "I would do those and I would be really happy doing it I just now I know and if I have a kid I'll just try not to make your life hell and make them feel like they can to stay",
    "apple_transcription": "I would do those and I'd be really happy doing it I just now I know and if I have a kid and I just try not to make their life hell and make them feel like they can stay",
    "clean_google_wer": 0.0952,
    "clean_ibm_wer": 0.0952,
    "clean_amazon_wer": 0.0476,
    "clean_msft_wer": 0,
    "clean_apple_wer": 0.119,
    "ddm": 0,
    "avg_wer": 0.0714
  },
  {
    "snippet": "PRV_se0_ag3_f_04_3_1229788_1238482.mp3",
    "use": true,
    "attribution": "coraal",
    "wordcount": 10,
    "phon_count": 3,
    "phon_features": [
      "th stopping: the, though",
      "r-less after vowel: corn"
    ],
    "gram_count": 1,
    "gram_features": [
      "absence plural s: string beans"
    ],
    "total_count": 4,
    "annotations": [
      {
        "timeStart": 2.3062680888781606,
        "timeEnd": 2.5892127979031767,
        "timeCtxStart": 1.485728432705614,
        "timeCtxEnd": 3.671476309923863,
        "start": 22,
        "end": 25,
        "content": "Syllable initial fricative stopping"
      },
      {
        "timeStart": 3.0277770968919517,
        "timeEnd": 3.6431818390213615,
        "timeCtxStart": 1.485728432705614,
        "timeCtxEnd": 3.671476309923863,
        "start": 31,
        "end": 37,
        "content": "Syllable initial fricative stopping"
      },
      {
        "timeStart": 4.357617229309527,
        "timeEnd": 5.178156885482074,
        "timeCtxStart": 3.671476309923863,
        "timeCtxEnd": 5.8006352453371095,
        "start": 46,
        "end": 50,
        "content": "Merger of tense and lax vowels before nasals"
      },
      {
        "timeStart": 7.9156469452991045,
        "timeEnd": 8.694013605442176,
        "timeCtxStart": 5.8006352453371095,
        "timeCtxEnd": 8.694013605442176,
        "start": 55,
        "end": 59,
        "content": "Vocalization of post-vocalic /r/"
      }
    ],
    "content": "Mm-hm. She had cooked the meat though, string bean and corn,",
    "real_transcription": "Mm-hm. She had cooked the meat though, string bean and corn.",
    "asr_transcription": "Yeah cook the meat no screaming and crying more",
    "age": 76,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "Yeah, Cook, the meat no string Bing. Ann. Call.",
    "ibm_transcription": "%HESITATION Hey echo to me no rain main and chrome",
    "amazon_transcription": "you could have me No spring Bing and Cole.",
    "google_transcription": "I cooked the meal of Stringbean and coma",
    "apple_transcription": "Yeah cook the meat no screaming and crying more",
    "clean_google_wer": 0.7,
    "clean_ibm_wer": 0.9,
    "clean_amazon_wer": 0.9,
    "clean_msft_wer": 0.7,
    "clean_apple_wer": 0.8,
    "ddm": 0.4,
    "avg_wer": 0.8
  },
  {
    "snippet": "PRV_se0_ag3_m_01_1_986228_1000398.mp3",
    "wordcount": 31,
    "phon_count": 3,
    "phon_features": [
      "-ng as -n: looking, looking",
      "final consonant deletion after vowel: le(t)s"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 3,
    "content": "to present it without um, l- f- u- looking- looking like a threat. We just want people to acknowledge the wrong and let's see if we can, um, do something correcting it because,",
    "age": 64,
    "source": "PRV",
    "gender": "Male",
    "msft_transcription": "To present it without? Looking looking like a threat. We just want people to acknowledge the wrong and let's see if we can do something about correcting it becaus.",
    "ibm_transcription": "to present it without %HESITATION look a like a threat we just want people to acknowledge the wrong in let's see if we can %HESITATION do something about correcting it because",
    "amazon_transcription": "to present it without, huh? Looking looking like a threat. We just want people to acknowledge the wrong and let's see if we can do something about correcting it, because",
    "google_transcription": "to presented without looking looking like a threat we just want people to acknowledge the wrong and let's see if we can do something about correctly because",
    "apple_transcription": "To present it without him looking like a threat we just want people to acknowledge the wrong and less it we can now do something about correctly because",
    "clean_google_wer": 0.2258,
    "clean_ibm_wer": 0.2258,
    "clean_amazon_wer": 0.129,
    "clean_msft_wer": 0.1613,
    "clean_apple_wer": 0.3226,
    "ddm": 0.0968,
    "avg_wer": 0.2129
  },
  {
    "snippet": "DCB_se2_ag4_m_01_1_1365784_1375073.mp3",
    "wordcount": 36,
    "phon_count": 12,
    "phon_features": [
      "'th' fronting: this, that, this, that,",
      "post-vocalic consonant deletion: man, man, bad",
      "initial g deletion: ima",
      "ccr (neg): aint",
      "monopthongization: i'm, my, I"
    ],
    "gram_count": 0,
    "gram_features": [
      "ain't: aint (overlap)"
    ],
    "total_count": 12,
    "content": "this and all of that, this that and the other. I'm like, man, I'm down /in my man, uh/ /I'm'a/ kill me in a instant. So I might you ain't as bad as you think you are.",
    "age": 59,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "This normal like man I'm. Kim Yuna instead. So. Unit is bad, you think you are.",
    "ibm_transcription": "is that it Chilean is to so %HESITATION my name is bad you think you are",
    "amazon_transcription": "this. No one did it, man. I'm gonna kill me in the distance. So you're bad. You think you are.",
    "apple_transcription": "So how bad you think",
    "clean_google_wer": 1,
    "clean_ibm_wer": 0.8056,
    "clean_amazon_wer": 0.6667,
    "clean_msft_wer": 0.7222,
    "clean_apple_wer": 0.8889,
    "ddm": 0.3333,
    "avg_wer": 0.8167
  },
  {
    "snippet": "ROC_se0_ag1_m_01_1_3347541_3368208.mp3",
    "use": true,
    "attribution": "coraal",
    "wordcount": 45,
    "phon_count": 2,
    "phon_features": [
      "final consonant deletion: its, its, like"
    ],
    "gram_count": 1,
    "gram_features": [
      "copula absence: where they from"
    ],
    "total_count": 3,
    "annotations": [
      {
        "timeStart": 1.7376405431908302,
        "timeEnd": 2.1297762557879167,
        "timeCtxStart": 0.8750217943699801,
        "timeCtxEnd": 2.9432739598846758,
        "start": 8,
        "end": 12,
        "content": "Final Consonant Deletion"
      },
      {
        "timeStart": 2.1297762557879167,
        "timeEnd": 2.6031653142384537,
        "timeCtxStart": 0.8750217943699801,
        "timeCtxEnd": 2.9432739598846758,
        "start": 13,
        "end": 17,
        "content": "Final Consonant Deletion"
      },
      {
        "timeStart": 5.1505289707464685,
        "timeEnd": 5.660704047363605,
        "timeCtxStart": 4.742485193299899,
        "timeCtxEnd": 6.121319970309696,
        "start": 18,
        "end": 19,
        "content": "Glide reduction of /ai/"
      },
      {
        "timeStart": 6.135715004542585,
        "timeEnd": 6.428058514553803,
        "timeCtxStart": 6.138135028565913,
        "timeCtxEnd": 9.04714010689146,
        "start": 32,
        "end": 36,
        "content": "Final Consonant Deletion"
      },
      {
        "timeStart": 7.273002561781346,
        "timeEnd": 7.572476401305033,
        "timeCtxStart": 6.138135028565913,
        "timeCtxEnd": 9.04714010689146,
        "start": 48,
        "end": 52,
        "content": "Final Consonant Deletion"
      },
      {
        "timeStart": 20.121752717820627,
        "timeEnd": 20.339703871400673,
        "timeCtxStart": 19.60699669179576,
        "timeCtxEnd": 20.666984126984126,
        "start": 216, // Manual
        "end": 226,
        "content": "1) Copula Absence; 2) Syllable Initial Fricative Stopping: \"Them\" -> \"Dem\""
      }
    ],
    "content": "<sigh> I don't- it's- it's- I don't know. It's- it's- somethi- it's something about us. Like- like you can hear somebody from the city and be like, okay yeah, they're from the city. But like, you hear somebody from Rochester and you're like, where they from?",
    "age": 24,
    "source": "ROC",
    "gender": "Male",
    "msft_transcription": "Is is? I don't know if something is something about us like. Like you could hear somebody from the city be like OK, yeah, there from the city. But like you hear somebody from Rochester and you're like. Really from",
    "ibm_transcription": "is is I don't know is is something is something about a slight but you can hear somebody from the city we live okay yeah they're from the city well like you hear somebody from Rochester you're lying live from",
    "amazon_transcription": "out is I don't know. It's something. It's something about us. Like you could hear somebody from the city be like, okay, there from this city. But like, you hear somebody from Rochester in your life from",
    "google_transcription": "this is I don't know if this is something is something about us like but you can hear somebody from the city be like okay they're from the city but like you hear somebody from Rochester your light Libra",
    "apple_transcription": "How is his eye don't know is is something is something about a slight but you can hear somebody from the city be late OK yeah they're from the city ballet you hear somebody from Rogers and you're lying",
    "clean_google_wer": 0.4,
    "clean_ibm_wer": 0.4222,
    "clean_amazon_wer": 0.3778,
    "clean_msft_wer": 0.2889,
    "clean_apple_wer": 0.4667,
    "ddm": 0.0667,
    "avg_wer": 0.3911,
    "real_transcription": "I don't it's it's I don't know. It's something, it’s something about us like you can hear somebody from the city and be like okay yeah they're from the city but like you hear somebody from Rochester and you're like, where they from?",
    "asr_transcription": "this is I don't know if this is something, is something about us like but you can hear somebody from the city be like okay they're from the city but like you hear somebody from Rochester your light Libra "
  },
  {
    "snippet": "DCB_se2_ag2_m_01_1_1216849_1241870.mp3",
    "use": true,
    "attribution": "coraal",
    "wordcount": 81,
    "phon_count": 13,
    "phon_features": [
      "monopthongization: I, I, my",
      "ccr: would",
      "l vocalization: old",
      "medial unstressed syllable deletion: interaction",
      "th stopping: with, that, that",
      "ccr: act, act",
      "l vocalization: culture",
      "final consonant deletion: cause"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 13,
    "annotations": [
      {
        "timeStart": 0.14712629122330165,
        "timeEnd": 0.34753369798844946,
        "timeCtxStart": 0,
        "timeCtxEnd": 1.4211681786476464,
        "start": 5,
        "end": 6,
        "content": "Glide reduction of /ai/"
      },
      {
        "timeStart": 0.5588017457183382,
        "timeEnd": 0.6655331267810911,
        "timeCtxStart": 0,
        "timeCtxEnd": 1.4211681786476464,
        "start": 12, // Manual
        "end": 13, // Manual
        "content": "Glide reduction of /ai/"
      },
      {
        "timeStart": 0.6655331267810911,
        "timeEnd": 0.7937509188902381,
        "timeCtxStart": 0,
        "timeCtxEnd": 1.4211681786476464,
        "start": 14,
        "end": 19,
        "content": "Final Consonant Deletion"
      },
      {
        "timeStart": 0.9218264822272844,
        "timeEnd": 1.06283149176806,
        "timeCtxStart": 0,
        "timeCtxEnd": 1.4211681786476464,
        "start": 30, // Manual
        "end": 31, // Manual
        "content": "Glide reduction of /ai/"
      },
      {
        "timeStart": 1.65678682207532,
        "timeEnd": 1.9152178156955595,
        "timeCtxStart": 1.4857116958030145,
        "timeCtxEnd": 4.059109066989635,
        "start": 50,
        "end": 53,
        "content": "Vocalization of post vocalic /l/"
      },
      {
        "timeStart": 1.9242486459998165,
        "timeEnd": 2.0445758393833655,
        "timeCtxStart": 1.4857116958030145,
        "timeCtxEnd": 4.059109066989635,
        "start": 55,
        "end": 61,
        "content": "Syllable initial fricative stopping"
      },
      {
        "timeStart": 2.0543932828595186,
        "timeEnd": 2.216381100216044,
        "timeCtxStart": 1.4857116958030145,
        "timeCtxEnd": 4.059109066989635,
        "start": 62,
        "end": 64,
        "content": "Glide Reduction of /ai/"
      },
      {
        "timeStart": 2.523176208845827,
        "timeEnd": 2.9158739478919498,
        "timeCtxStart": 1.4857116958030145,
        "timeCtxEnd": 4.059109066989635,
        "start": 71,
        "end": 82,
        "content": "Medial unstressed syllable deletion"
      },
      {
        "timeStart": 4.530377745414636,
        "timeEnd": 4.834347909842126,
        "timeCtxStart": 4.308561679481062,
        "timeCtxEnd": 5.781173894984512,
        "start": 106,
        "end": 108,
        "content": "Glide reduction of /ai/"
      },
      {
        "timeStart": 8.63637021943351,
        "timeEnd": 8.78756026198441,
        "timeCtxStart": 7.268431696104666,
        "timeCtxEnd": 9.772414828070364,
        "start": 164,
        "end": 168,
        "content": "Syllable initial fricative stopping"
      },
      {
        "timeStart": 10.95610290953503,
        "timeEnd": 11.156187891350859,
        "timeCtxStart": 10.64073717660677,
        "timeCtxEnd": 11.555032788877774,
        "start": 195,
        "end": 199,
        "content": "Syllable initial fricative stopping"
      },
      {
        "timeStart": 11.827511699585962,
        "timeEnd": 11.924258088558334,
        "timeCtxStart": 11.755361850182839,
        "timeCtxEnd": 13.403330001322395,
        "start": 211,
        "end": 216,
        "content": "Final Consonant Deletion"
      },
      {
        "timeStart": 11.934096704386034,
        "timeEnd": 12.184981407992355,
        "timeCtxStart": 11.755361850182839,
        "timeCtxEnd": 13.403330001322395,
        "start": 217,
        "end": 221,
        "content": "Syllable initial fricative stopping"
      },
      {
        "timeStart": 13.06225798596217,
        "timeEnd": 13.382013000362381,
        "timeCtxStart": 11.755361850182839,
        "timeCtxEnd": 13.403330001322395,
        "start": 239,
        "end": 243,
        "content": "Syllable initial fricative stopping"
      },
      {
        "timeStart": 19.384573304326132,
        "timeEnd": 19.799716514941355,
        "timeCtxStart": 18.32276470794488,
        "timeCtxEnd": 20.394488999380478,
        "start": 304,
        "end": 315,
        "content": "Medial unstressed syllable deletion"
      },
      {
        "timeStart": 19.799716514941355,
        "timeEnd": 20.027246928451625,
        "timeCtxStart": 18.32276470794488,
        "timeCtxEnd": 20.394488999380478,
        "start": 316,
        "end": 323,
        "content": "Post vocalic /l/ vocalization"
      },
      {
        "timeStart": 24.212793031619395,
        "timeEnd": 24.434007818198097,
        "timeCtxStart": 23.82787930297245,
        "timeCtxEnd": 24.824820607820477,
        "start": 408,
        "end": 410,
        "content": "Glide reduction before /ai/"
      }
    ],
    "content": "when I was- I would say when I was nineteen years old, that's my first interaction with white people. And my first initial reaction was white people really act how they act on TV. Like I thought that was crazy. Cause they really talk like that. I- I never experienced that before. So it was a- It was not- not necessarily culture shock, but it was just like, wow this is crazy. Cause I've been around black people my whole life.",
    "age": 27,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "And I was, I was having of 19 years old. That's my first in accident, were white people and my first initial reaction was. My people really act how they act on TV. Like I thought that was crazy 'cause, they really talk like that. I've never experienced it before. So it was a it was not necessary causes sharp, but it was just like wow. This is crazy. 'Cause I've been on Black Field my whole life.",
    "ibm_transcription": "I was I was seven of nineteen years so that's my first an axe and the white people am I the first initial reaction was Michael will really act how they act on TV but I thought that was crazy because they really talk like that and I never experience a full so it was it was not not not so close shop but it was just like wow this is crazy because I've been on black people my whole life",
    "amazon_transcription": "when I was seven, I was 19 years old. That's my first in accident were white people. And my first issue reaction was my people really act how they act on TV. Like I thought that was crazy. Could they really talk like that? I never experienced that before, so it was It was not not necessary. Call the shop. But it was just like, Wow, this is crazy because I've been about black people our whole life.",
    "google_transcription": "and I was I was hoping I was 19 years old that's my first in accident with white people and my first initial reaction was white people really act how they act on TV like I thought that was crazy they really talk like that and I never tried that before so it was a it was not nothing that's our call the shop but it was just like wow this is crazy cuz I've been on black people my whole life",
    "apple_transcription": "And I was I was seven of 19 years old is not for us and ask and white people and my first initial reaction was why people really I had it on TV they got all that was crazy because they really talk like that and I never expire that before so it was it it was not in the cycle shop but it was just like wow this is crazy and I've been a Blacktip my whole life",
    "clean_google_wer": 0.1975,
    "clean_ibm_wer": 0.358,
    "clean_amazon_wer": 0.2346,
    "clean_msft_wer": 0.2346,
    "clean_apple_wer": 0.4074,
    "ddm": 0.1605,
    "avg_wer": 0.2864,
    "real_transcription": "when I was. I would say when I was nineteen years old. That's my first interaction with white people. And my first initial reaction was white people really act how they act on TV. Like I thought that was crazy. Cause they really talk like that. I never experienced that before, so it was, it was not not necessarily culture shock. But it was just like, wow, this is crazy cause I've been around black people my whole life.",
    "asr_transcription": "when I was seven, I was nineteen years old. That's my first in accident were white people. And my first issue reaction was my people really act how they act on TV. Like I thought that was crazy. Could they really talk like that. I never experienced that before, so it was, it was not not necessary. Call the shop. But it was just like, wow, this is crazy cause I've been about black people our whole life."
  },
  {
    "snippet": "PRV_se0_ag1_f_01_1_895332_910331.mp3",
    "wordcount": 48,
    "phon_count": 2,
    "phon_features": [
      "CCR: risk",
      "final consonant deletion: took"
    ],
    "gram_count": 1,
    "gram_features": [
      "was-were levelling: they was like"
    ],
    "total_count": 3,
    "content": "And um, certain people took risk and they went through there, and they was like- But a week and a half went by- I think a week- maybe a week or four or five days went by- we went through and um- like just to see like, like what hap-",
    "age": 19,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "Certain reason they went through there and it was like but we can have went by, I think a week, maybe a week or? Four five days whereby we went through, and um might just to see I would have.",
    "ibm_transcription": "send people to the race and they went through there and it was like but a we can has went by I think a week maybe a week or four five days one by we went through and %HESITATION like just to see hi I would have",
    "amazon_transcription": "an outstanding people took racing. They went through there and was like, But we can have. I think we live a week or for five days. When we went through it all, Mike, just a scene I would have.",
    "google_transcription": "and I'm certain people took racing they went through there and it was like but we can have went by I think a week maybe a week or for five days went by we went through it on like just to see",
    "apple_transcription": "And I sent him some reason they went to their name is like but if we can have a twin but I think a week maybe a week or or 581 that we went through and I will buy just the thing",
    "clean_google_wer": 0.3542,
    "clean_ibm_wer": 0.3333,
    "clean_amazon_wer": 0.5833,
    "clean_msft_wer": 0.3958,
    "clean_apple_wer": 0.625,
    "ddm": 0.0625,
    "avg_wer": 0.4583
  },
  {
    "snippet": "ROC_se0_ag3_f_02_1_2221266_2232596.mp3",
    "wordcount": 31,
    "phon_count": 3,
    "phon_features": [
      "th stopping: with",
      "r-less after vowel: remember",
      "final consnant deletion after vowel: would"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 3,
    "content": "with all the crinolines on there. I remember that skirt. I would wear it every Friday. And it had like a carousel around it. I couldn't dance, but I could twirl.",
    "age": 80,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "With all the criminal is on there. I remember that skirt out where it every Friday and it had like a Carol cell? Around it, I couldn't dance, but I could twirl.",
    "ibm_transcription": "with all the criminalists on there I remember that skirt out weird every Friday and it had like a Carol sale around it dance with active Touro",
    "amazon_transcription": "with all the criminal is on there. I remember that skirt. I wear it every Friday and it had, like, a carol cell around it. I couldn't dance what I could twirl.",
    "google_transcription": "with all the crinolines on there I remember that skirt I wear it every Friday and it had like a carousel around it I couldn't dance what I could twirl",
    "apple_transcription": "With all the crinolines on there I remember that skirt out where they were Friday and it had like a carousel around it I couldn't dance what I could tour",
    "clean_google_wer": 0.0645,
    "clean_ibm_wer": 0.4194,
    "clean_amazon_wer": 0.1935,
    "clean_msft_wer": 0.2258,
    "clean_apple_wer": 0.2258,
    "ddm": 0.0968,
    "avg_wer": 0.2258
  },
  {
    "snippet": "ROC_se0_ag2_f_03_1_2341457_2355436.mp3",
    "wordcount": 21,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "Um, but I have had a handful of days at my company that were, you know, my blackness was, you know, bleeding",
    "age": 39,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "But I have had. A handful of days at my company that where you know. My blackness. Was? You know bleeding.",
    "ibm_transcription": "%HESITATION but I have had a handful days at my company that where you know my blackness was you know leading",
    "amazon_transcription": "um but I have had a handful of days at my company that where you know my blackness wass, you know, bleeding.",
    "google_transcription": "I have had a handful days at my company that where you know my blackness was you know I'm bleeding",
    "apple_transcription": "But I have had a handful of days at my company that where my blackness was bleeding",
    "clean_google_wer": 0.1905,
    "clean_ibm_wer": 0.1429,
    "clean_amazon_wer": 0.0952,
    "clean_msft_wer": 0.0476,
    "clean_apple_wer": 0.2381,
    "ddm": 0,
    "avg_wer": 0.1429
  },
  {
    "snippet": "DCB_se2_ag3_m_01_1_1145256_1166149.mp3",
    "wordcount": 60,
    "phon_count": 1,
    "phon_features": [
      "-ng as -n: doing"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "to lead, to speak, et cetera, to do these things that I try to help give kids now, that I love you know, doing so much with 'em now. Um, and then because of the art experience there, I was ready to go to /RD-SCHOOL-2/ gr- uh, interview for /RD-SCHOOL-2/ High School. Got into the both- the theater and the TV production apart- departments.",
    "age": 35,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "To lead to speak. Etc to do these things that I try to help give kids now that I love you. Know doing so much for them now. And then because of the art experience, there, I was ready to go. Interview for high school got into the both the theater eniti reproduction apart Department.",
    "ibm_transcription": "so to speak etcetera to do these things that I try to help give kids now that I love you know don't want so much for them now %HESITATION and then because our experience there I was ready to go great our interview for our school got into the both the theater and the TV production apart department",
    "amazon_transcription": "toe lied to speak et cetera, to do these things that I tried to help give kids. Now that I love, you know, doing so much for them now. Um, and then because of the art experience there, I was ready to go. The great R interview for Bicycle got into the boat theater and the TV production of art department.",
    "google_transcription": "to lead to speak excetera to do these things that I try to help him kids now that I love you know and then because of the art experience there I was ready to go interview for high school got into the both the theater and the TV production of part to part my",
    "apple_transcription": "To lead to speak etc. to do these things that I've tried to help him get his now that I love so much with them now and then because the art experience there I was ready to go to interview for the hospital gallon to the both the theater and a TV production of art department",
    "clean_google_wer": 0.2667,
    "clean_ibm_wer": 0.25,
    "clean_amazon_wer": 0.25,
    "clean_msft_wer": 0.1833,
    "clean_apple_wer": 0.35,
    "ddm": 0.0167,
    "avg_wer": 0.26
  },
  {
    "snippet": "DCB_se3_ag3_m_02_1_1405037_1410155.mp3",
    "wordcount": 21,
    "phon_count": 2,
    "phon_features": [
      "th stopping: the",
      "-ng as n: going"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 2,
    "content": "Uh, you know what the biggest difference is gonna be? He got somebody that's unselfish that will give him the ball.",
    "age": 40,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "You know the biggest difference is going to be, he got somebody this unselfish that will give him the ball.",
    "ibm_transcription": "%HESITATION you know the biggest difference is going to be he got somebody this unselfish that will give him the ball",
    "amazon_transcription": "you know, the biggest difference is going to be. He got somebody that's unselfish that will give him the ball.",
    "google_transcription": "you know the biggest difference is going to be he got somebody that's unselfish that will give him the ball",
    "apple_transcription": "You know the biggest difference is going to be he got somebody that's on sale for",
    "clean_google_wer": 0.0476,
    "clean_ibm_wer": 0.0952,
    "clean_amazon_wer": 0.0476,
    "clean_msft_wer": 0.0952,
    "clean_apple_wer": 0.381,
    "ddm": 0.0952,
    "avg_wer": 0.1333
  },
  {
    "snippet": "DCB_se3_ag3_f_02_1_688108_699492.mp3",
    "wordcount": 37,
    "phon_count": 1,
    "phon_features": [
      "l vocalization: well"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "Oh high school was very fun. I was pretty much in everything, I was on- well, I started debate team in eighth grade. I was on debate team, <ts> I was in ROTC the whole time. Um, drama club",
    "age": 39,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "Oh, I think is very fun. I was pretty much in everything I was on I started debating the degree wasn't beating I was in RCC the whole time. Drama club.",
    "ibm_transcription": "it was very fun I was pretty much and everything else is on what's not debating a free as in the rating of the RCC the whole time %HESITATION drama club",
    "amazon_transcription": "I'll have. It was very fun. I was pretty much in everything I was on. Well, I started debate Team A three. Hasn't Gaetane have the r l C c the whole time? Um, drama Club.",
    "google_transcription": "Arthur was right front I was pretty much everything I was on what side of the bay team 8th grade I was in the bay team house in rlcc the whole time drama club",
    "apple_transcription": "I was my phone I was pretty much in everything I was on what I'm debating Avery as in B team have the RCC the whole time drama club",
    "clean_google_wer": 0.4865,
    "clean_ibm_wer": 0.6216,
    "clean_amazon_wer": 0.4054,
    "clean_msft_wer": 0.4054,
    "clean_apple_wer": 0.5405,
    "ddm": 0.027,
    "avg_wer": 0.4919
  },
  {
    "snippet": "DCB_se3_ag4_f_02_1_1043988_1079568.mp3",
    "wordcount": 92,
    "phon_count": 3,
    "phon_features": [
      "final consonant deletion: neighborhood, neighborhood, heard"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 3,
    "content": "And that was a short span of her life, she was very young- that she lived in a neighborhood where she went to school. So I had a- I grew up in a very robust neighborhood, with children, lots of children. And we um, <ts> played outside a lot, and as you probably heard from older people, we could go outside and play forever until it was dark. I mean that stuff about the- the um- What do you call it? The night light? The um, <ts> playing until the- til the- the- the lights came on",
    "age": 54,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "And that was a short span of her life, she was very young that she lived in. A neighborhood where she went to school so. I had a group in a very robust neighborhood with children lots of children. An we Played outside a lot and as you probably heard from older people, we could go outside and play forever. Until it was dark, I mean, this stuff about the the what you call it. The night light the? Playing until the lights came on.",
    "ibm_transcription": "there was a short span of her life she was very young they she lived in a neighborhood where she went to school so I had a I grew up in a very robust neighborhood with children lots of children and we arm played outside a locked in as you probably heard from older people we could go outside and play forever until it was dark I mean this stuff about that the %HESITATION we call it the night light DM playing until the two of the the the light came on",
    "amazon_transcription": "and that was a short span of her life. She was very young that she lived in a neighborhood where she went to school. So I had a group in a very robust neighborhood with children, lots of children. And we are played outside a lot. And as you probably heard from older people, we could go outside and play forever until it was dark. I mean, that stuff about that the what you call it, the night light, the playing until the lights came on.",
    "google_transcription": "and I was a short span of her like she was very young that she lived in a neighborhood where she went to school so I had a I grew up in a very robust neighborhood with children lots of children and we are played outside a locked and is you probably heard from older people we could go outside and play Forever until it was dark I mean that stuff about the the we call it the night-light playing two to the the lights came on",
    "apple_transcription": "And I was a short span of her life she was very young that she lived in a neighborhood where she went to school so I had a group in a very robust neighborhood with children lots of children and we played outside a lot and as you probably heard from older people we could go outside and play forever until it was dark I mean the stuff about the D Young will Collett the night light DM playing until the two of them but the lights came on",
    "clean_google_wer": 0.1413,
    "clean_ibm_wer": 0.163,
    "clean_amazon_wer": 0.1087,
    "clean_msft_wer": 0.1087,
    "clean_apple_wer": 0.1739,
    "ddm": 0.0326,
    "avg_wer": 0.1391
  },
  {
    "snippet": "ROC_se0_ag1_f_02_1_1117598_1130479.mp3",
    "wordcount": 46,
    "phon_count": 2,
    "phon_features": [
      "final stop devoicing: build",
      "monopthongization: i'm"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 2,
    "content": "like three or four people that I knew in college, like, pretty well. And I had no idea that they were there. And I had just got to DC, so I was trying to kind of build my network, of people. Um, so I'm glad I went,",
    "age": 28,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "Like 3 or 4 people that are new in college like pretty well and I had no idea that they were there and I had just got to DC so I was trying to kind of built my network, people so I'm glad I.",
    "ibm_transcription": "three or four people that I knew in college like pretty well and I had no idea that they were there and I had just gotten to DC is always trying to kind of build my network of people and so I'm glad I went",
    "amazon_transcription": "like three or four people that I I knew in college, like pretty well, and I had no idea that they were there and I had just got to d. C. So I was trying to kind of build my network of people from some Glad I went.",
    "google_transcription": "like three or four people that I knew in college I pretty well and I had no idea that they were there and I had just got to DC's I was trying to kind of built my network of people from so I'm glad I want",
    "apple_transcription": "Like three or four people that are new and colleagues it pretty well and I had no idea that they were there and I had just got to DC as I was trying to kind of build my network of people and so I'm glad I went",
    "clean_google_wer": 0.1304,
    "clean_ibm_wer": 0.1304,
    "clean_amazon_wer": 0.1087,
    "clean_msft_wer": 0.1087,
    "clean_apple_wer": 0.1522,
    "ddm": 0.0435,
    "avg_wer": 0.1261
  },
  {
    "snippet": "DCB_se3_ag4_m_01_1_1148305_1163197.mp3",
    "wordcount": 40,
    "phon_count": 3,
    "phon_features": [
      "-ng as -n: listening, saying",
      "pin pen merger: IN"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 3,
    "content": "You know, listening to my daughter that that phrase comes up like six, seven times in a course of a conversation with her. And there's a lot of stuff in, you know what I'm saying, that you never get to",
    "age": 60,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "You know listening to my daughter that phrase comes up like 6 seven times in the course of a conversation where there and there's a lot of stuff in you know what I'm saying. That you never. Get to",
    "ibm_transcription": "you know listening to my daughter that that phrase comes up like six seven times in the course of a conversation with and there's a lot of stuff in you know what I'm saying that you never get to",
    "amazon_transcription": "you know, listening to my daughter that that phrase comes up, like 67 times in the course of a conversation with and there's a lot of stuff. And you know what I'm saying that you never get two.",
    "google_transcription": "listening to my daughter that that phrase comes up like six seven times in the course of a conversation with her and there's a lot of stuff and you know what I'm saying that you never get to",
    "apple_transcription": "You know listening to my daughter that that phrase comes up like 67 times in the course of the conversation with her and there's a lot of stuff and you know what I'm saying that you never get",
    "clean_google_wer": 0.1,
    "clean_ibm_wer": 0.05,
    "clean_amazon_wer": 0.125,
    "clean_msft_wer": 0.1,
    "clean_apple_wer": 0.125,
    "ddm": 0.075,
    "avg_wer": 0.1
  },
  {
    "snippet": "ROC_se0_ag2_f_03_1_1750135_1777007.mp3",
    "wordcount": 56,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "And even within our communities, even gay black men versus, um, g- um, gay queer black women there's still some, you know, differences. There's still, um, some needs to- some need to have separate spaces, or spaces that satisfy both. You know, and so, um, again, I think /RD-NAME-3/ gets it, and he's trying to p- create those spaces, you know.",
    "age": 39,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "And even within our communities, even gay black men versus gay. Queer black women there. Still, some differences there. Still, some needs to some need to have separate spaces, or spaces that satisfy both you know, and so again. I think gets it and he's trying to create those spaces, you know.",
    "ibm_transcription": "and even within our communities even gate black men verses gay queer black women there still some you know differences there still %HESITATION some needs to some need to have separate spaces or spaces that satisfy both you know and so %HESITATION again I think gets it and he's trying to create those spaces you know",
    "amazon_transcription": "and even within our communities, even gay black men versus gay, queer black women, they're still some, you know, difference. Is there still some needs to? Some need to have separate spaces or spaces that satisfy both, you know, And so again, I think gets it, and he's tryingto create those spaces, you know?",
    "google_transcription": "and even within our communities even gay black man versus gay queer black women there still some you know differences there still some needs to some need to have separate spaces or spaces that satisfy both you know and so again I think gets it and he's trying to create those spaces you know",
    "apple_transcription": "And even with in our community is even gay black man versus gay queer black women there still some in our differences there still some needs to be some need to have separate spaces or spaces that satisfy both you know and so again I think he gets it and he's trying to create those spaces you know",
    "clean_google_wer": 0.0893,
    "clean_ibm_wer": 0.1071,
    "clean_amazon_wer": 0.1429,
    "clean_msft_wer": 0.1071,
    "clean_apple_wer": 0.2321,
    "ddm": 0,
    "avg_wer": 0.1357
  },
  {
    "snippet": "PRV_se0_ag3_m_01_2_1147331_1160623.mp3",
    "wordcount": 49,
    "phon_count": 6,
    "phon_features": [
      "CCR: and",
      "final consonant deletion after vowel: said, but",
      "monopthongization: i'm",
      "-ng to -n: concentrating",
      "intial g deletion: im going to do"
    ],
    "gram_count": 2,
    "gram_features": [
      "copula absence: what you going to sing",
      "verb stem as past tense: say"
    ],
    "total_count": 8,
    "content": "And she say, I said, well I'm- I'm just concentrating. I said but I'm alright. She said have you decided what you gonna sing? I say um, <ts> I got two or three things um, that I would like to do but I don't know which one I'm gonna do.",
    "age": 64,
    "source": "PRV",
    "gender": "Male",
    "msft_transcription": "She say's well, I'm I'm just concentrating us for tomorrow. She's have you decided what you're gonna sing? I Sam? I got 2 three things and I would like to do, but I don't know which one will do.",
    "ibm_transcription": "this is a as William I'm just concentrating this remark to serve you decide which you can sing I say %HESITATION I got two three things on that I would like to do but I don't know which one will do",
    "amazon_transcription": "and she'd say, That's well, I'm just concentrating. That's what Amara she's have you decided what you're going to sing? I say I got two or three things that I would like to do, but I don't know which one will do.",
    "google_transcription": "she say I'm just concentrating that's what I'm all right just have you decided what you going to say Isaiah I got two or three things that I would like to do but I don't know which what I'm going to do",
    "apple_transcription": "She say as well am I'm just concentrating mess with them or just have you decided what you're going to sing as I am I got to three things on but I would like to do but I don't know which one will do",
    "clean_google_wer": 0.2857,
    "clean_ibm_wer": 0.4898,
    "clean_amazon_wer": 0.3061,
    "clean_msft_wer": 0.3673,
    "clean_apple_wer": 0.4286,
    "ddm": 0.1633,
    "avg_wer": 0.3755
  },
  {
    "snippet": "PRV_se0_ag1_f_02_2_173701_179076.mp3",
    "wordcount": 18,
    "phon_count": 2,
    "phon_features": [
      "final consnant deletion: it's",
      "monopthongization: my"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 2,
    "content": "And it's like, my dad's family is even close with my family because they all grew up together.",
    "age": 21,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "My day is families, even closed with my family because they all grew up together.",
    "ibm_transcription": "but my dad's family even close with my family because they all work together",
    "amazon_transcription": "but my dad's families even close with my family because they all grew up together.",
    "google_transcription": "but my dad's family is even close with my family because they all grew up together",
    "apple_transcription": "But my day is family even close with my family because they all work together",
    "clean_google_wer": 0.1667,
    "clean_ibm_wer": 0.3333,
    "clean_amazon_wer": 0.2778,
    "clean_msft_wer": 0.3889,
    "clean_apple_wer": 0.4444,
    "ddm": 0.1111,
    "avg_wer": 0.3222
  },
  {
    "snippet": "ROC_se0_ag3_f_02_1_3084760_3095984.mp3",
    "wordcount": 30,
    "phon_count": 1,
    "phon_features": [
      "CCR: liked"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "before you get to the concession stand. And then you could go in and find a seat. And I liked cause I could sit upstairs or I could sit downstairs.",
    "age": 80,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "Before, you get to the concession stand and then you could go in and find a seat. An I like azaka sit upstairs or I could sit down stairs.",
    "ibm_transcription": "before you get to the concession stand and then you can go in and find a seat and I like because opposite up stairs or at the sit downstairs",
    "amazon_transcription": "before you get to the concession stand, and then you could go in and find a seat. And I like cause I could sit upstairs or I could sit downstairs.",
    "google_transcription": "before you get to the concession stand and then you could go in and find a seat and I like cuz I could sit upstairs or I could sit downstairs",
    "apple_transcription": "Before you get to the concession stand and then you can go in and find a seat and I like is at the sit upstairs or add to sit downstairs",
    "clean_google_wer": 0.0333,
    "clean_ibm_wer": 0.3,
    "clean_amazon_wer": 0.0333,
    "clean_msft_wer": 0.2333,
    "clean_apple_wer": 0.2333,
    "ddm": 0.0333,
    "avg_wer": 0.1667
  },
  {
    "snippet": "PRV_se0_ag3_m_03_1_1708563_1714667.mp3",
    "wordcount": 14,
    "phon_count": 6,
    "phon_features": [
      "final consonant deletion: that's, what, was",
      "monopthongization: I",
      "unstressed initial syllable deletion: about",
      "dress syllable raising: telling"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 6,
    "content": "Uh, n- that's what I was telling you about in the houses /that way up/",
    "age": 83,
    "source": "PRV",
    "gender": "Male",
    "msft_transcription": "Uh. There's a lot to learn about in the House and now we have.",
    "ibm_transcription": "%HESITATION in there there are kidding about in the house and now we have",
    "amazon_transcription": "Yeah, and they tell you about the house that we have?",
    "apple_transcription": "Live in Academy but in the hall now we are",
    "clean_google_wer": 1,
    "clean_ibm_wer": 0.8571,
    "clean_amazon_wer": 0.7143,
    "clean_msft_wer": 0.8571,
    "clean_apple_wer": 0.8571,
    "ddm": 0.4286,
    "avg_wer": 0.8571
  },
  {
    "snippet": "DCB_se1_ag3_f_01_1_94738_99958.mp3",
    "wordcount": 7,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "<laugh> And high school, I went to /RD-SCHOOL-2/ and /RD-SCHOOL-5/.",
    "age": 42,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "An high school, I went to. Then.",
    "ibm_transcription": "and high school I went to",
    "amazon_transcription": "Yeah. And high school. I went to MIT.",
    "google_transcription": "and high school I went to",
    "apple_transcription": "And high school I went to bed",
    "clean_google_wer": 0.1429,
    "clean_ibm_wer": 0.1429,
    "clean_amazon_wer": 0.2857,
    "clean_msft_wer": 0.2857,
    "clean_apple_wer": 0.1429,
    "ddm": 0,
    "avg_wer": 0.2
  },
  {
    "snippet": "ROC_se0_ag3_f_02_1_1615257_1622185.mp3",
    "wordcount": 19,
    "phon_count": 1,
    "phon_features": [
      "r-less after vowel: or"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "or we'd stay at my house. And that's the way we grew up, so we grew up like sisters.",
    "age": 80,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "We stay at my house. Then there's the way we grew up. So we grew up like sisters.",
    "ibm_transcription": "we stay at my house then as the way we grew up so we grew up like sisters",
    "amazon_transcription": "So we stay at my house and that's the way we grew up, so we grew up like sisters.",
    "google_transcription": "always stay at my house and that's the way we grew up so we grew up like sisters",
    "apple_transcription": "I always stay at my house and that's the way we grew up so we grew up like sisters",
    "clean_google_wer": 0.1053,
    "clean_ibm_wer": 0.2105,
    "clean_amazon_wer": 0.1053,
    "clean_msft_wer": 0.2105,
    "clean_apple_wer": 0.1053,
    "ddm": 0.0526,
    "avg_wer": 0.1474
  },
  {
    "snippet": "DCB_se2_ag2_m_01_1_2643045_2659720.mp3",
    "wordcount": 59,
    "phon_count": 7,
    "phon_features": [
      "-ng as -n: going",
      "monopthongization: I, I, I, I, my, my",
      "th stopping: that"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 7,
    "content": "That's a story within itself. <laugh> I have two- two little girls by two different uh, mothers. So that- that- that set me back a little bit from- from going back to school because I- I was- I was ready to go back to school but /had them/ babies, and that- that took my- My m- My money went to that,",
    "age": 27,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "There's a story within itself. Have 2 two little girls by 2 different models. So that that that's me back a little bit from from going back to school because I I was, I was really go back to school when. Having babies and that that's my money went to that.",
    "ibm_transcription": "it's a story within itself have to two little girls by two different %HESITATION mothers so that that that seven back a little bit from from going back to school because I I was I was ready to go back to school but Hannah babies and that that's a mom mom mom money went to that",
    "amazon_transcription": "that's a story within itself. Have two little girls by two different mothers. So that that set me back a little bit from from going back to school, because I was I was ready to go back to school but had babies, and that that's my money went to that.",
    "google_transcription": "that's a story within itself have to two little girls by two different mothers so that that that set me back a little bit from going back to school because I was I was ready to go back to school but having babies and that that's at my my my money went to that",
    "apple_transcription": "That's the story with Anna so I have two total girls by two different mothers saw that that that set me back a little bit from for going back to school because I was I was very go by Taco Bell and the babies and that's what my mom my money went today",
    "clean_google_wer": 0.1525,
    "clean_ibm_wer": 0.2203,
    "clean_amazon_wer": 0.1695,
    "clean_msft_wer": 0.2542,
    "clean_apple_wer": 0.4068,
    "ddm": 0.1186,
    "avg_wer": 0.2407
  },
  {
    "snippet": "PRV_se0_ag3_m_03_1_1067264_1076116.mp3",
    "wordcount": 19,
    "phon_count": 5,
    "phon_features": [
      "final consonant deletion: stayed",
      "r less after vowel: for",
      "unstressed initial syllable deletion: about, about",
      "r-less intersyllabic: from,"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 5,
    "content": "Well, I s- s- stayed single for about- from- from- I stayed single I reckon about six, seven years.",
    "age": 83,
    "source": "PRV",
    "gender": "Male",
    "msft_transcription": "Will stay single for about? From after saying all ring about 6:00 AM.",
    "ibm_transcription": "Mayo please hang up about that's the thing %HESITATION ring about sixty million",
    "amazon_transcription": "well, they think about sing a ring about 16 years.",
    "google_transcription": "will I be single for about",
    "apple_transcription": "Please think of about coming home after you saying a ring about 16 menu",
    "clean_google_wer": 0.7895,
    "clean_ibm_wer": 0.8947,
    "clean_amazon_wer": 0.7895,
    "clean_msft_wer": 0.6842,
    "clean_apple_wer": 0.8947,
    "ddm": 0.2632,
    "avg_wer": 0.8105
  },
  {
    "snippet": "ROC_se0_ag2_f_03_1_211669_223270.mp3",
    "wordcount": 36,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "I'd love for businesses to be in the city. And we deserve good restaurants, and, you know, they all move out of our neighborhoods. And, now that I see what's happening, I'm like, wait a minute. <laugh>",
    "age": 39,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "I'd love for businesses to be in the city and we deserve good restaurants and you know they all move out of our neighborhoods and now that I see what's happening. I'm like wait a minute.",
    "ibm_transcription": "I'd love for businesses to be in the city and we deserve good restaurants and you know they all move out of our neighborhoods and now that I see what's happening I'm like wait a minute",
    "amazon_transcription": "I'd love for businesses to be in the city and we deserve good restaurants and, you know, they all move out of our neighborhoods. And now that I see what's happening, I'm like, Wait a minute.",
    "google_transcription": "I'd love for businesses to be in the city and we deserve good restaurants and you know they all move out of our neighborhoods and now that I see what's happening I'm like wait a minute",
    "apple_transcription": "I'd love for businesses to be in the city and we deserve good restaurants and you know they all move out of our neighborhoods and now that I see what's happening I'm like wait a minute",
    "clean_google_wer": 0,
    "clean_ibm_wer": 0,
    "clean_amazon_wer": 0,
    "clean_msft_wer": 0,
    "clean_apple_wer": 0,
    "ddm": 0,
    "avg_wer": 0
  },
  {
    "snippet": "PRV_se0_ag3_f_02_2_1292288_1297951.mp3",
    "wordcount": 18,
    "phon_count": 2,
    "phon_features": [
      "final stop devoicing: found",
      "-ng to -n: during"
    ],
    "gram_count": 1,
    "gram_features": [
      "was-were leveling: kids… that was in"
    ],
    "total_count": 3,
    "content": "And you know, we- we found kids during that time years ago that was in really bad shape",
    "age": 40,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "And you know, we, we found kids doing at time years ago that was in really bad shape.",
    "ibm_transcription": "and you know we we found kids during that ten years ago that was in really bad shape",
    "amazon_transcription": "And, you know, we found kids doing that time years ago. That was in really bad shape.",
    "google_transcription": "and you know we we found kids doing that time years ago that was in really bad shape",
    "apple_transcription": "And you know we we found kids doing it 10 years ago and it was in really bad shape",
    "clean_google_wer": 0.0556,
    "clean_ibm_wer": 0.0556,
    "clean_amazon_wer": 0.1111,
    "clean_msft_wer": 0.1111,
    "clean_apple_wer": 0.2778,
    "ddm": 0.1667,
    "avg_wer": 0.1222
  },
  {
    "snippet": "ROC_se0_ag1_m_01_1_1473998_1493891.mp3",
    "wordcount": 50,
    "phon_count": 5,
    "phon_features": [
      "CCR: first,",
      "l vocalizaiton after vowel: well",
      "merging of tense and lax vowels before L: calculus",
      "-ng as -n: sitting",
      "intial d deletion: we don't"
    ],
    "gram_count": 1,
    "gram_features": [
      "multiple negation: not really learning nothing"
    ],
    "total_count": 6,
    "content": "then I'll teach you calculus. And I'm like, okay so first, you know, I'm not really learning nothing because I'm not teaching well. But eventually, um, he taught me- he taught me calculus and I'm sitting here I'm like, okay, (whispered) derivatives huh, okay. And, um, the thing about /RD-SCHOOL-4/ is we don't take Regents.",
    "age": 24,
    "source": "ROC",
    "gender": "Male",
    "msft_transcription": "Then I'll teach you calculus and I'm like OK, so firstly. No, not really learning nothing because I'm not teaching well, but eventually he taught me taught me calculus and I'm sitting here and like OK derivatives. OK, an the thing about it is, we don't take regents.",
    "ibm_transcription": "then I'll teach you calculus I'm like okay so first you know I'm not really learning nothing because I'm not he did well but even to the %HESITATION he taught me tell me countless and I'm sitting here I'm like okay if it is on okay and %HESITATION the thing about but Israel take regents",
    "amazon_transcription": "then I'll teach you calculus. I'm like, Okay, so, Fergus, you know, I'm not really learning nothing, because I'm not teaching. What? But eventually he taught me. He taught me calculus. And I'm sitting here. I'm like, Okay, the revenues. Okay. And, um, the thing about it is beyond take regions.",
    "google_transcription": "send out teaching calculus I'm like okay so first you know I'm not really learning nothing cuz I'm not teaching but eventually he taught me taught me calculus and I'm sitting here in like okay or if it is okay and the thing about it is we don't take regents",
    "apple_transcription": "Then I'll teach you calculus and I'm like OK so first and I'm not really learning nothing because I'm not eating well but eventually he taught me and tell me how who is it I'm sitting here like OK derivatives OK and I am the thing about it is we don't take regents",
    "clean_google_wer": 0.28,
    "clean_ibm_wer": 0.32,
    "clean_amazon_wer": 0.18,
    "clean_msft_wer": 0.16,
    "clean_apple_wer": 0.26,
    "ddm": 0.12,
    "avg_wer": 0.24
  },
  {
    "snippet": "PRV_se0_ag3_m_01_1_1266334_1283477.mp3",
    "use": false,
    "attribution": "coraal",
    "wordcount": 34,
    "phon_count": 3,
    "phon_features": [
      "monopthong: I",
      "kit vowel raising: hill",
      "final consonant deletion: would"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 3,
    "annotations": [
      {
        "start": 28,
        "end": 32,
        "content": "Glide reduction /ai/"
      },
      {
        "start": 68,
        "end": 72,
        "content": "/Kit/ vowel raising"
      },
      {
        "start": 159,
        "end": 164,
        "content": "Final consonant deletion"
      }
    ],
    "content": "Well, this place, from what I've been told, um, f- w- was first called Freedom Hill and um, I can imagine my great great grandparents were part of those um, first freed um, slaves that would settle here.",
    "real_transcription": "Well, this place, from what I’ve been told was first called Freedom Hill. I can imagine my great great grandparents were part of those first freed slaves that would settle here.",
    "asr_transcription": "Well, this place, from what I’ve been told was first called Freedom. Hell in I can imagine my great great grand parents were part of those first read slaves that would settle here.",
    "age": 64,
    "source": "PRV",
    "gender": "Male",
    "msft_transcription": "Well, this place from what I've been told first call Freedom Hill. Ann. I can imagine my great great grandparents were part of those first freed. Slaves that was subtle here.",
    "ibm_transcription": "well this place from what I've been told %HESITATION was first call freedom he'll and %HESITATION I can imagine my great great grandparents were part of those first three %HESITATION slaves that was seven here",
    "amazon_transcription": "Well, this place, from what I've been told was first called Freedom. Hell in I can imagine my great great grand parents were part of those first read slaves that would settle here.",
    "google_transcription": "this place from what I've been told to call Freedom Hill and I can imagine my great-great-grandparents were part of those first freed slaves that was subtle here",
    "apple_transcription": "Well this place from what I've been told I was first called freedom Hill and I can imagine my great great grandparents were part of the reason for his freedom of slaves that were settled here",
    "clean_google_wer": 0.2353,
    "clean_ibm_wer": 0.2059,
    "clean_amazon_wer": 0.2059,
    "clean_msft_wer": 0.2059,
    "clean_apple_wer": 0.2941,
    "ddm": 0.0882,
    "avg_wer": 0.2294
  },
  {
    "snippet": "ROC_se0_ag1_f_01_1_2685915_2720794.mp3",
    "wordcount": 96,
    "phon_count": 2,
    "phon_features": [
      "r-less after vowel: here",
      "ccr neg: aren't"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 2,
    "content": "about. <laugh> So, it depends, um, but I think it- here I feel like a lot of people are from down south. A lot of people have come up to Rochester in particular because of job opportunities and different things at the time, so most of everybody I know has roots somewhere else. They aren't actually born here. I can't trace anybody back that I know of that was just- has always been here, which makes sense in history. <ts> Unless somebody's family was an indentured servant, I don't really know anybody that was born and just bred here.",
    "age": 26,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "About so it depends but I think it here. I feel like a lot of people are from Donald Styles, a lot of people have come up to Rochester in particular because of job opportunities in different things. At the time so most of everybody. I know has roots somewhere else. They are actually morning. I can't trace anybody back that I know of that was just has always been here, which. Makes sense in history. Spend less inviting family was an indentured servant. I don't really know anybody that was worn and just bread here.",
    "ibm_transcription": "about so it depends %HESITATION back the gate here are but a lot of people are from down south a lot of people have come up to Rochester in particular because of job opportunities and different things at the time so most of everybody I know has a room somewhere else they are actually morning I can't trace anybody bag that I know of that was just has always been here which makes sense in history unless somebody's family was an indentured servant I don't really know anybody that was born in just bread here",
    "amazon_transcription": "about, so it depends, but I think it here. I feel like a lot of people are from Donald style. A lot of people have come up to Rochester in particular because of job opportunities and different things at the time. So most of everybody I know has roots somewhere else. They are actually morning. I can't trace anybody back That I know of that was just has always been here, which makes sense in history unless somebody's family was an indentured servant. But I don't really know anybody that was born and just bread here.",
    "google_transcription": "about it depends but I think it's here I feel like a lot of people are from down south a lot of people have come up to Rochester in particular because of job opportunities in different things at the time so most of everybody I know has roots somewhere else they are actually born and I can't trace anybody back that I know of that was just has always been here which makes sense in history unless somebody's family was an indentured servant I don't really know anybody that was born and just bred here",
    "apple_transcription": "About it in pen that they get here I feel like a lot of people are from down south a lot of people have come up to Rochester in particular because of job opportunities and different things at a time so most of everybody I know has a route somewhere else they are actually more and I can't trace anybody back that I know of that was just has always been here which makes sense and his three my lesson by his family was an indentured servant I don't really know anybody that was warning just bread here",
    "clean_google_wer": 0.0521,
    "clean_ibm_wer": 0.1562,
    "clean_amazon_wer": 0.0729,
    "clean_msft_wer": 0.1146,
    "clean_apple_wer": 0.2292,
    "ddm": 0.0208,
    "avg_wer": 0.125
  },
  {
    "snippet": "DCB_se3_ag3_f_02_1_1643640_1664539.mp3",
    "wordcount": 67,
    "phon_count": 3,
    "phon_features": [
      "final consonant deletion: like, god",
      "th stopping: this"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 3,
    "content": "And they feel embarrassed because they're like, god, like this- It's embarrassing when they know that you know what they've been doing. Um, <ts> those stories are always the funniest because they- that's when I learned that people can be very creative with their stories (laughing) when they come up with things. Even when you say, it's on video. Oh, that's not me. I'm like, like Shaggy? That's not you?",
    "age": 39,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "And they feel embarrassed because if I got like this is embarrassing when they know that you know what they've been doing those stories are always the funniest because they that's not around that people could really creative with their stories when they come up with things even when you say. FM video oh that's not me and my shaggy.",
    "ibm_transcription": "and they feel embarrassed because if I can this is a dozen when they know that you know what they've been doing %HESITATION those stories always the funniest because they that's when I learned that people can be very creative with their stories may come up with things even when you say this on video all that's not me and my my psyche the value",
    "amazon_transcription": "and they feel embarrassed because I just isn't everything when they know that you know what they've been doing. Those stories are always the funniest, because they that's when I learned that people can be creative with their stories when they come up with things. Even when you say it's a video. Oh, that's not me and my, my shaggy.",
    "google_transcription": "and they feel embarrassed because of right when they know that you know what they've been doing those stories are always the funniest because they that's what I learned that people can be very creative with their stories when they come up with things even when you say that's a video that's not me and like by Shaggy",
    "apple_transcription": "And they feel embarrassed because of a cat like this if it doesn't when I know that you know where they've been doing on those stories are always the funniest because they that's an alert that people have very creative with their stories and they come up with things even when you say if I video oh that's not me on my way shaggy",
    "clean_google_wer": 0.2388,
    "clean_ibm_wer": 0.2836,
    "clean_amazon_wer": 0.2239,
    "clean_msft_wer": 0.2687,
    "clean_apple_wer": 0.3433,
    "ddm": 0.0448,
    "avg_wer": 0.2716
  },
  {
    "snippet": "DCB_se3_ag4_f_02_1_1644310_1671638.mp3",
    "wordcount": 85,
    "phon_count": 4,
    "phon_features": [
      "final consonant deletion: it",
      "initial d deletion: I don’t know",
      "monopthognization: I",
      "unstressed syllable deletion: across"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 4,
    "content": "So it was a different- She wasn't as elitist. And then we had to catch the bus together because she lived on Capitol Hill in Southeast, and then I lived- But- And she lived in Southeast, but it was Capitol Hill. And Capitol Hill is of course, booming. Even back then it was pretty. I don't know, but Capitol Hill was weird. But- You know, and I used to have to go across the bridge, so we you know, got c- rode the bus together.",
    "age": 54,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "So it was a different, she wasn't as elitist. And then we had to catch the bus together, because she lived on Capitol Hill in South Easton, then I live but and she lives in SE but it was Capitol Hill. In Capitol Hill is of course, booming. Even back then, it was pretty I don't know, but Capitol Hill was weird, but You know, and I used to have to go across the bridge, so we You look at rode the bus together.",
    "ibm_transcription": "so was it different she wasn't as elitist and we had to catch the bus together because she lived on Capitol Hill and southeast and I live with and she lives in southeast but was Capitol Hill and Capitol Hill is of course booming even back then it was pretty I don't know Campbell who was clear but you know when I used to have to go across the bridge so we look at what the bus together",
    "amazon_transcription": "So it was a different. She wasn't as elitist and that we had to catch the bus together because she lived on Capitol Hill in Southeast. And then I lived with and she lived in Southeast but was Capitol Hill. And Capitol Hill is, of course, booming. Even back then it was pretty. I don't know, Capitol Hill was weird, but, you know, and I used to have to go across the bridge, so we, you know, God rode the bus together.",
    "google_transcription": "so was a different she wasn't as elitist and then we had to catch the bus together because she lived on Capitol Hill in southeast and then I live with and she live in southeast but it was Capitol Hill and Capitol Hill is of course booming even back then it was pretty I don't know it was weird but you know what I used to have to go cross the bridge so we rode the bus together",
    "apple_transcription": "So was it different she wasn't as elitist and we had to catch the bus together because she lived on Capitol Hill and Southeast and then I live with and she lives in Southeast but was Capitol Hill and Capitol Hill is of course booming even back then it was pretty I don't know Capitol Hill was weird but you know when I used to have to go cross the bridge so we rode the bus again",
    "clean_google_wer": 0.1529,
    "clean_ibm_wer": 0.2235,
    "clean_amazon_wer": 0.0706,
    "clean_msft_wer": 0.1059,
    "clean_apple_wer": 0.1882,
    "ddm": 0.0471,
    "avg_wer": 0.1482
  },
  {
    "snippet": "PRV_se0_ag3_f_04_3_72404_78650.mp3",
    "wordcount": 7,
    "phon_count": 2,
    "phon_features": [
      "final consonant deletion: but",
      "dress vowel raising: dead"
    ],
    "gram_count": 1,
    "gram_features": [
      "unstressed been: she been"
    ],
    "total_count": 3,
    "content": "but she been dead uh, right many years.",
    "age": 76,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "But she been dead. Uh. Right many years.",
    "ibm_transcription": "the freemen daisy it our right man a year",
    "amazon_transcription": "where she men day? Uh, right many years.",
    "google_transcription": "when she been dead write me in a year",
    "apple_transcription": "Where is she been dead right mean to you",
    "clean_google_wer": 0.8571,
    "clean_ibm_wer": 1.1429,
    "clean_amazon_wer": 0.4286,
    "clean_msft_wer": 0,
    "clean_apple_wer": 0.7143,
    "ddm": 0.4286,
    "avg_wer": 0.6286
  },
  {
    "snippet": "PRV_se0_ag3_f_02_1_141536_147530.mp3",
    "wordcount": 20,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "uh, my dad also had a garage and store, which was located in the area where the cement is out there.",
    "age": 40,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "My dad also had a garage and store, which was located in area with the same. It is out there.",
    "ibm_transcription": "%HESITATION my day and also had a garage and store which is located in an area with the same it is out there",
    "amazon_transcription": "um my dad also had a garage and store, which was located in an area with the same It is out there.",
    "google_transcription": "my dad also had a garage and store which was located in area with the semen is out there",
    "apple_transcription": "My dad also had a garage and store which is located in an area with the same it is up",
    "clean_google_wer": 0.15,
    "clean_ibm_wer": 0.35,
    "clean_amazon_wer": 0.2,
    "clean_msft_wer": 0.2,
    "clean_apple_wer": 0.35,
    "ddm": 0,
    "avg_wer": 0.25
  },
  {
    "snippet": "DCB_se3_ag3_f_02_1_2632973_2651557.mp3",
    "wordcount": 63,
    "phon_count": 4,
    "phon_features": [
      "fricative stopping: even",
      "intial d deletion: I don't know",
      "CCR: and, maryland"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 4,
    "content": "We just don't, it's like we don't even like Bal- (laughing) <laugh> I don't know, it's a thing. First of all, DC s- like, straight DC does not claim Baltimore at all. Like it's like, <laugh> they don't ever get along. But, um, <ts> I don't know, it's just a thing, like we like, mm-mm. People like, Baltimore, Maryland, no just Baltimore, Baltimore, we don't claim the step children (laughing) <laugh>",
    "age": 39,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "If I really like all other thing first of all DC like straight DC does not claim Baltimore at all like it's like they don't ever get along. But. I don't know it's just a thing like we like feel like Baltimore, MD. I'll just Baltimore, Baltimore. We don't claim stepchildren.",
    "ibm_transcription": "if I don't who I although the thing for the thieves by straight DC does not claim Baltimore at all like if I will ever get along but %HESITATION this is the thing I would like the light was one religious boss mobile to little claim step two is",
    "amazon_transcription": "just if I really knew. Like, although the thing First DC like straight d. C. There's not clean Boston little like it's like they don't ever get along. But I don't know, it's just a thing that we like. If you like Boston Memorial, just boss, my boss and, well, you know, claim Steptoe.",
    "google_transcription": "if I buy something for DC's by Street DC does not claim Baltimore at all like if they don't get along but it's a good thing I would like to bite Baltimore Maryland Baltimore Baltimore a climbing Step 2",
    "apple_transcription": "If I really like all other things first what do you favorite spice trade if he does not clean bottom at all like if I don't ever get along but I don't know that's the thing I would like the light wasn't on her a lot of balls in the bottom of it I'll climb steps",
    "clean_google_wer": 0.6508,
    "clean_ibm_wer": 0.6984,
    "clean_amazon_wer": 0.6032,
    "clean_msft_wer": 0.3175,
    "clean_apple_wer": 0.7937,
    "ddm": 0.0635,
    "avg_wer": 0.6127
  },
  {
    "snippet": "PRV_se0_ag2_f_02_1_2410419_2419278.mp3",
    "use": true,
    "attribution": "coraal",
    "wordcount": 26,
    "phon_count": 2,
    "phon_features": [
      "-ng to -n: living",
      "monopthongization: I"
    ],
    "gram_count": 2,
    "gram_features": [
      "copula absence: he deceased",
      "was-were levelling: they was the first"
    ],
    "total_count": 4,
    "annotations": [
      {
        "timeStart": 0,
        "timeEnd": 0.61294154371853,
        "timeCtxStart": 0,
        "timeCtxEnd": 2.652764705171475,
        "start": 0, // Manual
        "end": 11,
        "content": "Copula Absence"
      },
      {
        "timeStart": 2.0905519610254335,
        "timeEnd": 2.5951018596180346,
        "timeCtxStart": 0,
        "timeCtxEnd": 2.652764705171475,
        "start": 39,
        "end": 45,
        "content": "Realization of final -ng as -n"
      },
      {
        "timeStart": 2.9627025000212157,
        "timeEnd": 3.2870560062593164,
        "timeCtxStart": 2.652764705171475,
        "timeCtxEnd": 3.8564766060995375,
        "start": 47,
        "end": 50,
        "content": "Final Consonant Cluster Reduction"
      },
      {
        "timeStart": 5.0385649399450605,
        "timeEnd": 5.492659848678401,
        "timeCtxStart": 4.151998689560918,
        "timeCtxEnd": 5.507075560066761,
        "start": 84,
        "end": 87,
        "content": "Generalization of \"was\" to use with second person subjects"
      },
      {
        "timeStart": 8.339762847879507,
        "timeEnd": 8.859002267573697,
        "timeCtxStart": 5.680064096727082,
        "timeCtxEnd": 8.859002267573697,
        "start": 115,
        "end": 120,
        "content": "Past participle as past tense"
      }
    ],
    "content": "He deceased now, but his wife is still living. And, uh, tell you the truth, I think that was the first black doctor that I ever known.",
    "real_transcription": "He deceased now, but his wife is still living. And tell you the truth, I think they was the first black doc I ever known.",
    "asr_transcription": "EDC's number his wife is still live. Ann. Teacher Birthday Day was the first played out that yeah. No.",
    "age": 48,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "EDC's number his wife is still live. Ann. Teacher Birthday Day was the first played out that yeah. No.",
    "ibm_transcription": "EDCs numbers wives delivery and %HESITATION H. about that day was the first black dog that none",
    "amazon_transcription": "he disease number his wife still living. And, uh, teacher? Nothing. They was the first flight out. I am. No.",
    "google_transcription": "etc's number is wife still living and what's the first black no",
    "apple_transcription": "It is his numbers wise still live in Anna and I was the first place",
    "clean_google_wer": 0.7308,
    "clean_ibm_wer": 0.7692,
    "clean_amazon_wer": 0.6154,
    "clean_msft_wer": 0.6923,
    "clean_apple_wer": 0.7692,
    "ddm": 0.1538,
    "avg_wer": 0.7154
  },
  {
    "snippet": "PRV_se0_ag3_m_03_1_1412830_1418584.mp3",
    "wordcount": 25,
    "phon_count": 6,
    "phon_features": [
      "monopthong: I",
      "th stopping: there",
      "r-less after vowel: for, before, before",
      "l vocalizaiton: pull"
    ],
    "gram_count": 1,
    "gram_features": [
      "copula absence: but i there"
    ],
    "total_count": 7,
    "content": "Mm-hm. When I g- when- you know, but- but I there for a little before I was twenty, see. They pull me before I got twenty.",
    "age": 83,
    "source": "PRV",
    "gender": "Male",
    "msft_transcription": "We're not you know, but I would dare build little bit pull over to anything you put forgot.",
    "ibm_transcription": "when I went you know but that would be a bit a little bit forward to anything you put him well got to an",
    "amazon_transcription": "You know what? I would have been a little bit for over 20. You pullem forgot twins.",
    "google_transcription": "when you know",
    "apple_transcription": "When I grab you know what I got",
    "clean_google_wer": 0.88,
    "clean_ibm_wer": 0.76,
    "clean_amazon_wer": 0.8,
    "clean_msft_wer": 0.8,
    "clean_apple_wer": 0.76,
    "ddm": 0.28,
    "avg_wer": 0.8
  },
  {
    "snippet": "ROC_se0_ag2_f_04_1_469678_515980.mp3",
    "wordcount": 169,
    "phon_count": 1,
    "phon_features": [
      "r-less after vowel: mother"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "I tease him that he should have, uh, when I first met him, I thought his family was West Indian cause, you know, I wasn't sophisticated enough to be able to tell dialects at that time, and I only knew Florida and Georgia, and that's a very different sounding, um, tone. So when I first heard his mother, and his- her- his uncle speak, and she would say things, and I was like, is his mother West Indian? And then I found out they're from South Carolina, and then just like Daughters of the Dust and, um, Mama Day, and reading about the, ugh- those Sea Coast Islands and just really being caught up in the beauty of the language. So I tease him that he probably shoulda been educated as an ESL student because in many ways his- his parents have this heavy- hi- his mother and his siblings- I mean her siblings have this heavy speech pattern that I hear him say tha- I'm like, yeah you know. I said, really.",
    "age": 43,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "I tease him that he should have when I first met him. I thought his family was W Indian 'cause. You know, I wasn't sophisticated enough to be able to tell dialects at that time and I live in Florida and Georgia and that's a very different sounding tone. So when I first heard his mother and his heart. His uncle speak and she would say things like this mother was and then I found it from South Carolina and then just like daughters of the dust and mama date of reading about the. Those vehicles islands and just really being caught up in the beauty of the language so I tease him that he probably should have been educated as an SL student because in many ways, his parents have this heavy his mother and siblings. I mean, her siblings have this heavy speech pattern that I hear him, saying, I'm like yeah, you know, I said really.",
    "ibm_transcription": "I tease him that he should have when I first met him I thought his family was listed in because you know I wasn't sophisticated enough to be able to tell dialect at that time in on in Florida and Georgia and that's a very different sounding tone so when I first heard his mother and his heart his uncle speak and she would say things like this hello sign in and then up on it %HESITATION from South Carolina and then just like daughters of the dust and %HESITATION the mom a day and reading about the aha both vehicles island that is really being caught up in the beauty of the language so I tease and that he probably should educate as an **** student because in many ways as his parents have this heavy as mother and siblings I mean her siblings have this heavy speech pattern that I hear him say I'm like yeah you know it's a really",
    "amazon_transcription": "autism that he should have. When I first met him, I thought his family was West Indian because, you know, I wasn't sophisticated enough to be able to tell a dialect at that time. And I live in Florida and Georgia, and that's a very different sounding tone. So when I first heard his mother and his his uncle speak and she would say things like that and then I found it from South Carolina and then, like daughters of the dust in Mama Day and reading about the, uh, those sickles islands and just really being caught up in the beauty of the language. So I tease him that he probably should have been educated as an SL student because in many ways his parents have this heavy. His mother and siblings. I mean, her siblings have this heavy speech pattern that I hear him saying, I'm like, Yeah, you know, I said, Really",
    "google_transcription": "I tease him that he should have when I first met him I thought his family was West Indian cuz you know I wasn't sophisticated enough to be able to tell diet like that that time and I'm in the Florida and Georgia and that's a very different sounding alarm tone so when I first heard his mother and his hurt his uncle speak and she would say things I like and then like Daughters of the dust and the Mama De and reading about the Dos Picos islands and is really being caught up in the beauty of the language so I teased him that he probably should have been educated as a Nestle student because in many ways is his parents had this heavy his mother and siblings and her siblings have this heavy speech pattern that I hear him say that my dear you know I said really",
    "apple_transcription": "Had to use him that he should have when I first met him I thought his family was West Indian because you know I wasn't sophisticated enough to be able to tell a guy like that that time in Florida and Georgia and as a very different sounding him tone so when I first heard his mother and his heart his uncle speak and she and that I found her from South Carolina and then do my daughters of the dust in the mama data reading about the other vehicles I was it is really being caught up in the beauty of the language so I tease him that he probably should've been educate as an actual student because in many ways as his parents have this heavy it is mother and siblings and her siblings have this heavy speech pattern that I hear is it really",
    "clean_google_wer": 0.2722,
    "clean_ibm_wer": 0.2544,
    "clean_amazon_wer": 0.1893,
    "clean_msft_wer": 0.1538,
    "clean_apple_wer": 0.3669,
    "ddm": 0.0059,
    "avg_wer": 0.2473
  },
  {
    "snippet": "PRV_se0_ag3_f_04_1_2296717_2302779.mp3",
    "wordcount": 9,
    "phon_count": 1,
    "phon_features": [
      "l vocalization: principal"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "And so this lady, she was a principal too.",
    "age": 76,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "And so. This lady, she was a principle.",
    "ibm_transcription": "and so this lady if you will of France but to",
    "amazon_transcription": "And so this lady, she was a principal too.",
    "google_transcription": "and so this lady she was a principal to",
    "apple_transcription": "And so maybe he was a prince but",
    "clean_google_wer": 0.1111,
    "clean_ibm_wer": 0.7778,
    "clean_amazon_wer": 0,
    "clean_msft_wer": 0.2222,
    "clean_apple_wer": 0.5556,
    "ddm": 0.1111,
    "avg_wer": 0.3333
  },
  {
    "snippet": "ROC_se0_ag2_f_03_1_379589_427006.mp3",
    "wordcount": 140,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "I don't think so. I think they are just trying to make more room and space for their students. And trying to make it attractive to the folks that they are, um, <ts> reaching out to. And so, this whole push for safer spaces and building, you know, this whole perception that you're in the city, the U of R, but it might not be safe and so they're taking up real estate that is exclusive to the folks that they're catering to. And that's how it feels to me. But that's without any data. I don't know what attempts U of R, um, has at, uh- my parents live a couple of blocks from U of R, I don't think they've reached out at all, I don't think- but <ts> who knows, um, if there is something in the works, I'm not aware of it.",
    "age": 39,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "I don't think so. I think they're just trying to make more room in space for their students and trying to make it attractive to the folks that they are. Reaching out to and so this whole push for safer spaces and building. You know this whole perception that you're in the city. the U of R, but it might not be safe and so there taking up real estate that is exclusive to the folks that their catering too. And that's how it feels to me, but that's without any data. I don't know what attempts U of R. Has that I don't think my parents live a couple of blocks from you over. I don't think they've reached out at all but who knows if there is something in the works. I'm not aware of.",
    "ibm_transcription": "I don't think so I think they're just trying to make more room in space for their students and trying to make it attractive to the folks that they are %HESITATION reaching out to and so this whole push for safer spaces and building you know this whole perception that you're in the city the %HESITATION fire but it might not be safe and so they're taking up real estate that is exclusive to the folks at their catering to and that's how it feels to me but that's without any data I don't know what the temps you of our %HESITATION has at %HESITATION I don't think my parents live a couple blocks away of our I don't think they've reached out at all but who knows %HESITATION if there is something in the works I am not aware of it",
    "amazon_transcription": "I don't think so. I think they're just trying to make more room and space for their students and trying to make it attractive to the folks that they are, um, reaching out to. And so this whole push for safer spaces and building, you know, this whole perception that you're in the city, you of our but it might not be safe. And so there, taking up real estate that is exclusive to the folks that their catering, Teo And that's how it feels to me. But that's without any data. I don't know what attempts you of our has. I don't think my parents live a couple of black from you are. I don't think they've reached out at all. But who knows if there is something in the works, I'm not aware of it.",
    "google_transcription": "I don't think so I think they are just trying to make more room and space for their students and trying to make it attractive to the folks that they are reaching out to and so this whole push for safer spaces and building in all this whole perception that you're in the city the U of R but it might not be safe until they're taking up real estate that is exclusive to the folks at their catering too and that's how it feels to me but that's without any data I don't know what a temp U of R has at I don't think my parents live a couple of blocks from you over I don't think they've reached out at all but who knows if there is something in the works I'm not aware of",
    "apple_transcription": "I don't think so I think they are just trying to make more room in space for their students and trying to make it attractive to the folks that they are reaching out to and so this whole push for safer spaces and building in all this whole perception that you're in the city do you of are but it might not be safe and so they're taking up real estate that is exclusive to the folks at their catering to and that's how it feels to me but that's without any data I don't know what the temps your bar has at I don't think my parents live a couple blocks from over I don't think they've reached out at all but who knows if there is something in the works I'm not aware of",
    "clean_google_wer": 0.1357,
    "clean_ibm_wer": 0.1714,
    "clean_amazon_wer": 0.1429,
    "clean_msft_wer": 0.1214,
    "clean_apple_wer": 0.1643,
    "ddm": 0,
    "avg_wer": 0.1471
  },
  {
    "snippet": "PRV_se0_ag1_f_01_1_702727_714055.mp3",
    "wordcount": 32,
    "phon_count": 1,
    "phon_features": [
      "monothong: my"
    ],
    "gram_count": 5,
    "gram_features": [
      "lack of posessive s: aunt's husband friend",
      "verb stem for past tense: help her, hold her, swim with her",
      "existential it: it was a lot of people on top of houses"
    ],
    "total_count": 6,
    "content": "so she said that um- <ts> My aunt's um, husband friend help her- hold her and swim with her to the boat, and it was a lot of people on top of houses, you know?",
    "age": 19,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "So she said. My eyes. Husband friend help her whole her and swim with her to the boat and there's a lot of people on top of houses you know.",
    "ibm_transcription": "so she said %HESITATION %HESITATION has refrained help her whole heart a swim with her to the boat and a lot of people on top of houses you know",
    "amazon_transcription": "So she said, um, husband, friend, help her wholehearted, Swim with her to the boat And there's a lot of people on top of houses, you know?",
    "google_transcription": "so she said my husband friend help her hold her and swim with her to the boat and there's a lot of people on top of houses in",
    "apple_transcription": "So she said her arm hurts my friend help her whole heart and swim with her to the boat and a lot of people out of the house is a no",
    "clean_google_wer": 0.1875,
    "clean_ibm_wer": 0.3125,
    "clean_amazon_wer": 0.25,
    "clean_msft_wer": 0.1562,
    "clean_apple_wer": 0.4688,
    "ddm": 0.1875,
    "avg_wer": 0.275
  },
  {
    "snippet": "PRV_se0_ag2_f_02_2_777158_782721.mp3",
    "wordcount": 10,
    "phon_count": 2,
    "phon_features": [
      "r-less after vowel: started",
      "-ng to -n: raining"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 2,
    "content": "This was like, um, a Wednesday night when it started raining.",
    "age": 48,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "This was like home. All wins tonight when it starts raining.",
    "ibm_transcription": "this is that going all wins tonight one is start raining",
    "amazon_transcription": "This was like on always tonight when it starts raining.",
    "google_transcription": "this was like going on a Wednesday night when it start raining",
    "apple_transcription": "This was like on a Wednesday night when it started raining",
    "clean_google_wer": 0.3,
    "clean_ibm_wer": 0.9,
    "clean_amazon_wer": 0.4,
    "clean_msft_wer": 0.5,
    "clean_apple_wer": 0.1,
    "ddm": 0.2,
    "avg_wer": 0.44
  },
  {
    "snippet": "ROC_se0_ag3_f_01_1_74615_80900.mp3",
    "wordcount": 15,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "Yeah, so now, my neighbors are great. I actually have, um, kids from RIT next-door.",
    "age": 52,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "Yeah, so now my neighbors are great. I actually have kids from RIT next door.",
    "ibm_transcription": "yeah so now my neighbors are great actually have %HESITATION kids from our A. T. next door",
    "amazon_transcription": "Yeah. So now my neighbors are great, actually. Have kids from all righty next door.",
    "google_transcription": "yeah so now my neighbors are great actually have kids from RIT next door",
    "apple_transcription": "Yeah so now my neighbors are great actually have kids from our team next-door",
    "clean_google_wer": 0.0667,
    "clean_ibm_wer": 0.2667,
    "clean_amazon_wer": 0.1333,
    "clean_msft_wer": 0,
    "clean_apple_wer": 0.2,
    "ddm": 0,
    "avg_wer": 0.1333
  },
  {
    "snippet": "PRV_se0_ag3_f_02_1_1693504_1699318.mp3",
    "wordcount": 11,
    "phon_count": 2,
    "phon_features": [
      "r-less after vowel: better",
      "-ng to -n: parenting"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 2,
    "content": "You know how to become a better parent, um, uh, parent- control parenting,",
    "age": 40,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "You know how to become a better parent. Parent control parenting.",
    "ibm_transcription": "you know how to become a better parent %HESITATION %HESITATION parent control parent in",
    "amazon_transcription": "you know how to become a better parents? Uh, parent control parent and",
    "google_transcription": "you know how to become a better parent parent control parenting",
    "apple_transcription": "You know how to become a better parent parent control parenting",
    "clean_google_wer": 0,
    "clean_ibm_wer": 0.1818,
    "clean_amazon_wer": 0.2727,
    "clean_msft_wer": 0,
    "clean_apple_wer": 0,
    "ddm": 0.1818,
    "avg_wer": 0.0909
  },
  {
    "snippet": "ROC_se0_ag1_m_01_1_1997487_2005526.mp3",
    "wordcount": 10,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "Um, like, the education I got from /RD-SCHOOL-2/ was- was top tier,",
    "age": 24,
    "source": "ROC",
    "gender": "Male",
    "msft_transcription": "Like the education I got from was. Was top tier?",
    "ibm_transcription": "like the education I got from was was top tier",
    "amazon_transcription": "home. Like the education I got from Wass was top tier.",
    "google_transcription": "like the education I got from you was was top-tier",
    "apple_transcription": "Like the education I got from him was was talk to you",
    "clean_google_wer": 0.1,
    "clean_ibm_wer": 0,
    "clean_amazon_wer": 0.2,
    "clean_msft_wer": 0,
    "clean_apple_wer": 0.4,
    "ddm": 0,
    "avg_wer": 0.14
  },
  {
    "snippet": "ROC_se0_ag1_m_02_1_1404369_1412561.mp3",
    "wordcount": 36,
    "phon_count": 4,
    "phon_features": [
      "final consonant deletion: on",
      "unstressed intial syllable deletion: before",
      "CCR: sound",
      "-ng to -n: bumping"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 4,
    "content": "I need your music on right now before I can have my nice night tonight, you know what I mean. So it's cool like, they're not just bumping down south music, they do respect our sound.",
    "age": 27,
    "source": "ROC",
    "gender": "Male",
    "msft_transcription": "I need your music on right now for to have my nice night tonight. You know what I mean, so it's cool like they're not just bumping down South music. They do respect our son.",
    "ibm_transcription": "I need your music are right now for now have a nice night you know what I mean so is cool like they're not just blow up and down south music they do respect our son",
    "amazon_transcription": "I need your music or right now for me, Have my nice night. And you know what I mean. So is cool. Like they're not just bumping down South music. They do respect our son.",
    "google_transcription": "I need your music on right now from to have my nice night tonight you know what I mean so it's cool like they're not just bumping down south music they do respect our son",
    "apple_transcription": "I need your musical right now for now have my most by the moment you know what I mean it is cool like they're not just bumping down south music they do respect our son",
    "clean_google_wer": 0.1111,
    "clean_ibm_wer": 0.3056,
    "clean_amazon_wer": 0.1944,
    "clean_msft_wer": 0.1111,
    "clean_apple_wer": 0.3333,
    "ddm": 0.1111,
    "avg_wer": 0.2111
  },
  {
    "snippet": "DCB_se2_ag2_f_02_1_3630624_3662849.mp3",
    "wordcount": 115,
    "phon_count": 2,
    "phon_features": [
      "-ng to -n: playing",
      "th stopping: this"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 2,
    "content": "put me on his toes, like his feet, and we would dance all night. Literally. And with my uncles as well. That's- that's honestly what we did. And it's- I love music. Um, music speaks to the soul. Like I love the older music. Like you will l- catch me listening to all the old school. Like I love Marvin Gaye. Um, /RD-NAME-1/ was actually complaining the other night cause I'm listening to Marvin Gaye. And I'm like, this is our music, honestly. And I was telling her that they should have signed me up for jazz. Like I loved jazz. I loved /byue/- blues. Like I love all types of music. But yeah. I think CCB probably.",
    "age": 23,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "Put me on his toes like his feet and we would dance all night literally and with my uncles, as well. That's that's honestly what we did, and is I love music music speaks to soul like I love the older music like you will catch me listening to all the old school like I love Marvin Gaye was actually complaining the other night 'cause. I'm listening to Marvin Gaye and I'm like this is our music. Honestly, I was telling you that they should sign me up for jazz like I love jazz. I love you. Blues like I love all types of music but. I think CCP proud.",
    "ibm_transcription": "so like its feet and we would dance all night literally it with my uncles well that's that's honestly what we did and this I love music %HESITATION music speaks to the soul I I love the older music like you will catch me listening to all the old school like I love Marvin Gaye %HESITATION was actually complain the other night because I'm listening to Marvin Gaye and I'm like this is our music honestly I was telling her that they should sign me up for jazz like I love jazz I love you lose like I love all types of music but yeah I think CCB probably",
    "amazon_transcription": "put me on his Taub's like his feet and we would dance all night literally and with my uncles as well. That's that's honestly what we did. And it's I love music. Music speaks to Seoul. I I love the older music like he will catch me listening to all the old school like I love Marvin Gaye. Um was actually complaining the other night because I'm listening to Marvin Gaye and I'm like, This is our music. Honestly, now I'm telling you that they should've signed me up for jazz like I loved jazz. I love you Lose like I love all types of music, right? I think see CP.",
    "google_transcription": "put me on his table so I can speak and we would dance all night literally and with my old as well that's that's honestly what we did and it's I love music music speaks to the soul III love the older music like he will catch me listening to all those cool like I love Marvin Gaye was actually complaining the other night cuz I'm listening to our music honestly I was telling her that they should have signed me up for jazz I got to love jazz I love you Blues like I love all types of music but I think",
    "apple_transcription": "Put me on his toes like his feet and we would dance on it literally it with my horses well that's that's honestly what we did and as I love music I am music speaks to Sol but I love the older music like he will catch me listening to all those cool like I love Marvin Gaye actually complain the other night because I'm listening to Marvin Gaye and I'm like this is our music now telling her that they should've signed me up for jazz like I love jazz I love you blues like I love all types of music but I think",
    "clean_google_wer": 0.2609,
    "clean_ibm_wer": 0.1739,
    "clean_amazon_wer": 0.1739,
    "clean_msft_wer": 0.113,
    "clean_apple_wer": 0.2696,
    "ddm": 0.0174,
    "avg_wer": 0.1983
  },
  {
    "snippet": "ROC_se0_ag1_m_03_1_2977116_2990720.mp3",
    "wordcount": 33,
    "phon_count": 3,
    "phon_features": [
      "final consonant deletion: but",
      "th stopping: those, they're"
    ],
    "gram_count": 1,
    "gram_features": [
      "was-were levelling: they was raised up here"
    ],
    "total_count": 4,
    "content": "it's- it'll work out. It'll work out. But yeah, that's what people- I guess that's what people think about, uh, <ts> those that speak like they're from down south, but they was raised up here.",
    "age": 25,
    "source": "ROC",
    "gender": "Male",
    "msft_transcription": "Yes, it will workout it workout, but yeah, that's what people. I guess that's what people think about. Those that speak. Like there from now, So what day was raised.",
    "ibm_transcription": "is there work out it'll work out yeah that's what people I guess that's what people think about %HESITATION does that speak like different now so what date was raised",
    "amazon_transcription": "it is a work out. It'll work out well, Yeah, that's what people. I guess that's what people think about those that speak like they're from now south. But they was raised here.",
    "google_transcription": "it work out it'll work out but yeah that's what people I guess that's what people think about those that speak like they're from down south but they was raised up",
    "apple_transcription": "Hey is there work out it'll work out for you that's what people I guess that's what people think about those that speak like you're from now so what date was raised it",
    "clean_google_wer": 0.0909,
    "clean_ibm_wer": 0.3636,
    "clean_amazon_wer": 0.1818,
    "clean_msft_wer": 0.4242,
    "clean_apple_wer": 0.3636,
    "ddm": 0.1212,
    "avg_wer": 0.2848
  },
  {
    "snippet": "DCB_se1_ag3_f_01_1_333418_343444.mp3",
    "wordcount": 33,
    "phon_count": 3,
    "phon_features": [
      "l vocalization: well",
      "r-less after vowel: tired",
      "-ng to n: soliciting"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 3,
    "content": "Oh, well nobody knows. I guess people got tired of soliciting help and you know, some of the dynamics changed where neighbors just were getting older and didn't want to do it anymore.",
    "age": 42,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "We. I guess people got added soliciting help and you know, some of the dynamics change with neighbors just we're getting older and didn't wanna do it anymore.",
    "ibm_transcription": "I guess people got tattered soliciting help in you know some of the dynamics change with neighbors just we're getting older did one do it anymore",
    "amazon_transcription": "we know why knows? I guess people got tired soliciting help. And, you know, some of the dynamics change where neighbors just we're getting older and didn't want to do it anyway.",
    "google_transcription": "when nobody knows I guess people got tired of soliciting help and you know some of the Dynamics change with neighbors just we're getting older and didn't want to do it anymore",
    "apple_transcription": "We know when else I guess people got tatted soliciting help in some of the dynamics change we're neighbors just we're getting older didn't want to do it anymore",
    "clean_google_wer": 0.1212,
    "clean_ibm_wer": 0.3939,
    "clean_amazon_wer": 0.1818,
    "clean_msft_wer": 0.303,
    "clean_apple_wer": 0.3636,
    "ddm": 0.0909,
    "avg_wer": 0.2727
  },
  {
    "snippet": "ROC_se0_ag3_f_02_1_708252_721458.mp3",
    "wordcount": 38,
    "phon_count": 4,
    "phon_features": [
      "l vocalization: old",
      "final consonant deletion: said",
      "-ng to -n: going, forcing"
    ],
    "gram_count": 1,
    "gram_features": [
      "was-were leveling: they was"
    ],
    "total_count": 5,
    "content": "I think cause in the old days you didn't- I s- like I said, I don't know if it was consensual. They was going behind the bend somewhere, or if he was forcing hisself and she was scared",
    "age": 80,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "I think that's in the old days you didn't. Ask like I say I don't know if it was consensual. They was going behind the band somewhere. Or if he was forcing his self and she was scared.",
    "ibm_transcription": "I think that's in the old days you didn't ask like I say I don't know if it was consensual they was gonna hang a band somewhere or if he was forcing his selfish she was scared",
    "amazon_transcription": "I think that's in the old days. You didn't asked. Like I say, I don't know if it was consensual. They was going behind, been somewhere or if he was forcing itself and she was scared.",
    "google_transcription": "I think it's in the old days you didn't act like I said I don't know if it was consensual they was going behind the Ben somewhere or if he was forcing herself and she was scared",
    "apple_transcription": "I think because in the old days you didn't ask like I say I don't know if it was consensual they was going behind the band somewhere or if he was forcing his self and she was scared",
    "clean_google_wer": 0.1316,
    "clean_ibm_wer": 0.2632,
    "clean_amazon_wer": 0.1842,
    "clean_msft_wer": 0.1842,
    "clean_apple_wer": 0.1842,
    "ddm": 0.1316,
    "avg_wer": 0.1895
  },
  {
    "snippet": "DCB_se2_ag2_f_02_1_1926109_1933405.mp3",
    "wordcount": 26,
    "phon_count": 2,
    "phon_features": [
      "metathesis: asked",
      "ccr: passed"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 2,
    "content": "Um, and I remember and I aksed /RD-NAME-2/, cause /RD-NAME-2/ was three when she passed, and he still remembers that that's how he learned how to count steps. so.",
    "age": 23,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "And I remember I ask was three when she pass and he still remembers that that's how he learned how to count steps so.",
    "ibm_transcription": "and I remember and I acts does bliss three what you pass and he still remembers that that's how you learn how to count steps so",
    "amazon_transcription": "Andi. I remember, and I act because most three when she passed and he still em burst that. That's how he learned how to count steps so",
    "google_transcription": "but I remembered I asked was three when she passed and he still remembers that that's how he learned how to count steps",
    "apple_transcription": "But I'm a run I ask because bus three when she passed and he still the numbers that that's how you learn how to count steps",
    "clean_google_wer": 0.1923,
    "clean_ibm_wer": 0.3077,
    "clean_amazon_wer": 0.2308,
    "clean_msft_wer": 0.1538,
    "clean_apple_wer": 0.4615,
    "ddm": 0.0769,
    "avg_wer": 0.2692
  },
  {
    "snippet": "ROC_se0_ag1_m_01_1_2542169_2591051.mp3",
    "wordcount": 134,
    "phon_count": 1,
    "phon_features": [
      "-ng to -n: realizing"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "doing music integration. And yeah. I'm like it- it- it was- it was nice, it was really nice. But I was really looking for- My thing was, what I started realizing, um, as in /RD-WORK-4/ is like, you make progress with kids, but then like, something may happen at home, that you have no idea about. And they come the next day and it's just like, yo what's going on? Something coulda happened. Um, at /RD-SCHOOL-2/ I'm allowed to- I have that connection with the home, the school, the student, the parent and it's like that full circle. So I feel like right now, I'm finally at that place where I'm- this is the job that I want. Like, this is how I feel like I can really make some type of impact or change amongst these youth.",
    "age": 24,
    "source": "ROC",
    "gender": "Male",
    "msft_transcription": "Doing music integration. And. Yeah, I mean, it was, it was nice. It was really nice. But I was really looking for my thing was what I started realizing as it is like? We made progress with kids, but then like something may happen at home that you have no idea about and they come. The next day and it's just like you know what's going on? Something could happen. I'm allowed to have that connection with the home, the school. The student the parent is like that full circle, so awful late right now, I'm finally at that place where I'm. This is the job that I want like this is how I feel like I can really make some type of impact or change among cities. You.",
    "ibm_transcription": "doing music integration and yeah on the way it is those nice really really nice but I was really looking for my thing was what I said to realize in on as in this late a progress with kids but then like something may happen at home that you have no idea about when they come the next day and it's just like you know what's going on some good habits %HESITATION I'm allowed to have that connection with the home the school the student the parent is like that full circle so I feel like right now I'm finally at that place where I'm this is the job that I want like this is how I feel like I can really make some type of impact or change among cities",
    "amazon_transcription": "doing music integration and yeah, I'm like it was It was a nice That's really nice, but I was really looking for my thing, was what I started realizing as it was like we made progress with kids. But then, like, something may happen at home that you have no idea about, they come the next day and just like, you know what's going on. Some gonna happen. I'm allowed to have that connection with the home, the school, the students, the parents. It's like that full circle suffer. Like right now I'm finally at that place. We're out. This is the job that I want. Like, this is how I feel that I can really make some type of impactor change amongst these Thanks.",
    "google_transcription": "doing music integration and yeah I made it is it was nice was really nice but I was really looking for my thing was what I said it realizing as it is like you made progress with kids but then like something may happen at home that you have no idea about Nick come the next day and it's just like yo what's going on something to happen if I'm allowed to have that connection with the home the school the student the parent is like that full circle sofa light right now I'm finally at that place where out this is the job that I want like this is how I feel like I can really make some type of and Packer change amongst these you",
    "apple_transcription": "Doing music integration and yeah it was nice was really nice but I was really looking for my thing was when I started realizing him as in his leg he made progress with kids but then like something may happen at home that you have no idea about and they come the next day and it's just like it's going on some good habit mom and I'm allowed to I have that connection with the home the school the student and the parent is like their full circle to fillet right now I'm finally at that place where I am this is the job that I want like this is how I feel like I can really make some type of impact or change a monks these",
    "clean_google_wer": 0.194,
    "clean_ibm_wer": 0.2313,
    "clean_amazon_wer": 0.2239,
    "clean_msft_wer": 0.1567,
    "clean_apple_wer": 0.2463,
    "ddm": 0.0075,
    "avg_wer": 0.2104
  },
  {
    "snippet": "ROC_se0_ag3_f_02_1_2528873_2546883.mp3",
    "wordcount": 41,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "and she never recovered. She was in, uh, the Episcopal home. She never recovered, and that affected him. And his health kind of went down too. So I think somebody moved him out to Arizona or something, and that's where he died.",
    "age": 80,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "And she never recovered. She was in the Episcopal home. She never recovered. And that affected him and his health kind of went down to. So I think somebody moved him out to Arizona or something and that's where he died.",
    "ibm_transcription": "and she never recovered she was in the past couple home she never recovered and that affected him and his help counter went down to so I think somebody moved him out to Arizona and something in this where he died",
    "amazon_transcription": "and she never recovered. She was in, ah, Episcopal home. She never recovered, and that affected him and his health counter went down too. So that thing somebody moved him out to Arizona something, and that's where he died.",
    "google_transcription": "and she never recovered she was in the Episcopal home she never recovered and that affected him and his health kind of went down to so I think somebody moved him out to Arizona or something and that's where he died",
    "apple_transcription": "And she never recovered she was in a Piscopo home she never recovered and that affected him and his health counter went down to so I think somebody moved him out to Arizona or something and that's where he died",
    "clean_google_wer": 0.0244,
    "clean_ibm_wer": 0.2195,
    "clean_amazon_wer": 0.1463,
    "clean_msft_wer": 0.0244,
    "clean_apple_wer": 0.122,
    "ddm": 0,
    "avg_wer": 0.1073
  },
  {
    "snippet": "PRV_se0_ag3_f_02_2_816661_829223.mp3",
    "use": true,
    "attribution": "coraal",
    "wordcount": 47,
    "phon_count": 8,
    "phon_features": [
      "CCR: used, closed",
      "unstressed syllable deletion: eleven",
      "final consonant deletion: when",
      "r-less after vowel: were, were",
      "-ng as -n: going",
      "monothong: ride"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 8,
    "annotations": [
      {
        "timeStart": 6.122577151755293,
        "timeEnd": 6.378094217353698,
        "timeCtxStart": 5.458232781199444,
        "timeCtxEnd": 7.757886371585077,
        "start": 82,
        "end": 87,
        "content": "Unstressed initial syllable deletion"
      },
      {
        "timeStart": 6.940231761670185,
        "timeEnd": 7.297955653507951,
        "timeCtxStart": 5.458232781199444,
        "timeCtxEnd": 7.757886371585077,
        "start": 88,
        "end": 94,
        "content": "Unstressed initial syllable deletion"
      },
      {
        "timeStart": 9.84290562686805,
        "timeEnd": 9.991693261977266,
        "timeCtxStart": 9.117237160568585,
        "timeCtxEnd": 10.507249997423902,
        "start": 133,
        "end": 137,
        "content": "Final consonant deletion"
      },
      {
        "timeStart": 9.998611961971081,
        "timeEnd": 10.172444299315641,
        "timeCtxStart": 9.117237160568585,
        "timeCtxEnd": 10.507249997423902,
        "start": 138,
        "end": 143,
        "content": "Vocalization of stressed syllabic /r/"
      },
      {
        "timeStart": 10.187202812417398,
        "timeEnd": 10.415538447616523,
        "timeCtxStart": 9.117237160568585,
        "timeCtxEnd": 10.507249997423902,
        "start": 144,
        "end": 150,
        "content": "Final consonant cluster reduction"
      },
      {
        "timeStart": 10.54716722555484,
        "timeEnd": 10.748639844848185,
        "timeCtxStart": 10.507249997423902,
        "timeCtxEnd": 11.643779800000367,
        "start": 154,
        "end": 159,
        "content": "Vocalization of stressed syllabic /r/"
      },
      {
        "timeStart": 10.772816559163386,
        "timeEnd": 10.95011246414153,
        "timeCtxStart": 10.507249997423902,
        "timeCtxEnd": 11.643779800000367,
        "start": 160,
        "end": 164,
        "content": "Final Consonant Cluster Reduction"
      },
      {
        "timeStart": 10.971602876866152,
        "timeEnd": 11.113976861166783,
        "timeCtxStart": 10.507249997423902,
        "timeCtxEnd": 11.643779800000367,
        "start": 165,
        "end": 170,
        "content": "Final Consonant Deletion"
      },
      {
        "timeStart": 11.441705655217289,
        "timeEnd": 11.642881383624536,
        "timeCtxStart": 10.507249997423902,
        "timeCtxEnd": 11.643779800000367,
        "start": 178,
        "end": 182,
        "content": "Glide reduction of /ai/"
      },
      {
        "timeStart": 12.00853684858754,
        "timeEnd": 12.108261066304722,
        "timeCtxStart": 11.643779800000367,
        "timeCtxEnd": 12.561995464852608,
        "start": 195,
        "end": 198,
        "content": "Syllable initial fricative stopping"
      }
    ],
    "content": "My mom w- um, you know, sh- when she was- h- had the store, and she would- used to close the store about uh, eleven o-clock. And she would uh, promise us, now when we're closed up we're just gonna take a ride and look at the town.",
    "real_transcription": "My mom you know when she was had the store and she would- used to close the store about eleven o-clock and she would promise us, now when we're closed up we're just gonna take a ride and look at the town.",
    "asr_transcription": "My mom you know when she was had the store and she will use the closest store. By eleven o-clock and she would promise and I were closer. We could just go take around look at the town.",
    "age": 40,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "My mom you know when she was had the store and she will use the closest store. By 11:00 o'clock and she would promise and I were closer. We could just go take around look at the town.",
    "ibm_transcription": "my mom will %HESITATION you know she when she was had the store and she would use a clothes store about %HESITATION eleven o'clock and she would %HESITATION promises and I will close it would just come take around look at the town",
    "amazon_transcription": "Hi, mom. You know, when she was had the store and she would use the closest store about 11 o'clock and she would promises and I were close that we're just going to take around. Look at the town.",
    "google_transcription": "my mom will you know when she was at the store and she would use a clothes store about 11 and she would promise and I get to town",
    "apple_transcription": "You know when she was at had a store and she would use to close the store about 11 o'clock and she would've probably just come take a ride and look at a town",
    "clean_google_wer": 0.5532,
    "clean_ibm_wer": 0.4681,
    "clean_amazon_wer": 0.4043,
    "clean_msft_wer": 0.4681,
    "clean_apple_wer": 0.4468,
    "ddm": 0.1702,
    "avg_wer": 0.4681
  },
  {
    "snippet": "ROC_se0_ag2_f_03_1_39270_63728.mp3",
    "wordcount": 71,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 0,
    "content": "<ts> I would describe it as inner city. I mean, I grew up, um, I'm thirty-nine now, but when I grew up, it was the G-Boys and the Black Mob and, you know it's a- you know, between jen- Cottage Street was pretty- a lot of homeowners, but Genesee and Jefferson, um, a lot of, uh, just, um, activity, uh, around, but also a very pretty area. Um, a lot of old homes, a lot of",
    "age": 39,
    "source": "ROC",
    "gender": "Female",
    "msft_transcription": "I would describe his inner city. I mean, I grew up. I'm 39 now. But when I grew up with the G boys and the black mob and you know it's you know between Cotton Street was pretty lot of homeowners. But Tennessee in Jefferson, a lot of just. Activity around but also very pretty area. A lot of old homes a lot.",
    "ibm_transcription": "I would describe it is in our city I mean I grew up %HESITATION I'm thirty nine now but when I grew up it was the G. boys in the black mob and yeah it's %HESITATION you know between Jan Kavan street was pretty lot of homeowners but Tennesseans ever send %HESITATION a lot of %HESITATION just %HESITATION activity %HESITATION around but also a very pretty area %HESITATION a lot of old homes a lot of",
    "amazon_transcription": "I would describe his inner city. I mean, I grew up. Um, I'm 39 now, but when I grew up, it was G boys in the black mob and you know it. You know, between Cattle Street was pretty lot of homeowners, but Tennessee and Jefferson. A lot of just activity around, but also a very pretty area. A lot of old homes, a lot of",
    "google_transcription": "I would describe it as inner-city I mean I grew up I'm 39 now but when I grew up with a G boys and a black mob and homeowners but Tennessee and Jefferson a lot of just activity around but also very pretty area a lot of old homes",
    "apple_transcription": "I would describe it as inner-city I mean I grew up I'm 39 now but when I grab it with the boys in a black Marvin another twins in cottage Street was pretty lotta homeowners but Genesee and Jefferson a lot of largest activity around but also very pretty area and lotta old home tomorrow",
    "clean_google_wer": 0.338,
    "clean_ibm_wer": 0.1831,
    "clean_amazon_wer": 0.1408,
    "clean_msft_wer": 0.169,
    "clean_apple_wer": 0.3944,
    "ddm": 0,
    "avg_wer": 0.2451
  },
  {
    "snippet": "DCB_se1_ag3_m_02_1_1458301_1496422.mp3",
    "use": true,
    "attribution": "coraal",
    "wordcount": 105,
    "phon_count": 13,
    "phon_features": [
      "ccr: band, band, fast",
      "monothong: I",
      "l vocalization: style, older",
      "fricative stopping: wasn't",
      "th stopping: with, that, they, that, that",
      "th fronting: northeast"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 13,
    "annotations": [
      {
        "timeStart": 1.119565570080369,
        "timeEnd": 1.4259547510138342,
        "timeCtxStart": 0.04974438652766645,
        "timeCtxEnd": 1.7138549296495902,
        "start": 11,
        "end": 15,
        "content": "Final consonant cluster reduction"
      },
      {
        "timeStart": 2.5590664632591493,
        "timeEnd": 3.2960856054530874,
        "timeCtxStart": 1.7138549296495902,
        "timeCtxEnd": 3.4846938775510203,
        "start": 38,
        "end": 43,
        "content": "Glide reduction of /ai/"
      },
      {
        "timeStart": 5.093469135426159,
        "timeEnd": 5.6684155430748975,
        "timeCtxStart": 4.958187627744103,
        "timeCtxEnd": 7.0772177608527915,
        "start": 57,
        "end": 64,
        "content": "Syllable initial fricative stopping"
      },
      {
        "timeStart": 7.71015330567587,
        "timeEnd": 8.044762542747945,
        "timeCtxStart": 7.0772177608527915,
        "timeCtxEnd": 9.451733913087146,
        "start": 116,
        "end": 122,
        "content": "Final consonant cluster reduction"
      },
      {
        "timeStart": 9.016338761234326,
        "timeEnd": 9.387230927627469,
        "timeCtxStart": 7.0772177608527915,
        "timeCtxEnd": 9.451733913087146,
        "start": 146,
        "end": 151,
        "content": "Glide reduction of /ai/"
      },
      {
        "timeStart": 11.052528298017489,
        "timeEnd": 11.22593964932103,
        "timeCtxStart": 10.028463966670907,
        "timeCtxEnd": 12.648381409337905,
        "start": 170,
        "end": 172,
        "content": "Invariant habitual \"be\""
      },
      {
        "timeStart": 17.068061031921683,
        "timeEnd": 17.48176983521478,
        "timeCtxStart": 15.523325742206316,
        "timeCtxEnd": 19.059868738098945,
        "start": 237,
        "end": 242,
        "content": "Vocalization of post vocalic /l/"
      },
      {
        "timeStart": 18.182405711759547,
        "timeEnd": 18.422623726574898,
        "timeCtxStart": 15.523325742206316,
        "timeCtxEnd": 19.059868738098945,
        "start": 260,
        "end": 266,
        "content": "Fricative stopping before nasals"
      },
      {
        "timeStart": 18.442641894476175,
        "timeEnd": 18.639487212172085,
        "timeCtxStart": 15.523325742206316,
        "timeCtxEnd": 19.059868738098945,
        "start": 267,
        "end": 271,
        "content": "Stopping of voiceless interdental fricatives"
      },
      {
        "timeStart": 18.853014336452397,
        "timeEnd": 19.063205099415825,
        "timeCtxStart": 15.523325742206316,
        "timeCtxEnd": 19.059868738098945,
        "start": 276,
        "end": 280,
        "content": "Syllable initial fricative stopping"
      },
      {
        "timeStart": 21.90757441870614,
        "timeEnd": 22.58412097217129,
        "timeCtxStart": 20.928034623505063,
        "timeCtxEnd": 23.22331219446352,
        "start": 311,
        "end": 320,
        "content": "Labialization of interdental fricatives"
      },
      {
        "timeStart": 23.57611254443001,
        "timeEnd": 23.746286830884433,
        "timeCtxStart": 23.22331219446352,
        "timeCtxEnd": 26.203302151478432,
        "start": 339,
        "end": 343,
        "content": "Syllable initial fricative stopping"
      },
      {
        "timeStart": 24.613760632566745,
        "timeEnd": 24.95825979782814,
        "timeCtxStart": 23.22331219446352,
        "timeCtxEnd": 26.203302151478432,
        "start": 361,
        "end": 368,
        "content": "Syllable initial fricative stopping"
      },
      {
        "timeStart": 25.207295338980956,
        "timeEnd": 25.811679587838128,
        "timeCtxStart": 23.22331219446352,
        "timeCtxEnd": 26.203302151478432,
        "start": 372,
        "end": 376,
        "content": "Final consonant cluster reduction"
      },
      {
        "timeStart": 28.720302917399643,
        "timeEnd": 28.94987635087066,
        "timeCtxStart": 27.74591303927225,
        "timeCtxEnd": 29.978024248125426,
        "start": 415,
        "end": 419,
        "content": "Syllable initial fricative stopping"
      }
    ],
    "content": "Yeah, each- each band has their significant style. I mean, but they're all played on the same instrument, of course every band's gonna have a different style. Like Chuck Brown'd be like more slow pace. Or you know you had more slow pace for the older crowd cause they wasn't with all that. But, you know, Junk, Back, and Northeast Groovers you know they could go slow or they'll go fast for a couple dances or something like that for back in the day. But I'll tell you one thing. We don't percolate, that's what we won't do down in DC we don't percolate.",
    "real_transcription": "Yeah, each band has their significant style. I mean, but they're all played on the same instrument, of course every band's gonna have a different style. Like Chuck Brown be like more slow pace. Or you know you had more slow pace for the older crowd cause they wasn't with all that. But you know Junk, back, and Northeast Groovers you know they could go slow or they'll go fast for a couple dances or something like that for back in the day. But I'll tell you one thing. We don't percolate, that's what we won't do down in DC we don't percolate.",
    "asr_transcription": "he's very I have this sort of style moment by the off label sign issue of course every five that like Chuck Brown be like more slow pace for in a year more slow pace for to crack cause they wouldn't win but you know jump back a little freeze rules and you know they can go slow what their goal fan full covered bears a little sound like they're from back in the day. But I'd say you want we don't percolate, that's what the man in the same ballpark. ",
    "age": 36,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "Is my hair style? They all played with the same issue and of course, everybody's gonna have a different style. Like Chuck Brown be like more slow paced. Or. In a year, more slow pace for the older crowd. 'cause they wouldn't with all that. But you know jump back in NE Groovers, you know they could go slower they go fan. For a couple days or something like that, for back in the day. But I tell you one thing we don't percolate.",
    "ibm_transcription": "he's very I have this sort of style moment by the off label sign issue of course every five that like Chuck brown be like more slow pace for in a year more slow pace for %HESITATION to crack because they wouldn't win but you know jump back a little freeze rules and you know they can go slow what their goal fan full covered bears a little sound like they're from back in the day but I'd say you want we don't percolate that's what the man in the same ballpark",
    "amazon_transcription": "there is. But I have this new style. Oh, man, they're all playing the same issue. Of course, everybody's gonna have a difference that, like, shut ground be like more slow pace war, you know? Yeah, more slow pace for the older crack because they wouldn't get on. But, you know, back off these rules, you know, they go slow with a go fans for a couple of days, like there for about days. But I'd say you want We don't percolate. That's what I do. I think Perkins.",
    "google_transcription": "is bad has their significant style I mean back Lopez or in a year more so pays for the older crowd cuz they wouldn't with all that but you know junk back in Northeast Groovers you know they can go slow or they go fast. Advances or something like that for back in the day but I tell you one thing we don't percolate that's what we want.",
    "apple_transcription": "Is there a cheeseburger have this on my own but of course I would like Chuck Brown to be like more slow pace or in a year more slow pace with all the crackers they won with all that but you know back in the office room you know they can go slow with a gold van for comfort versus on my deathbed and day but I'd say you want percolate basketball",
    "clean_google_wer": 0.5048,
    "clean_ibm_wer": 0.6571,
    "clean_amazon_wer": 0.5333,
    "clean_msft_wer": 0.4095,
    "clean_apple_wer": 0.7143,
    "ddm": 0.1238,
    "avg_wer": 0.5638
  },
  {
    "snippet": "DCB_se3_ag3_m_01_1_89698_106868.mp3",
    "wordcount": 52,
    "phon_count": 1,
    "phon_features": [
      "CCR: first"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "in Lincoln Heights when we first moved here. And that was like my pre K three, pre K four days. My mom is a DC- DC teacher, so she taught there, and we went to school there. But after /RD-SCHOOL-2/ Elementary School, graduated from there and went to /RD-SCHOOL-4/ Middle School in Upper Marlboro,",
    "age": 33,
    "source": "DCB",
    "gender": "Male",
    "msft_transcription": "In Lincoln Heights when we first moved here. And that was like my pre K3 Pre K 4 days. My mom is a DC DC teacher, so she taught their own but it's cool there, but After elementary school graduate from there went to. Middle school. In Upper Marlboro.",
    "ibm_transcription": "Lincoln heights when we first moved here and that was like my pre K. three pre K. for days among the DC DC teachers so she taught them with the school there but after the magic school graduate from there went to middle school an upper Marlboro",
    "amazon_transcription": "in Lincoln Heights when we first moved here, and that was like my pre K three pre k for days. My mom's in DC DC teachers, So she taught their own with the school there. But at the elementary school graduate from there went to middle school and at the Marbro.",
    "google_transcription": "in Lincoln Heights when we first moved here and that was like my Pre-K 3 Pre-K 4 days my mom's DC DC teacher so she taught there we went to school there but after elementary school graduate from there went to middle school in Upper Marlboro",
    "apple_transcription": "In Lincoln Heights when we first moved here and that was like my pre-K-3 pre-K for days I'm on the DC DC teacher so she taught dealing with the school year but at the middle school graduate from there went to middle school and upper Marboro",
    "clean_google_wer": 0.1154,
    "clean_ibm_wer": 0.3269,
    "clean_amazon_wer": 0.3462,
    "clean_msft_wer": 0.1923,
    "clean_apple_wer": 0.3462,
    "ddm": 0.0192,
    "avg_wer": 0.2654
  },
  {
    "snippet": "PRV_se0_ag1_f_01_1_1086962_1100671.mp3",
    "wordcount": 43,
    "phon_count": 3,
    "phon_features": [
      "-ng to -n: living, sitting",
      "r-less after vowel: her"
    ],
    "gram_count": 1,
    "gram_features": [
      "lack of third person singular s: it sit up"
    ],
    "total_count": 4,
    "content": "one bathroom, small living room, small kitchen, all of us like into one- just ridiculous, and now you go to her house- Her house is not sitting in the same space. It's kind of further back. It sit up. They gotta build up",
    "age": 19,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "One bathroom, small living room, small kitchen all of it was like into ones is ridiculous and then you go to her house. Her house is not seeing the same space is kind of further back. It's it up, they gotta build up.",
    "ibm_transcription": "one that small little smile keeps in all of this I gave to what it is ridiculous and then you go to our house hi houses medicine is safe spaces camera for the bay if it up they got a build up",
    "amazon_transcription": "one bathroom, small little smile, kitchen. All of it was like it was just ridiculous. And then you go to her house. Houses that's in the same space is kind of further back. Sit up. They gotta build up.",
    "google_transcription": "1 bathroom small living room small kitchen all of it was like into one just ridiculous and then you go to her house her house is not sending same space is kind of for the back it says they got to build up",
    "apple_transcription": "One bathroom small living room small kitchen all of those like into what it is ridiculous and then to go to her house her house is not as in a safe space a timer for the back if it up they got a build up",
    "clean_google_wer": 0.3023,
    "clean_ibm_wer": 0.7209,
    "clean_amazon_wer": 0.3488,
    "clean_msft_wer": 0.2326,
    "clean_apple_wer": 0.3953,
    "ddm": 0.093,
    "avg_wer": 0.4
  },
  {
    "snippet": "PRV_se0_ag1_f_01_1_1793116_1798463.mp3",
    "wordcount": 24,
    "phon_count": 6,
    "phon_features": [
      "final consonant deletion: what, said, said, out",
      "ccr: grandma",
      "l vocalization: well"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 6,
    "content": "<laugh> What we look for? You know, like, okay. My grandma's like- I said, well- she said, well I put it out. I was like,",
    "age": 19,
    "source": "PRV",
    "gender": "Female",
    "msft_transcription": "Will we look for you know like OK? We're going to like I said, well, she said. Wow, pretty house like?",
    "ibm_transcription": "what we look for you know life okay one normal life as a live she's a lovely house I",
    "amazon_transcription": "we'll live for, you know, like okay, like I said, she said while putting out.",
    "google_transcription": "what we live for you don't like",
    "apple_transcription": "OK I'm on the way as it were",
    "clean_google_wer": 0.7917,
    "clean_ibm_wer": 0.6667,
    "clean_amazon_wer": 0.5417,
    "clean_msft_wer": 0.4583,
    "clean_apple_wer": 0.9167,
    "ddm": 0.25,
    "avg_wer": 0.675
  },
  {
    "snippet": "DCB_se2_ag2_f_01_1_2793680_2811400.mp3",
    "wordcount": 71,
    "phon_count": 8,
    "phon_features": [
      "ccr: kind",
      "monothongization: I'm, I'm, I'm, I, I",
      "th stopping: seventh, ninth"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 8,
    "content": "S- kind of sort of. So I'm the link, so- Well I'm not- I'm not the link, and I wasn't the link. I'm the one that pull everybody together so to speak. So it was me and my best friend /RD-NAME-2/. We've known each other since seventh grade. And then, <laugh> it's not funny but I was- Towards my ninth grade year I became a part of a group called Keep it Tight",
    "age": 25,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "Kind of thought of so I'm the length, so I'm not. I'm not dealing. I wasn't doing. I'm the one that brought everybody together, so this week, so it was me and my best friend. We've known types itself right and then. Not funny but I was told by nightcore, yeah, became a part of a group called keep it tight.",
    "ibm_transcription": "kind of thought of thought I'm the late so I'm not I'm not the man with No Way Out no one a bowl everybody together so to speak so was my best friend we do not have that self worry and they're not funny but I was a close one I'm Corey yeah became a part of a group called keep it tight",
    "amazon_transcription": "kind of sort of. So I'm the length. So I'm not I'm not like I wasn't the one that brought everybody together. So sweet. So was my best friend, even on tip toe so far, eh? And there Not funny. I was told my knife or yeah, became a part of a group called Keep It tight.",
    "google_transcription": "kinda sorta song I'm the link so everybody together to my best friend that's funny that I was close my ninth-grade year became a part of a group called keep it tight",
    "apple_transcription": "I kind of thought I saw on the link though I don't want to bore everybody together for the sweet that was me my best friend lives in there that money but I will close my life for you have a kennel part of a group called keep in touch",
    "clean_google_wer": 0.6197,
    "clean_ibm_wer": 0.4648,
    "clean_amazon_wer": 0.4225,
    "clean_msft_wer": 0.338,
    "clean_apple_wer": 0.7042,
    "ddm": 0.1127,
    "avg_wer": 0.5099
  },
  {
    "snippet": "DCB_se2_ag3_f_02_1_2200823_2206816.mp3",
    "wordcount": 26,
    "phon_count": 2,
    "phon_features": [
      "th stopping: theyre",
      "-ng to -n: building"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 2,
    "content": "To the point where they're building buildings on top of something- it j- it- it shouldn't even- Why are you building a building on an overpass?",
    "age": 31,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "The point where they're building buildings on top of something it shouldn't even why you better better not overpass.",
    "ibm_transcription": "building building on top of something that it did did they should be required okay",
    "amazon_transcription": "to the point where you're building building on top of something that it should mean. Why better? Better?",
    "google_transcription": "building building on top of something that they shouldn't even try",
    "apple_transcription": "What are you building building on Papas of them it did it did show me what what are you been about an hour",
    "clean_google_wer": 0.7308,
    "clean_ibm_wer": 0.7692,
    "clean_amazon_wer": 0.5769,
    "clean_msft_wer": 0.3846,
    "clean_apple_wer": 0.7308,
    "ddm": 0.0769,
    "avg_wer": 0.6385
  },
  {
    "snippet": "DCB_se2_ag3_f_02_1_3026179_3071055.mp3",
    "wordcount": 164,
    "phon_count": 12,
    "phon_features": [
      "initial d deletion: ima go",
      "intersylabbic /r/ deletion: government",
      "-ng as n': going, going",
      "final consonant deletion: woulda, that, woulda",
      "CCR: lyft, lyft, lyft",
      "Monopthongization: my car, my house"
    ],
    "gram_count": 3,
    "gram_features": [
      "say as verb complementizer: said you know what, I said ima go over here.",
      "Verb stem as past: park my car"
    ],
    "total_count": 15,
    "content": "I know it was totally different. Um, it was a Friday night, I had got published in Capitol Romance that day. Um, one of my s- photoshoots got published. I said, you know what, screw it, I'm going out tonight, I'm gonna hit U Street. Went out, looked for parking for forty-five god darn minutes. Couldn't find not s- stitch of parking near U Street. So I drove all the way down to Constitution Ave. I said, I'm'a go over here by the government buildings where I know my car's not gonna get broken into and I'm still in the city, and I parked my car and I called a Lyft from there. The difference is if I woulda went all the way home to Temple Hills and called a Lyft and parked my car at my house, that Lyft woulda been like twenty dollars. I called a Lyft from Constitution Avenue to U Street, it was four dollars and sixty-seven cents.",
    "age": 31,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "I know it's only different it was a Friday night. I had got published in capital romance that day. One of my photo shoots got published. I say you know what screw it. I'm going out tonight. I'm gonna hit you St. When out look for parking for 45, got darn minutes couldn't find that stitches parking near U Street. So I drove all way down to constitution. Ave I said i'ma go over here by the government buildings where I know my car is not gonna get broken into and I'm still in the city and I Park. My car in our caller left from there. The differences if I woulda went all way home to simple Hills and called a lift in parking my power. My house that would have been like $20. I've gotta left from Constitution Ave to U Street, it was $4.67.",
    "ibm_transcription": "it was a Friday night I had got published in capital romance that day %HESITATION one of my best photo shoots got published I say you know what screw it I'm going out tonight I'm going he you street went out look for parking full forty five got during minutes couldn't find that there's a park in the street so I drove all the way down the constitution that I said I'm a bull we about government buildings where I know my cars my home get broken into and I'm still in the city and I park my car not called a live from there the difference is if I would want always home to simple will the gonna live in park my car my house they live within like twenty dollars I got a lift from constitution Avenue to use street was for all of the sixty seven cents",
    "amazon_transcription": "No, it's only different. It was a Friday night I had got published in capital romance. That day one of my photo shoot's got published. I say, You know what? Screw it. I'm going out tonight. I'm going hit you st went out. Look for parking for 45. Gotten dharma minutes. Couldn't find that stitch of parking there you street. So I drove all the way down to Constitution at I said, I'm going over here by the government buildings where I know my cars, my home get broken into And I'm still in the city And I parked my car and I caught a lift from there. The difference is, if I was went all the way home to Simple Hills and call the lift and park my car in my house that lives would've been like $20. I got a lift from Constitution Avenue to you Street. It was $4 or 67 cents",
    "google_transcription": "I know it's only did it was a Friday night I had got published in capital romance that day when am I supposed to shoot got published I say you know what screw it I'm going out tonight I'm going to hit you Street when out look for parking for 45 got during minutes couldn't find that Stitch apartment near U Street so I drove all the way down to Constitution if I said I'm going to go over here by the government buildings where I know my car is not going to get broken into and I'm still in the city and I park my car and I called and left from there the difference is if I would have went all way home to Temple Hills and caught a lift and park my car in my house that life would have been like $20 I called and left on Constitution Avenue to use Street it was $4.67",
    "apple_transcription": "I know it was a Friday night I had got published in capital romance that day I want mice photo shoot got published I say you know what screw it I'm going out tonight I'm gonna hit you straight when out look for parking for 45 got Dara minutes couldn't find that there's a park in the street so I drove all way down to Constitution net I said I'm a go away by the government buildings where no more cars my whole get broken into and I'm still in the city and I park my car in O'Connell live from there the difference is if I would've went all way home and got a live in park my car at my house the liver been like $20 I got a live in Casa Napoli do you Street it was $4.67",
    "clean_google_wer": 0.2805,
    "clean_ibm_wer": 0.4024,
    "clean_amazon_wer": 0.2378,
    "clean_msft_wer": 0.25,
    "clean_apple_wer": 0.3841,
    "ddm": 0.0915,
    "avg_wer": 0.311
  },
  {
    "snippet": "DCB_se2_ag2_f_02_1_176993_184247.mp3",
    "wordcount": 22,
    "phon_count": 1,
    "phon_features": [
      "l vocalizaiton: while"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 1,
    "content": "Okay. Um, so we lived with my grandmother the majority of the time while we lived in DC. And I can't remember the",
    "age": 23,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "OK, so we live with my grandmother majority of the time while we lived in DC and I can't remember the?",
    "ibm_transcription": "okay %HESITATION so we live with my grandmother and during the time I we lived in DC and I can't remember the",
    "amazon_transcription": "Okay. Um, so we lived with my grandmother a majority of the time while we lived in D. C. And I can't remember the",
    "google_transcription": "okay so we live with my grandmother majority of the time I'll be lives in DC and I can't remember the",
    "apple_transcription": "OK so we live with my grandmother enjoy the time away lives in DC and I can't remember the year",
    "clean_google_wer": 0.2273,
    "clean_ibm_wer": 0.2273,
    "clean_amazon_wer": 0.1364,
    "clean_msft_wer": 0.0909,
    "clean_apple_wer": 0.3636,
    "ddm": 0.0455,
    "avg_wer": 0.2091
  },
  {
    "snippet": "DCB_se2_ag4_f_01_1_3578924_3594150.mp3",
    "wordcount": 43,
    "phon_count": 3,
    "phon_features": [
      "stop devoicing: learned",
      "-n g to -n: becoming",
      "ccr: architect"
    ],
    "gram_count": 0,
    "gram_features": [],
    "total_count": 3,
    "content": "One time, I r- I learned a lot becoming a tourist one time (laughing). And it wasn't until I w- got married again. We took the bus- tour bus, and I learned about the /archiatect/. I learned to appreciate the /archiatect/ more in DC.",
    "age": 58,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "I learn a lot, becoming a tours one time and it wasn't until I got married again. We took the bus tour bus. And I learned about the architect and learn to appreciate the architect more in DC.",
    "ibm_transcription": "I learned a lot becoming a tourist and time and it wasn't until I got married yet we took the bus to a bus and I learned about the architect I learned to appreciate the architect more NBC",
    "amazon_transcription": "one time I learned a lot becoming a tourist from one time, and it wasn't into life. Got married. We took the bus to a bus and I learned about the architect. I learned to appreciate the architect, more indecent.",
    "google_transcription": "one time I learned a lot become a notorious open time and it wasn't until I got married again we took the bus to a bus and I learned about the ark attack I learned to appreciate the architect more in DC",
    "apple_transcription": "One time I learn a lot becoming a Taurus and time it was until I got married yet we took the bus to a bus and I learned about the architect and I depreciate the architect warranty thing",
    "clean_google_wer": 0.2558,
    "clean_ibm_wer": 0.3023,
    "clean_amazon_wer": 0.3023,
    "clean_msft_wer": 0.2558,
    "clean_apple_wer": 0.4651,
    "ddm": 0.0698,
    "avg_wer": 0.3163
  },
  {
    "snippet": "DCB_se1_ag3_f_02_2_541814_589575.mp3",
    "wordcount": 132,
    "phon_count": 11,
    "phon_features": [
      "final consonant deletion: work",
      "front stressing: pólice, police",
      "r-less after vowel: hair, together",
      "monothong: high",
      "l vocalization: school, all",
      "ccr: and",
      "th stopping: the",
      "-ng as n: trying"
    ],
    "gram_count": 1,
    "gram_features": [
      "absence third person singular s: she live"
    ],
    "total_count": 12,
    "content": "Yes, but all us work. I have a uh, friend she's a police officer that's my best friend, we went to hair school together, w- basically we did everything together until she went into the police force. Um, back in high school I still talk to um, some of my friends from the basketball team. <ts> Uh, /RD-NAME-3/ uh, me and her close but uh, we have our families, she live out Clinton. So we see each other when we go to church sometimes. And we talk on the phone but when we get together it's always the same, so we know that you know we are true friends cause it alway the s- always the same when we see each other. But all in all I stays in the house. Trying to get them book works done. That's it.",
    "age": 38,
    "source": "DCB",
    "gender": "Female",
    "msft_transcription": "Yes, but all us work. I have a friend she's a police off so that's my best friend. We went to high school together. Basically we did everything together and to she went into the police force. Um back in high school, I still talk to some of my friends from the basketball team. Uh. Are made her clothes but we have our family, she live out Clinton? So we see each other when we go to church sometimes. And we talk on a phone. But when we get the gallery is always the same. So we know that you know, we are true friends. 'cause it's always it's always the same when we see each other. The old no *** stays in the House trying to get them bookworks done that's it.",
    "ibm_transcription": "yes but all us were I have a of France he's a police officer does my best friend we went to his school again basically we did everything together and to see when it to the police force Tom back and has school I still talk to on some of my friends from the basketball team all may hurt close but all we have our family she live out Clinton so we see double %HESITATION when we go to church sometimes and we don't want to phone but when we get together is always the same so we noted you know we ought to France because all we just always the same we see each other the only NA last stays in the house trying to get them but works done does it",
    "amazon_transcription": "Yes, but all us work I have. Ah, friend. She's a police officer. That's my best friend. We went to his school together. Basically, we did everything together until she went into the police force. Um, back in high school as they will talk to some of my friends from the basketball team. Uh, may her clothes, but we have our family. She live out Clinton. So we see each other when we go to church sometimes and we talk on the phone. But when we get to Gavin's, always the same. So we know that you know, we are true friends because it always is always the same when we see each other, the old Noah stays in house trying to get them. But work's done. That's it.",
    "google_transcription": "yes but all lights work I have a friend she's a police officer that's my bestfriend we went to HighSchool together basically we did everything together until she went into the police force back in high school I still talk to some of my friends from the basketball team made her clothes but we have our family she live out Clinton so we see each other when we go to church sometimes and we talk on the phone but when we get together it's always the same so we know that you know we are true friends cuz it's always always the same when we see each other the old Noah stays in the house trying to get them book work done that's it",
    "apple_transcription": "Yes but all is work I have a bar friends he's a police officer does my best friend we went to high school together but basically we did everything together after she went to the police force him back in high school I still talk to our summer friends on the basketball team at Almeda her clothes but we have our family she live out Claire so we see each other when we go to church sometimes and we talk wanna farm but when we get together is always the same so we know that you know we are true friends because all we do is always the same with each other but all in all a stays in the house trying to get them but works the best",
    "clean_google_wer": 0.1439,
    "clean_ibm_wer": 0.3561,
    "clean_amazon_wer": 0.1742,
    "clean_msft_wer": 0.1894,
    "clean_apple_wer": 0.2727,
    "ddm": 0.0909,
    "avg_wer": 0.2273
  },
  {
    "snippet": "ROC_se0_ag1_m_03_1_2731369_2750996.mp3",
    "wordcount": 37,
    "phon_count": 0,
    "phon_features": [],
    "gram_count": 1,
    "gram_features": [
      "was-were levelling: lot of us was raised"
    ],
    "total_count": 1,
    "content": "I mean, Geechee is like, basically like- um, like a Southern accent, um, and which a lot of us in Rochester already sound quote unquote Geechee. Because a lot of us was raised by folks that lived down south.",
    "age": 25,
    "source": "ROC",
    "gender": "Male",
    "msft_transcription": "I mean, Geechee is like basically like. Oh. Like a southern accent. In which A lot of us in rashes, there already sound quote Unquote meaty because a lot of us was raised by folks that. Live down South.",
    "ibm_transcription": "I mean DC is like basically like %HESITATION like a southern accent of in which a lot of us in Rochester already sound quote on quote needs you because a lot of was was raised by folks that live down south",
    "amazon_transcription": "I mean, Geechee is, like, basically like, um, like a Southern accent in ways a lot of us and rashes there already sound quarter unquote meeting because a lot of us were raised by folks that live down south.",
    "google_transcription": "I mean geechie is like basically like like a Southern accent in which a lot of us in Rochester already sound quote on quote me to you because I thought it was was raised by folks that lived",
    "apple_transcription": "I mean DC is like basically like like a southern accent and Waze a lot of us and rashes are already sound \"on \"meeting you because a lot of was raised by folks that live down south",
    "clean_google_wer": 0.3514,
    "clean_ibm_wer": 0.2432,
    "clean_amazon_wer": 0.2432,
    "clean_msft_wer": 0.1622,
    "clean_apple_wer": 0.2703,
    "ddm": 0.027,
    "avg_wer": 0.2541
  }
]
;

import React from 'react';
import {
  VictoryChart,
  VictoryBar,
  VictoryGroup,
  VictoryAxis,
} from 'victory';
import { Page, PageBody, PageText, PageTitle } from '../components/Page';
import { wer } from '../data/wer.js';
import { Box } from '@rebass/grid/emotion';
import {BarChartLabel} from '../components/BarChartLabel';
import {formatProduct, formatWer, formatRace} from '../format';
import * as theme from '../components/theme';
import {ChartTitle, BetterWorseLabels} from '../components/ChartLabels';

export default function AccuracyByProduct(props) {
  const groupAvg = wer.byRaceAndProduct.reduce((agg, d) => {
    const {product, wer} = d;
    if (agg.has(product)) {
      const cur = agg.get(product);
      agg.set(product, (cur + wer) / 2);
    } else {
      agg.set(product, wer);
    }
    return agg;
  }, new Map());

  const productBarDataBlack = wer.byRaceAndProduct.filter(d => d.race === 'black').map(d => ({
    x: d.product,
    y: d.wer,
    xLabelData: 'b',
    groupAvg: groupAvg.get(d.product),
  }));

  const productBarDataWhite = wer.byRaceAndProduct.filter(d => d.race === 'white').map(d => ({
    x: d.product,
    y: d.wer,
    xLabelData: 'w',
    groupAvg: groupAvg.get(d.product),
  }));

  return (
    <Page {...props}>
      <PageTitle>
        All five firms have similar gaps in performance
      </PageTitle>
      
      <PageBody>
        <Box width={[ 0, 1/6, 1/4 ]} />
        <Box width={[ 1, 2/3, 1/2 ]}>
          <VictoryChart
            width={500}
            theme={theme.chart}>
            <ChartTitle text="Error rates by firm, race, and gender" />
              
            <VictoryAxis
              dependentAxis
              tickValues={[0, 0.1, 0.2, 0.3, 0.4, 0.5]}
              label={true}
              axisLabelComponent={
                <BetterWorseLabels xOffset={10} topAnchor={0.5} bottomAnchor={0} />
              }
              tickFormat={formatWer}
              />
            <VictoryAxis 
              tickFormat={formatProduct}
              />  
            <VictoryGroup
              padding={0}
              domain={{y: [0, 0.5]}}
              offset={35}>
              <VictoryBar
                data={productBarDataBlack}
                sortKey="groupAvg"
                sortOrder="descending"
                labels={d => d}
                barWidth={30}
                labelComponent={
                  <BarChartLabel
                    xVertical={true}
                    xFormat={d => d._group === 0 && formatRace(d.xLabelData)} />
                }
                />

              <VictoryBar
                data={productBarDataWhite}
                sortKey="groupAvg"
                sortOrder="descending"
                labels={d => d}
                barWidth={30}
                labelComponent={
                  <BarChartLabel
                    xFormat={d => d._group === 0 && formatRace(d.xLabelData)}
                    xVertical={true} />
                }
                />
            </VictoryGroup>
          </VictoryChart>
        </Box>
        <Box width={[ 0, 1/6, 1/4 ]} />

        <Box width={[ 1 ]}>
          <PageText>
          We found similar racial disparities for all five of the companies we audited. In every case, the speech recognition tools made about twice as many errors for Black speakers than for white speakers.
          </PageText>
        </Box>
        </PageBody>

    </Page>
  );
}

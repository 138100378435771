import React from 'react'
import { Page, PageTitle } from '../components/Page'
import styled from '@emotion/styled'

export default function Video(props) {
  return (
    <Page {...props}>
      <PageTitle>Want to hear more? Watch our video!</PageTitle>
      <VideoContainer>
        <div>
          <VideoPlayer
            src="https://www.youtube.com/embed/1fQ7q4_x00I"
            frameborder="0"
            gesture="media"
            allow="encrypted-media"
            allowfullscreen
          ></VideoPlayer>
        </div>
      </VideoContainer>
    </Page>
  )
}

const VideoContainer = styled('div')`
  width: 100%;
  max-width: 1200px;

  & > div {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    width: 100%;
  }
`

const VideoPlayer = styled('iframe')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`

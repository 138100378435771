import React from 'react';

const expanded = {
  "coraal": (
    <span>
      Audio from <a href="https://oraal.uoregon.edu/coraal" rel="noopener noreferrer" target="_blank">CORAAL</a>
    </span>
  ),
};

export function getAttribution(text) {
  if (!expanded.hasOwnProperty(text)) {
    return <span>{text}</span>;
  }

  return expanded[text];
}

import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/grid/emotion'
import * as theme from './theme';
import {vhProp} from "../style";

export const Page = styled(Flex)`
  position: relative;
  background-color: ${theme.white};
  ${vhProp("min-height", 100)}
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  flex-direction: column;
  color: ${theme.black};
  justify-content: center;
  font-family: ${theme.bodyFont};
  padding: 2rem 5rem 2rem 7rem;
  @media (max-width: 420px) {
    padding-right: 1rem;
    padding-left: 3rem;
  }
`;

export const PageTitle = styled(Box)`
  width: 100%;
  ${vhProp("flex-basis", 15)}
  font-size: 2rem;
  font-family: ${theme.titleFont};
`;

export const PageBody = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
`;

export const PageText = styled.p`
  text-align: left;
  font-size: 1.1rem;
  line-height: 2;
`;

import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/grid/emotion'
import { Page, PageBody, PageTitle, PageText } from '../components/Page'
import {mq} from "../components/AppContainer";
import * as theme from '../components/theme'
import { formatNumberWithArticle, formatRaceSimple, formatWer, formatRaceSex } from '../format'
import { VictoryChart, VictoryBar, VictoryGroup, VictoryAxis } from 'victory'
import { BetterWorseLabels, ChartTitle } from '../components/ChartLabels'
import { BarChartLabel } from '../components/BarChartLabel'
import { AudioPlayer } from '../components/AudioPlayer'
import { Diff } from '../components/Diff'
import {getAttribution} from '../components/Attribution';

import { wer } from '../data/wer.js'

const groupAvgs = wer.byRaceAndSex.reduce((agg, d) => {
  if (!agg.has(d.sex)) {
    agg.set(d.sex, d.wer);
  } else {
    const avg = (d.wer + agg.get(d.sex)) / 2;
    agg.set(d.sex, avg);
  }
  return agg;
}, new Map());

const barDataBlack = wer.byRaceAndSex
  .filter(d => d.race === 'black')
  .map(d => ({
    x: d.sex,
    y: d.wer,
    groupAvg: groupAvgs.get(d.sex),
    xLabelData: { race: 'b', sex: d.sex },
    original: { ...d, race: 'b' },
  }))

const barDataWhite = wer.byRaceAndSex
  .filter(d => d.race === 'white')
  .map(d => ({
    x: d.sex,
    y: d.wer,
    groupAvg: groupAvgs.get(d.sex),
    xLabelData: { race: 'w', sex: d.sex },
    original: { ...d, race: 'w' },
  }))


export default function AccuracyBySex(props) {
  const [selected, setSelected] = useState(barDataBlack[1])

  return (
    <Page {...props}>
      <PageTitle>Error rates are especially high for Black men</PageTitle>

      <PageBody>
        <GenderContainer>
          <GenderInfoBox width={[1, 1, 0.5]}>
            <PageText>
              The systems performed particularly poorly for Black men, with more
              than 40 errors for every 100 words.
            </PageText>
            <PageText>
              Click on the bars in the chart to hear typical audio samples — and
              see their machine transcriptions — for different self-identified
              demographic groups.
            </PageText>

            {selected
              ? (<GenderAudioInfo point={selected} />)
              : (
                  <ChartPlaceholderText>
                    Select a point on the chart to view detailed information.
                  </ChartPlaceholderText>
                )}
          </GenderInfoBox>

          <Box width={[1, 1, 0.5]}>
            <VictoryChart
              domainPadding={{ x: 100 }}
              theme={theme.chart}
              width={500}
              animate={{
                duration: 250,
                onLoad: { duration: 100 },
              }}
            >
              <ChartTitle text="Error rates by race and gender" />

              <VictoryAxis
                dependentAxis
                label={true}
                axisLabelComponent={
                  <BetterWorseLabels xOffset={10} topAnchor={0.5} bottomAnchor={0} />
                }
                tickValues={[0, 0.1, 0.2, 0.3, 0.4, 0.5]}
                tickFormat={formatWer} />
              <VictoryAxis tickFormat={() => ''} />
              <VictoryGroup offset={80}>
                <VictoryBar
                  domain={{ y: [0, 0.5] }}
                  data={barDataBlack}
                  labels={d => d}
                  sortKey="groupAvg"
                  sortOrder="descending"
                  barWidth={70}
                  labelComponent={
                    <BarChartLabel xFormat={d => formatRaceSex(d.xLabelData)} />
                  }
                  style={{
                    data: {
                      stroke: d =>
                        !!selected &&
                        formatRaceSex(selected.xLabelData) ===
                          formatRaceSex(d.datum.xLabelData)
                          ? theme.red
                          : 'transparent',
                      strokeWidth: 3,
                      cursor: 'pointer',
                    },
                  }}
                  events={[
                    {
                      eventHandlers: {
                        onClick: (_, d) => setSelected(d.datum),
                      },
                    },
                  ]}
                />

                <VictoryBar
                  domain={{ y: [0, 0.5] }}
                  data={barDataWhite}
                  sortKey="groupAvg"
                  sortOrder="descending"
                  labels={d => d}
                  barWidth={70}
                  labelComponent={
                    <BarChartLabel xFormat={d => formatRaceSex(d.xLabelData)} />
                  }
                  style={{
                    data: {
                      stroke: d =>
                        !!selected &&
                        formatRaceSex(selected.xLabelData) ===
                          formatRaceSex(d.datum.xLabelData)
                          ? theme.red
                          : 'transparent',
                      strokeWidth: 3,
                      cursor: 'pointer',
                    },
                  }}
                  events={[
                    {
                      eventHandlers: {
                        onClick: (_, d) => setSelected(d.datum),
                      },
                    },
                  ]}
                />
              </VictoryGroup>
            </VictoryChart>
          </Box>
        </GenderContainer>
      </PageBody>
    </Page>
  )
}

const GenderContainer = styled(Flex)`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  width: 100%;
  align-items: start;
`;

const GenderInfoBox = styled(Box)`
  padding: 0;

  ${mq[1]} {
    padding-right: 2rem;
  }
`;

//taken from DDM.js and needs to be refactored
function GenderAudioInfo({ point }) {
  const [audioPos, setAudioPos] = useState({});
  const d = point.original

  useEffect(() => {
    setAudioPos({});
  }, [point]);

  return (
    <div style={{ borderTop: `1px solid ${theme.lighterGray}` }}>
      <GenderAudioTitle style={{color: d.race === "b" ? theme.blue : theme.gold}}>
      {formatNumberWithArticle(d.age)}-year-old {formatRaceSimple(d.race, true)} {d.sex === "male" ? "man" : "woman"}
      </GenderAudioTitle>
      <SnippetBox>
        <AudioPlayer
          snippet={audioPos}
          onClick={s => setAudioPos({start: s})}
          clip={d.audio.snippet}
          attribution={getAttribution(d.audio.attribution)} />
        <SnippetTranscription>
          <Diff b={d.audio.real_transcription} a={d.audio.asr_transcription} />
        </SnippetTranscription>
      </SnippetBox>
    </div>
  )
}

const SnippetBox = styled.div`
  width: 90%;
  margin-top: 2rem;
`

const SnippetTranscription = styled.div`
  font-family: ${theme.dataFont};
  line-height: 2.5;
  text-align: left;
  margin: 1rem 0;
`

const GenderAudioTitle = styled.h3`
  font-size: 1.2rem;
  color: ${theme.blue};
  text-align: left;
`

const ChartPlaceholderText = styled.div`
  font-style: italic;
  color: ${theme.gray};
  margin: 3rem 0;
`


import React from 'react';
import {VictoryLabel} from 'victory';
import * as theme from './theme';
import { formatWer } from '../format';

export function BarChartLabel(props) {
  const xVertical = !!props.xVertical;
  const xLabel = props.xFormat ? props.xFormat(props.datum, props.index) : props.datum.x;
  const topStyle = Object.assign({}, props.style, {
    fill: theme.slate,
  });
  const bottomStyle = Object.assign({}, props.style, {
    fill: theme.white,
    fontWeight: "bold",
  });
  return (
    <g style={{pointerEvents: "none"}}>
      <VictoryLabel
        {...props}
        text={formatWer(props.datum.y)}
        style={topStyle} />
      {xLabel &&
        <VictoryLabel
          {...props}
          y={props.scale.y(0.01)}
          text={xLabel}
          textAnchor={xVertical ? "start" : "middle"}
          verticalAnchor={xVertical ? "start" : "end"}
          angle={xVertical ? -90 : 0}
          style={bottomStyle} />
      }
    </g>
  );
}
